import { React, useContext, useState } from 'react'
import './TopOfPage.css'
import TopMembers from './TopMembers'

import logo from './logo.png'
import whiteLogo from './whiteLogo.png'
import TopClaims from './TopClaims'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useHistory, Redirect } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { AppContext } from '../core/app-context'

import { Center, Box } from '@chakra-ui/react'

function Header(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [actively, setActive] = useState(false)

  let history = useHistory()

  return (
    <nav>
      <div className="div_header">
        <div className="logo">
          <img src={whiteLogo} />
        </div>

        <div style={{ marginLeft: '9vw', marginRight: '27.5vw' }}>
          <p
            onClick={() => history.push('/members')}
            className={props.active === 'members' ? 'active' : 'p_header'}
          >
            Membros
          </p>
          <a><p
            onClick={() => history.push('/transaction')}
            style={{ marginLeft: '50px' }}
            className={props.active === 'transactions' ? 'active' : 'p_header'}
          >
            Transações
          </p> </a>
          <p
            onClick={() => history.push('/plan')}
            style={{ marginLeft: '50px' }}
            className={props.active === 'plans' ? 'active' : 'p_header'}
          >
            Planos
          </p>

        { appState.user.position ==='editor'?(
          <p
            onClick={() => history.push('/providers')}
            style={{ marginLeft: '30px' }}
            className={props.active === 'providers' ? 'active' : 'p_header'}
          >
            Provedores
          </p>):(" ") }
        </div>

        <div syle={{ display: 'flex', flexDirection: 'row' }}>
          <p className="p_header" style={{ width: '150px' }}>
            <PersonIcon style={{ height: '16px' }} />
            {appState.user.first_names + ' ' + appState.user.last_name}
          </p>
          <button onClick={() => history.push('/')} className="btn_header">
            <ExitToAppIcon style={{ height: '16px' }} />
            Logout{' '}
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Header
