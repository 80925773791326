import React, { useState, useEffect, useContext } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { AppContext } from '../core/app-context'
import api from '../core/api'

function MUP_ClaimRefund({
  setProviderid,
  setAuthno,
  Authno,
  setRefName,
  setDate,
  setTime,
  setRefund,
  setNetworkProvider,
  setPercentageAmount
}) {
  const [appState, dispatch] = useContext(AppContext)

  const [providerName, setProviderName] = useState('')

  const [providerInfo, setProviderInfo] = useState(null)

  const [provi_id, setProvidd] = useState('')

  const [disable, setDisable] = useState(false)

  const sub_provider = () => {
    api
      .post('/api.php', {
        action: 'insert_other_provider',
        body: {
          provider_name: providerName
        }
      })
      .then((result) => {
        console.log('Providerzzz', result.data.data)
        setProviderInfo(result.data.data)
      })
      .catch((err) => console.warn(err))
  }
  const getRanHex = (size) => {
    let result = []
    let hexRef = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H'
    ]

    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)])
    }
    return result.join('')
  }

  useEffect(() => {
    providerInfo !== null
      ? providerInfo.slice(0, 1).map((pro) => setProviderid(pro.id))
      : setProvidd('')

    setAuthno('Reembolso_' + getRanHex(5))
  }, [providerInfo])

  const [providers, setProviders] = useState([])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_providers',
        body: {}
      })
      .then((result) => {
        console.log('providers', result.data.data)
        setProviders(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [])

  const [filteredProviders, setFilteredProviders] = useState([])

  const [searching, setSearching] = useState('no')

  const filterProviders = (filterForm) => {
    let newProviders = providers
    if (filterForm.provider.toString().length > 0) {
      newProviders = providers.filter(
        (m) =>
          filterForm.provider.toString().length > 0 &&
          m.name.toString().toLowerCase().includes(filterForm.provider.toLowerCase())
      )
    }

    // console.log("newMembers", newMembers);
    setFilteredProviders(newProviders)
  }

  const [searchForm, setSearchForm] = useState({
    provider: ''
  })

  const [resetForm, setResetForm] = useState({
    provider: ''
  })

  const [Network, setNetwork] = useState(true)

  function handleChange(event) {
    setNetwork(event.target.value)
    setNetworkProvider(event.target.value)
    if (event.target.value === 'true') {
      setNetwork(true)
    } else {
      setSearching('no')
      setNetwork(false)
    }
  }

  function changePercentage(event) {
    setPercentageAmount(event.target.value)
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings"> </p>

        <Box style={{ width: '35%', display: 'inline-block' }}>
          <label className="label_claims">
            Provedor <span style={{ color: '#ED3737' }}>*</span>
          </label>
          {/*<select className="input_claim" onChange={handleChange} style={{width:'100%'}}>
    
            {providers.map(provider=> <option value ={provider.id} > {provider.name} ------ ({provider.address}) </option>)} 
                
            </select>*/}
          {Network !== true ? (
            <input
              className="input_claim"
              onChange={({ target }) => setProviderName(target.value)}
              style={{ width: '90%', display: 'inline-block' }}
              type="text"
            />
          ) : (
            <input
              className="input_claim"
              onChange={(e) => {
                filterProviders(searchForm)

                setSearchForm({
                  ...searchForm,
                  provider: e.currentTarget.value
                })
              }}
              onFocus={(e) => {
                setSearching('yes')
              }}
              value={searchForm.provider}
              style={{ width: '90%', display: 'inline-block' }}
              type="text"
            />
          )}{' '}
          <div
            className={searching === 'yes' ? 'display_icon' : 'display_nothing'}
            style={{ alignItems: 'bottom', height: '28px', width: '20px' }}
          >
            <CloseIcon
              onClick={() => {
                setSearching('no')
                setSearchForm({ provider: '' })
              }}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
          <div
            className={searching === 'yes' ? 'display_result' : 'display_nothing'}
            style={{ position: 'absolute' }}
          >
            {filteredProviders.slice(0, 5).map((e) => {
              return (
                <h5
                  value={e.name}
                  onClick={() => {
                    setProviderid(e.id)
                    setSearchForm({ provider: e.name })
                    setSearching('no')
                  }}
                  className="dataItem"
                >
                  {e.name} -- {e.address}
                </h5>
              )
            })}
          </div>
        </Box>

        <Box style={{ width: '18%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">Autorização emitida por</label>
          <input
            className="input_claim"
            value={appState.user.first_names + ' ' + appState.user.last_name}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '14%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims" style={{ width: '150px' }}>
            Numéro de Autorização
          </label>
          <input className="input_claim" value="Reembolso" style={{ width: '100%' }} type="text" />
        </Box>

        <Box style={{ width: '10%', display: 'inline-block', marginLeft: '3%' }}>
          {1 == 1 ? (
            <div>
              <label className="label_claims">
                Percentagem<span style={{ color: '#ED3737' }}>*</span>
              </label>
              <select
                className="input_claim"
                onChange={changePercentage}
                style={{ width: '100%', color: '#000' }}
              >
                <option> </option>
                <option value="100">100</option>
                <option value="70">70</option>
              </select>
            </div>
          ) : (
            <div />
          )}
        </Box>
      </Box>

      <div style={{ display: 'block' }}>
        <Box style={{ width: '14%', display: 'inline-block', marginTop: '2%', marginLeft: '24px' }}>
          <label className="label_claims">
            Data <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            className="input_claim"
            onChange={({ target }) => setDate(target.value)}
            style={{ width: '100%' }}
            type="date"
          />
        </Box>

        <Box style={{ width: '14%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
          <label className="label_claims">
            Hora (HH:MM) <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            className="input_claim"
            onChange={({ target }) => setTime(target.value)}
            style={{ width: '100%' }}
            type="text"
            onBlur={sub_provider}
          />
        </Box>

        <Box
          style={{ width: '24%', display: 'inline-block', marginLeft: '15.5%', marginTop: '2%' }}
        >
          <label className="label_claims">
            nome de referencia<span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            className="input_claim"
            onChange={({ target }) => setRefName(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '24%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
          <label className="label_claims">
            Na rede?<span style={{ color: '#ED3737' }}>*</span>
          </label>
          <select
            className="input_claim"
            onChange={handleChange}
            style={{ width: '100%', color: '#000' }}
          >
            {' '}
            <option value="true">Sim</option>
            <option value="false">Nao</option>
          </select>
        </Box>
      </div>
    </div>
  )
}

export default MUP_ClaimRefund
