import React, { useState, useEffect, useContext } from 'react'
import './TopOfPage.css'
import Header from './Header'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory, Redirect } from 'react-router-dom'
import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import { AppContext } from '../core/app-context'

function TopPlans(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [searchForm, setSearchForm] = useState({
    member: '',
    prov: '',
    auth_no: ''
  })

  const handleSubmit = () => {}

  useEffect(() => {
    console.log('searchForm', searchForm)
  }, [searchForm])

  let history = useHistory()

  return (
    <div className="main-page">
      <div className="header">
        <Header active="plans" />
      </div>

      <Center className="subheader" style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}}>
        <Box className="top_pagebox">
          <h1 style={{ display: 'inline-flex' }} className="subheader_heading">
            Planos
          </h1>

          {appState.user.position === 'editor' ? (
            <Button
              leftIcon={<AddIcon style={{ height: '28px' }} />}
              onClick={() => history.push({ pathname: '/planDetail' })}
              className="new_member_btn"
              style={{ float: 'right', width: '10.5%' }}
            >
              Adicionar Plano
            </Button>
          ) : (
            <p> </p>
          )}

          <div
            style={{ width: '100%', height: '1px', backgroundColor: '#EFEFEF', marginTop: '18px' }}
          />

          <div style={{ marginTop: '1.3%', display: 'inline-block', width: '21.5%' }}>
            <label className="labels"> </label>
            <input className="inputs_sidebar" style={{ width: '100%' }} type="text" />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%'
            }}
          >
            <label className="labels"> </label>
            <input className="inputs_sidebar" style={{ width: '100%' }} type="text" />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%'
            }}
          >
            <label className="labels"></label>
            <input className="inputs_sidebar" style={{ width: '100%' }} type="text" />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '1.7%' }}>
            <IconButton
              className="search_btn"
              style={{ width: '50px', height: '28px' }}
              onClick={() => {
                props.onSearch(searchForm)
              }}
              icon={<SearchIcon style={{ height: '18px' }} />}
            />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '10.7%' }}>
            <IconButton
              style={{ width: '36px', height: '28px' }}
              className="trash_btn"
              icon={<DeleteOutlineIcon style={{ height: '18px' }} />}
            />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '15.26%',
              marginLeft: '1.7%'
            }}
          >
            <select className="inputs_sidebar" style={{ width: '100%' }}>
              <option>Sort By: A-Z</option>
              <option>Sort By: Z-A</option>
            </select>
          </div>
        </Box>
      </Center>
    </div>
  )
}

export default TopPlans
