import React, { useState, useEffect } from 'react'
import './AMP.css'
import { Center, Box, Button } from '@chakra-ui/react'
import api from '../core/api'
import { AppContext } from '../core/app-context'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

function AMP_Page1({
  nextStep,
  titlez,
  setTitle,
  gender,
  setGender,
  idNo,
  setIdNo,
  fNames,
  setfNames,
  lName,
  setlName,
  D_o_b,
  setDob,
  C_O_W,
  setCOW,
  homeNum,
  setHomeNum,
  phone,
  setPhone,
  address,
  setAddress,
  email,
  setEmail,
  createMember
}) {
  const selected_gender = (e) => {
    const picked = e.target.value
    if (picked === 'male') {
      setGender('male')
    } else if (picked === 'female') {
      setGender('female')
    }
  }

  function handleTitle(event) {
    setTitle(event.target.value)
  }
  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="amp_page1_heading" style={{ paddingTop: '10px' }}>
          Dados Pessoais do Segurado
        </p>

        <Box style={{ width: '14%', display: 'inline-block' }}>
          <label className="amp_label">
            Titulo <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <select
            className="input_claim"
            value={titlez}
            onChange={handleTitle}
            style={{ width: '100%', color: '#000' }}
          >
            <option value="Mr"> Mr</option>
            <option value="Mrs"> Mrs </option>
            <option value="Miss"> Miss </option>
          </select>
        </Box>

        <Box style={{ width: '18%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="amp_label">
            Sexo<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <Box style={{ display: 'inline-block', paddingTop: '4px' }}>
            <label className="radio_value">
              <input
                name="gender"
                onClick={(e) => {
                  selected_gender(e)
                }}
                type="radio"
                value="male"
              />
              Masc
            </label>
          </Box>

          <Box style={{ display: 'inline-block', marginLeft: '52px', paddingTop: '4px' }}>
            <label className="radio_value">
              <input
                name="gender"
                onClick={(e) => {
                  selected_gender(e)
                }}
                type="radio"
                value="female"
              />
              Fem
            </label>
          </Box>
        </Box>

        <Box style={{ width: '38%', display: 'inline-block', marginLeft: '15%' }}>
          <label className="label_claims">
            Nº de B.I. ou Passaporte: <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            value={idNo}
            onChange={({ target }) => setIdNo(target.value)}
            className="input_claim"
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '70%', display: 'inline-block', marginTop: '2%' }}>
          <label className="label_claims">
            Nomes <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            value={fNames}
            onChange={({ target }) => setfNames(target.value)}
            className="input_claim"
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '70%', display: 'inline-block', marginTop: '2%' }}>
          <label className="label_claims">
            Sobrenome <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            value={lName}
            onChange={({ target }) => setlName(target.value)}
            className="input_claim"
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '22%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">
              Data de Nascimento <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={D_o_b}
              className="input_claim"
              onBlur={createMember}
              onChange={({ target }) => setDob(target.value)}
              style={{ width: '100%' }}
              type="date"
            />
          </Box>

          <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">Pais de Trabalho</label>
            <input
              value={C_O_W}
              onChange={({ target }) => setCOW(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">Tel. Casa: Indicativo</label>
            <input
              value={homeNum}
              onChange={({ target }) => setHomeNum(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
        </div>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">
              Tel. Nº: <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={phone}
              onChange={({ target }) => setPhone(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">Telm. Nº:</label>
            <input className="input_claim" style={{ width: '100%' }} type="text" />
          </Box>
        </div>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '86%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">
              Morada completa da residência:<span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={address}
              onChange={({ target }) => setAddress(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <div style={{ display: 'block' }}>
            <Box style={{ width: '38%', display: 'inline-block', marginTop: '2%' }}>
              <label className="label_claims">
                Endereço electrónico<span style={{ color: '#ED3737' }}>*</span>
              </label>
              <input
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                className="input_claim"
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box
              style={{ width: '38%', display: 'inline-block', marginTop: '2%', marginLeft: '10%' }}
            >
              <label className="label_claims">
                Entidade Patronal:<span style={{ color: '#ED3737' }}>*</span>
              </label>
              <input className="input_claim" style={{ width: '100%' }} type="text" />
            </Box>
          </div>
        </div>

        <div style={{ display: 'block', width: '86%', marginTop: '40px', textAlign: 'end' }}>
          <Button
            onClick={nextStep}
            rightIcon={<ArrowForwardIosIcon style={{ height: '12px' }} />}
            className="next_sect_btn"
          >
            Next Section{' '}
          </Button>
        </div>
      </Box>

      <br />
    </div>
  )
}

export default AMP_Page1
