import './PrintPNew.css'
import { AppContext } from '../core/app-context'
import { useContext, useState, React, useEffect } from 'react'
import BetterCareLogo from './bettercareOG.png'
import api from '../core/api'
import { Col, Row, Table } from 'react-bootstrap'
import { Box, Center } from '@chakra-ui/react'
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop'

function PrintInvoice({ Transactions, issue_date, paid_invoice_id, invoice_no, price }) {
  const print = () => {
    window.print()
  }
  const [appState, dispatch] = useContext(AppContext)

  const [Trans, setTrans] = useState([])

  useEffect(() => {
    if (paid_invoice_id != '') {
      //I THING THAT SOLVES IT
      api
        .post('/api.php', {
          action: 'get_paid_invoice_trans',
          body: { paid_invoice_id: paid_invoice_id }
        })
        .then((result) => {
          console.log('WHY YOU NO GRIND', result.data.data)
          setTrans(result.data.data)
        })
        .catch((err) => {
          console.warn(err)
        })
    }
  }, [paid_invoice_id]) // I THING THAT SOLVES IT

  let thePrice = 0

  let provider_name = ''

  {
    Trans.slice(0, 100).map((e) => {
      thePrice += e.price < 15 ? e.alt_price : e.price

      provider_name = e.provider.name
    })
  }

  return (
    <div>
      <LocalPrintshopIcon onClick={print} style={{ color: '#CD1D33' }} />

      <div style={{ width: '100%', display: 'none' }} className="divname">
        <div className="InnerDiv" class="k-widget k-grid" data-role="grid" />

        <Row>
          <div
            className="logo_p"
            style={{
              width: '22.68%',
              height: '20px',
              display: 'inline-block'
            }}
          >
            <img src={BetterCareLogo} width="172px" height="46px" />
          </div>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <p className="folha" style={{ color: '#000' }}>
            Pagamentos ao provedor
          </p>
        </div>

        <br />

        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col sm="6" style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#CD1D33' }}>Confidencial</h3>
            <span>---</span>
            <span>---</span>
            <span>---</span>
            <span>---</span>
          </Col>

          <Col
            style={{
              marginTop: '12px',
              display: 'flex',
              flexDirection: 'column',
              width: '45%',
              padding: '10px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span>Processado</span>
              <span>{issue_date}</span>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <span>Nome do provedor</span>
              <span>{provider_name}</span>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <span>Claims #</span>
              <span>{Transactions.length}</span>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <span>--</span>
              <span>---</span>
            </div>
          </Col>
        </Row>

        <hr />

        {/* =============FOOTER============= */}

        <Box style={{ width: '100%', marginTop: '50px' }}>
          <table className="value_table">
            <thead>
              <tr className="table_total_block">
                <td
                  style={{
                    width: '15%',
                    textAlign: 'left',
                    fontSize: '10px',
                    borderRight: '1px solid #000000',
                    textTransform: 'uppercase'
                  }}
                >
                  No Factura
                </td>
                <td
                  style={{
                    width: '20%',
                    textAlign: 'left',
                    fontSize: '10px',
                    borderRight: '1px solid #000000',
                    textTransform: 'uppercase'
                  }}
                >
                  Data de Emissão
                </td>
                <td
                  style={{
                    width: '40%',
                    textAlign: 'center',
                    fontSize: '10px',
                    borderRight: '1px solid #000000',
                    textTransform: 'uppercase'
                  }}
                >
                  Nome
                </td>
                <td
                  style={{
                    width: '20%',
                    textAlign: 'left',
                    fontSize: '10px',
                    borderRight: '1px solid #000000',
                    textTransform: 'uppercase'
                  }}
                >
                  Valor da Despesa
                </td>
                <td style={{ width: '5%', fontSize: '10px' }}> </td>
              </tr>
            </thead>

            <tbody>
              {Trans.map((e) => {
                return (
                  <tr>
                    <td style={{ fontSize: '10px' }}> {e.factura_no}</td>
                    <td style={{ fontSize: '10px' }}>{e.date}</td>
                    <td style={{ textAlign: 'left', fontSize: '10px' }}>
                      {e.member.first_names} {e.member.last_name}
                    </td>
                    <td style={{ textAlign: 'left', fontSize: '10px' }}>
                      {e.price < 15 ? e.alt_price : e.price}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <table className="value_table">
            <tr className="table_total_block">
              <td
                colspan="2"
                style={{
                  width: '7%',
                  textAlign: 'left',
                  fontSize: '10px',
                  textTransform: 'uppercase'
                }}
              >
                Total
              </td>
              <td colspan="1" style={{ width: '5%', fontSize: '10px' }}>
                {thePrice} MT
              </td>
            </tr>
          </table>
        </Box>

        <table className="value_table" style={{ marginTop: '150px', bottom: '150px' }}>
          <tr className="table_total_block">
            <td colspan="1" className="table-invoice-footer ">
              Lançado e conferido por
            </td>
            <td colspan="2" style={{ width: '7%', fontSize: '10px' }}></td>
          </tr>

          <tr className="table_total_block">
            <td colspan="1" className="table-invoice-footer ">
              Departamento médico
            </td>
            <td colspan="2" style={{ width: '7%', fontSize: '10px' }}></td>
          </tr>

          <tr className="table_total_block">
            <td colspan="1" className="table-invoice-footer ">
              Aprovado por
            </td>
            <td colspan="2" style={{ width: '7%', fontSize: '10px' }}></td>
          </tr>

          <tr className="table_total_block">
            <td colspan="1" className="table-invoice-footer ">
              Cheque emitido á
            </td>
            <td colspan="2" style={{ width: '7%', fontSize: '10px' }}></td>
          </tr>

          <tr className="table_total_block">
            <td colspan="1" className="table-invoice-footer ">
              Banco
            </td>
            <td colspan="2" style={{ width: '7%', fontSize: '10px' }}></td>
          </tr>
        </table>

        {/* =============FOOTER============= */}

        {/* @KENNEDY : COMMENTED THIS OUT FOR NOW AS THEY WANT TO USE THIS FROM TOMORROW */}
        {/* <div
          style={{
            position: 'fixed',
            width: '740px',
            height: '10px',
            bottom: '90px',
            borderTop: '1px solid #CD1D33'
          }}
        >
          <br />
          <span style={{ fontSize: '12px' }}>
            Av. Armando Tivane, Nº 1359, R/C Maputo - Moçambique
          </span>{' '}
          <br />
          <br />
          <span style={{ fontSize: '12px' }}>
            Para autorizações telefónicas por favor contacte a nossa linha de assistência ao cliente
            pelos seguintes: <br /> +(258) 85 000 1279 / 86 61 00075
          </span>{' '}
          <br />
          <br />
          <span style={{ fontSize: '12px' }}>
            info@bettercare.co.mz | www.bettercare.co.mz
          </span>{' '}
          <br />
        </div> */}
        {/* <Box
          className="logo_p"
          style={{
            width: '22.68%',
            height: '20px',
            display: 'inline-block'
          }}
        >
          <img src={BetterCareLogo} width="172px" height="46px" />
        </Box>

        <Box style={{ float: 'right', display: 'inline-block' }}>
          <p className="folha">Pagamentos ao provedor</p>
        </Box> */}
      </div>
    </div>
  )
}

export default PrintInvoice
