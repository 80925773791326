import React, { useState, useEffect, useContext } from 'react'
import './TDP.css'
import { Center, Box, IconButton } from '@chakra-ui/react'
import api from '../core/api'
import EditIcon from '@material-ui/icons/Edit'
import { AppContext } from '../core/app-context'

function TDP_Transaction({
  SetTransid,
  id,
  benefitline,
  benefitlineID,
  benefitClass,
  procedureName,
  price,
  date,
  time,
  refName,
  altPrice,
  transactionDetails,
  refresh,
  newProvId,
  setNewProvId,
  editing,
  setEditing
}) {
  SetTransid = { SetTransid }
  const [appState, dispatch] = useContext(AppContext)

  const theID = transactionDetails.slice(0, 1).map((tDeet) => tDeet.plan.id)

  const provID = transactionDetails.slice(0, 1).map((tDeet) => tDeet.provider.id)

  const [counter, setCounter] = useState(2)
  const click = () => {
    setNewPrice(price)
    setNewAltPrice(altPrice)
    setNewServiceID(benefitlineID + '_' + benefitline)
    setNewProcedure(procedureName)

    setCounter(counter + 1)

    if (counter % 2 !== 0) {
      setEditing(false)
    } else {
      setEditing(true)
    }
  }

  const [benefitLine, setBenefitLine] = useState([])
  const [uniqueBenefitType, setUniqueBenefitType] = useState([])
  const [uniqueBenefit, setUniqueBenefit] = useState([])

  const [newBline, setBline] = useState('')
  const [Pricey, setPricey] = useState('')
  const [AltPricey, setAltPricey] = useState('')
  const [Procedurez, setProcedure] = useState('')

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_benefit_line',
        body: {
          planid: theID
        }
      })
      .then((result) => {
        console.log('Benefit Lines', result.data.data)
        setNewProvId(provID)
        setBenefitLine(result.data.data)
        setBline(benefitline)
        setPricey(price)
        setAltPricey(altPrice)
        setProcedure(procedureName)

        const uniqueArray = []

        result.data.data.forEach((item) => {
          const index = uniqueArray.findIndex((el) => el.id == item.service_type.id)
          if (index < 0) {
            uniqueArray.push(item.service_type)
          }
        })

        setUniqueBenefitType(uniqueArray)
      })
      .catch((err) => console.warn(err))
  }, [])

  const [newPrice, setNewPrice] = useState('')
  const [newAltPrice, setNewAltPrice] = useState('')
  const [newServiceID, setNewServiceID] = useState('')
  const [newProcedure, setNewProcedure] = useState('')

  function handleChange(event) {
    setNewServiceID(event.target.value)
  }

  

  const aay = newServiceID.split('_')[1]

  const SubmitValues = () => {
    setBline(aay)

    setPricey(newPrice)
    setAltPricey(newAltPrice)
    setProcedure(newProcedure)

    alert(newProvId)

    api
      .post('/api.php', {
        action: 'update_transaction_values',

        body: {
          provider_id: newProvId,
          procedure_name: newProcedure,
          service_id: Number(newServiceID.split('_')[0]),
          price: parseFloat(newPrice),
          alt_price: parseFloat(newAltPrice),
          trans_id: id
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleChangeSelectBenefitType = (e) => {
    if (!e) {
      setUniqueBenefit([])
      return
    }

    const benefit = []
    benefitLine.forEach((el) => {
      if (el.service_type_id == e) benefit.push(el)
    })

    setUniqueBenefit(benefit)
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings" style={{ paddingTop: '10px' }}>
          Transação
          {appState.user.position === 'editor' ? (
            <IconButton
              onClick={click}
              style={{ marginLeft: '250px' }}
              className={editing !== true ? 'save_item_btn' : 'edit_btn_active'}
              icon={<EditIcon style={{ height: '18px' }} />}
            />
          ) : (
            ''
          )}
          {editing !== true ? (
            ''
          ) : (
            <button
              className="save_item_btn"
              style={{ marginLeft: '250px' }}
              onClick={() => {
                SubmitValues()
                refresh()
              }}
            >
              Save
            </button>
          )}
        </p>

        <Box style={{ width: '35%', display: 'inline-block' }}>
          <label className="label_claims">Tipo de Benefício</label>

          {editing !== true ? (
            <input
              className="input_completed"
              readOnly
              value={benefitClass}
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <select
              className="input_claim"
              onChange={(e) => handleChangeSelectBenefitType(e.target.value)}
              style={{ width: '100%', color: '#000' }}
            >
              <option> </option>
              {uniqueBenefitType.map((type) => (
                <option value={type.id}>{type.name}</option>
              ))}
            </select>
          )}
        </Box>

        <Box style={{ width: '35%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">Benefício</label>
          {editing !== true ? (
            <input
              className="input_completed"
              readOnly
              value={newBline}
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <select
              className="input_claim"
              onChange={handleChange}
              style={{ width: '100%', color: '#000' }}
            >
              <option> </option>
              {uniqueBenefit.map((benefit) => (
                <option value={benefit.id + '_' + benefit.service_name}>
                  {benefit.service_name}
                </option>
              ))}
            </select>
          )}
        </Box>

        <Box style={{ width: '35%', display: 'inline-block', marginTop: '2%' }}>
          <label className="label_claims">
            Procedimento <span style={{ color: '#ED3737' }}>*</span>
          </label>
          {editing !== true ? (
            <input
              className="input_completed"
              readOnly
              value={Procedurez}
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <input
              className="input_claim"
              onChange={({ target }) => setNewProcedure(target.value)}
              placeholder="auto"
              style={{ width: '100%' }}
              type="text"
            />
          )}
        </Box>

        <Box style={{ width: '15%', display: 'inline-block', marginLeft: '13%', marginTop: '2%' }}>
          <label className="label_claims">
            {price > 50 ? 'Preço(MT)' : 'Valor'} <span style={{ color: '#ED3737' }}>*</span>
          </label>

          {editing !== true ? (
            <input
              className="input_completed"
              value={Pricey + (price > 5 ? '' : '')}
              readOnly
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <input
              className="input_claim"
              onChange={({ target }) => setNewPrice(target.value)}
              placeholder="auto"
              style={{ width: '100%' }}
              type="text"
            />
          )}
        </Box>

        <Box
            style={{ width: '15%', display: 'inline-block', marginLeft: '5%', marginTop: '2%' }}
          >
            <label className="label_claims">Preço Alternativo(MT)</label>
            {editing !== true ? (
              <input
                className="input_completed"
                value={AltPricey + (altPrice > 50 ? ' ' : '')}
                readOnly
                style={{ width: '100%' }}
                type="text"
              />
            ) : (
              <input
                className="input_claim"
                onChange={({ target }) => setNewAltPrice(target.value)}
                placeholder="auto"
                style={{ width: '100%' }}
                type="text"
              />
            )}
          </Box>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '17%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">
              Data <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              className="input_completed"
              readOnly
              value={date}
              style={{ width: '100%' }}
              type="date"
            />
          </Box>

          <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
            <label className="label_claims">Hora (HH:MM)</label>
            <input
              className="input_completed"
              readOnly
              value={time}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box
            style={{ width: '35%', display: 'inline-block', marginLeft: '13%', marginTop: '2%' }}
          >
            <label className="label_claims">
              Nome de referência <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              className="input_completed"
              value={refName}
              readOnly
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

         
        </div>
      </Box>

      <br />
    </div>
  )
}

export default TDP_Transaction
