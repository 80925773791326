import React, { useState, useEffect } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'
import api from '../core/api'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MemberSummary from '../PrintPages/PrintMemberTrans'

function MUP_History(props) {
  return (
    <div style={{ height: '100%', width: '100%', backgroundColor: '#fff' }}>
      <Box style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}>
        <p className="headings" style={{ marginLeft: '10px', paddingTop: '16px' }}>
          <MemberSummary member_id={props.member_id}  memberDetails={props.memberDetails}/>{' '}
        </p>

        <p className="showing" style={{ display: 'inline-block', marginLeft: '10px' }}>
          Mostrando:{' '}
        </p>

        <p className="benefits" style={{ display: 'inline-block', marginLeft: '16px' }}>
          {props.serv_name}
        </p>

        <table className="history_table">
          <thead>
            <th>Autorização</th>
            <th>Procedimento</th>
            <th>Preço</th>
            <th>Data</th>
            <th> </th>
          </thead>

          {props.memberHistory.map((e) => {
            return (
              <tr className="history_table_inside">
                <td style={{ width: '22%' }}>
                  {e.auth_no}
                  <span className="span_member">{e.auth_by}</span>
                </td>
                <td style={{ width: '30%' }}>
                  {e.procedure_name}
                  <span className="span_member">{e.provider.name}</span>
                </td>
                <td style={{ width: '14%' }}>
                  {e.price} {e.price <= 100 ? 'sessao' : 'MT'}{' '}
                  {e.alt_price <= 100 ? ' ' : e.alt_price + ' MT'}
                </td>
                <td style={{ width: '14%' }}>
                  {e.date}
                  <span className="span_member">{e.time}</span>
                </td>
                <td style={{ width: '6%' }}>
                  <MoreVertIcon />
                </td>
              </tr>
            )
          })}
        </table>
      </Box>
    </div>
  )
}

export default MUP_History
