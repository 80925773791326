import React, { useState, useEffect } from 'react'
import './AMP.css'
import { Center, Box, Button } from '@chakra-ui/react'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import AttachmentIcon from '@material-ui/icons/Attachment'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

function Doenca({ setSick, setTreat, setDot, setS, saveSickness }) {
  return (
    <div>
      <Box style={{ width: '54%', marginTop: '2%' }}>
        <label className="amp_label">
          Doença: <span></span>
        </label>

        <input
          className="input_claim"
          onChange={({ target }) => setSick(target.value)}
          style={{ width: '100%' }}
          type="text"
        />
      </Box>

      <div style={{ display: 'block' }}>
        <Box style={{ width: '22%', display: 'inline-block', marginTop: '2%' }}>
          <label className="label_claims">Tipo de Tratamento:</label>
          <input
            className="input_claim"
            style={{ width: '100%' }}
            onChange={({ target }) => setTreat(target.value)}
            type="text"
          />
        </Box>

        <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
          <label className="label_claims">Data de Tratamento:</label>
          <input
            className="input_claim"
            style={{ width: '100%' }}
            onChange={({ target }) => setDot(target.value)}
            type="text"
          />
        </Box>

        <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
          <label className="label_claims">Estado actual da saúde:</label>
          <input
            className="input_claim"
            style={{ width: '100%' }}
            onChange={({ target }) => setS(target.value)}
            onBlur={saveSickness}
            type="text"
          />
        </Box>
      </div>
    </div>
  )
}

export default Doenca
