import { React, useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Lorem,
  Center,
  Box
} from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import './Modal.css'
import { useHistory, Redirect } from 'react-router-dom'
import api from '../core/api'

function Modalz(props) {
  let history = useHistory()

  let [plans, SetPlans] = useState([])

  let [fNames, setfNames] = useState('')
  let [lName, setlName] = useState('')
  let [D_o_b, setDob] = useState('')
  let [policyNo, setPolicyNo] = useState('')
  let [memberNo, setMemberNo] = useState('')
  let [idNo, setIdNo] = useState('')
  let [email, setEmail] = useState('')
  let [phone, setPhone] = useState('')
  let [Planid, setPlanId] = useState('1')
  let [startdate, setStartdate] = useState('')
  let [enddate, setEnddate] = useState('')
  let [waitperiod, setWaitperiod] = useState('')

  const resetForm = () => {
    setfNames(' ')
    setlName(' ')
    setPolicyNo('')
    setMemberNo(' ')
    setIdNo(' ')
    setEmail(' ')
    setPhone(' ')
    setPlanId('1')
    setStartdate(' ')
    setEnddate(' ')
    setWaitperiod(' ')
  }

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_plans',
        body: {}
      })
      .then((result) => {
        console.log('Plans', result.data.data)
        SetPlans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  function handleChange(event) {
    setPlanId(event.target.value)
  }

  const handleSubmit = () => {
    api
      .post('/api.php', {
        action: 'add_member',

        body: {
          first_names: fNames,
          last_name: lName,
          d_o_b: D_o_b,
          policy_no: policyNo,
          member_no: memberNo,
          id_number: idNo,
          email: email,
          phone: phone,
          plan_id: Planid,
          start_date: startdate,
          end_date: enddate,
          waiting_period: waitperiod
        }
      })
      .then(function (response) {
        console.log(response)
        if (response.data.status === 1) {
          const userInfo = JSON.stringify(response.data)
          console.log(userInfo)
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        leftIcon={<AddIcon style={{ height: '28px' }} />}
        className="new_member_btn"
        style={{ float: 'right', width: '10.5%' }}
        onClick={onOpen}
      >
        Novo Membro
      </Button>

      <Modal className="ModalBody" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.5)', position: 'fixed' }} />
        <ModalContent
          style={{
            width: '45vw',
            height: '100vh',
            backgroundColor: '#fff',
            position: 'absolute',
            marginLeft: ' 35%',
            marginRight: 'auto'
          }}
        >
          <ModalHeader className="ModalBody">
            <p className="modal_title">Novo Membro</p>
          </ModalHeader>

          <ModalBody className="ModalBody">
            <p className="sub_header_head"> Detalhes pessoais</p>
            <Box style={{ width: '59%', display: 'inline-block' }}>
              <label className="labels">Primeiro nome</label>
              <input
                className="inputs"
                defaultValue="haha"
                value={fNames}
                onChange={({ target }) => setfNames(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '29%', display: 'inline-block', marginLeft: '20px' }}>
              <label className="labels">Apelido</label>
              <input
                className="inputs"
                value={lName}
                onChange={({ target }) => setlName(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '27%', display: 'inline-block' }}>
              <label className="labels">Número da apolície</label>
              <input
                className="inputs"
                value={policyNo}
                onChange={({ target }) => setPolicyNo(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '29%', display: 'inline-block', marginLeft: '20px' }}>
              <label className="labels">Número do membro</label>
              <input
                className="inputs"
                value={memberNo}
                onChange={({ target }) => setMemberNo(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '29%', display: 'inline-block', marginLeft: '20px' }}>
              <label className="labels">B.I No</label>
              <input
                className="inputs"
                value={idNo}
                onChange={({ target }) => setIdNo(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '59%', display: 'inline-block' }}>
              <label className="labels">Email</label>
              <input
                className="inputs"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>
            <Box style={{ width: '29%', display: 'inline-block', marginLeft: '20px' }}>
              <label className="labels">Contacto</label>
              <input
                className="inputs"
                value={phone}
                onChange={({ target }) => setPhone(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '27%', display: 'inline-block' }}>
              <label className="labels">Data De Nascimento</label>
              <input
                className="inputs"
                value={D_o_b}
                onChange={({ target }) => setDob(target.value)}
                style={{ width: '100%' }}
                type="date"
              />
            </Box>

            <p className="sub_header_head"> Detalhes do membro</p>
            <Box style={{ width: '92%', display: 'inline-block' }}>
              <label className="labels">Plano</label>

              <select
                className="inputs"
                onChange={handleChange}
                value={Planid}
                style={{ width: '100%' }}
              >
                {plans.map((plan) => (
                  <option value={plan.id}>
                    {' '}
                    {plan.name} ({plan.total_amount} MT)
                  </option>
                ))}
              </select>
            </Box>

            <Box style={{ width: '29%', display: 'inline-block' }}>
              <label className="labels"> Data de Inicio</label>
              <input
                className="inputs"
                value={startdate}
                onChange={({ target }) => setStartdate(target.value)}
                style={{ width: '100%' }}
                type="date"
              />
            </Box>

            <Box style={{ width: '29%', display: 'inline-block', marginLeft: '16px' }}>
              <label className="labels">Data Fim</label>
              <input
                className="inputs"
                value={enddate}
                onChange={({ target }) => setEnddate(target.value)}
                style={{ width: '100%' }}
                type="date"
              />
            </Box>

            <Box style={{ width: '29%', display: 'inline-block', marginLeft: '16px' }}>
              <label className="labels">Período de carência</label>
              <input
                className="inputs"
                value={waitperiod}
                onChange={({ target }) => setWaitperiod(target.value)}
                style={{ width: '100%' }}
                type="date"
              />
            </Box>

            <Box style={{ width: '59%', display: 'inline-block', marginTop: '8%' }}>
              <button
                onClick={() => {
                  handleSubmit()
                  resetForm()
                  props.refreshy()
                  onClose()
                }}
                className="search_btn"
                style={{ width: '100%' }}
                type="text"
              >
                {' '}
                Salve{' '}
              </button>
            </Box>
            <Box
              style={{ width: '29%', display: 'inline-block', marginLeft: '20px', marginTop: '8%' }}
            >
              <button onClick={onClose} className="inputs" style={{ width: '100%' }} type="text">
                Cancel{' '}
              </button>
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Modalz
