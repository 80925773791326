import React, { useState, useEffect } from 'react';
import './MUP.css';
import { Center, Box } from '@chakra-ui/react';
import CloseIcon from '@material-ui/icons/Close';
import api from '../core/api';

function MUP_LineItem({ addT, id, service_id, setProcedurename, setPrice, setAltPrice, Procedurename, Price, AltPrice, handleRemove}) {
	
const [ disable, setDisable ] = useState(false);

	const dis = () => {
		setDisable(true);
	};

	const [deleted, setDeleted] = useState(false)


const deletey = ()=> {
	setDeleted(true)
}
function SubmitButton() {
		if ( service_id!== 0) {
			return (
				<button
					style={{ float: 'right', marginTop:'18px' }}
					className="save_item"
					disabled={disable}
					onClick={() => {
						addT({ Procedurename, service_id, Price, AltPrice });
						dis();
					}}
				>
					Salvar
				</button>
				
			);
		} else {
			return (
				<button style={{ float: 'right' }} className="save_item_disabled" disabled>
					Salvar item
				</button>
			);
		}
	}

	return (
		<div className={deleted?("deleted"):("")}>
			<Box style={{ width: '35%', display: 'inline-block', marginTop: '2%' }}>
				<label  className="label_claims">
					Procedimento <span style={{ color: '#ED3737' }}>*</span>
				</label>

				<input
					className="input_claim"
					onChange={({ target }) => setProcedurename(target.value)}
					style={{ width: '90%', display: 'inline-block' }}
					type="text"
				/>
			</Box>

			<Box style={{ width: '15%', display: 'inline-block', marginLeft: '13%', marginTop: '2%' }}>
				<label className="label_claims">
					Preço(MT) <span style={{ color: '#ED3737' }}>*</span>
				</label>
				{/*<input className="input_claim"  onChange={({ target }) => setPrice(target.value)} placeholder='auto' style={{ width: '100%' }} type="text" />*/}

				<input
					className="input_claim"
					onChange={({ target }) => setPrice(target.value)}
					style={{ width: '100%' }}
					type="text"
				/>

				{/*} <input
                            type="checkbox"
                            onClick={(e) => {
                                selectPricey(e);
                            }}
                        />*/}
			</Box>

			<Box style={{ width: '15%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
				<label onClick={() => handleRemove(id) 
				}className="label_claims">Preço Alternativo(MT)</label>

				<input
					className="input_claim"
					onChange={({ target }) => setAltPrice(target.value)}
					style={{ width: '100%' }}
					type="text"
				/>
			</Box>

			<SubmitButton />  <CloseIcon
			onClick={() =>{ handleRemove(id) 
			deletey()}
				}
             
              style={{ width: '20px', height: '20px' }}
            />
		</div>
	);
}

export default MUP_LineItem;
