import React, { useEffect, useState, useContext } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Lorem,
  Center,
  Box
} from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import './Modal.css'
import api from '../core/api'
import { AppContext } from '../core/app-context'


function AddProviders() {
      const [appState, dispatch] = useContext(AppContext)

	
    const [name, setName] = useState("")
    const [province, setProvince]= useState("")
    const [type, setType] = useState("")
    const [address, setAddress] = useState("")
    const [phone , setPhone]= useState("")
    const [email, setEmail] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountNib, setAccountNib] = useState("")
    const [bank, setBank]= useState("")
    const [bankOwner, setBankOwner] = useState("")


function handleChange(event) {
		setProvince(event.target.value);
	}

  

    const submitProvider =() => {

  api
      .post('/api.php', {
        action: 'add_provider',
        body: {   
          name: name,
          province:province,
          type: type,
          address: address,
          phone: phone,
          email: email,
          account_number: accountNumber,
          account_nib: accountNib,
          bank: bank,
          bank_owner: bankOwner
         
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
			
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })

    }



  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
    <Button
            onClick={onOpen}
            leftIcon={<AddIcon style={{ height: '28px' }} />}
            className="new_member_btn"
          >
            Adiciona Provedor
          </Button>
     
      <Modal className="ModalBody" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.5)', position: 'fixed' }} />
        <ModalContent
          style={{
            width: '33vw',
            height: '58vh',
            backgroundColor: '#fff',
            position: 'absolute',
            marginLeft: ' 35%',
            marginRight: 'auto',
            marginTop: '5%',
            borderRadius: '6px'
          }}
        >
          <ModalHeader style={{ marginLeft: '40px' }}>
            <p className="submitTrans_header">Add Provider</p>
          </ModalHeader>

          <ModalBody className="ModalBody">


          <Box style={{ width: '193px', display: 'inline-block'}}>
            <label className="label_claims">Nome</label>

            <input
            className="input_block"
            name="invoice_no"
           onChange={({ target }) => setName(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
          </Box>

           <Box style={{ width: '193px', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims">
            Address<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <input
            className="input_block"
            name="invoice_no"
           onChange={({ target }) => setAddress(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '193px', display: 'inline-block', marginTop:'16px' }}>
          <label className="label_claims">
            Provincia<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <select
            className="input_block"
            name="payment_meth"
            onChange={handleChange}
            style={{ width: '100%', color: '#000' }}
          >
            <option value="Cabo Delgado"> Cabo Delgado</option>
            <option value="Gaza">Gaza</option>
             <option value="Inhambane">Inhambane</option>
              <option value="Manica">Manica</option>
               <option value="Maputo">Maputo</option>
                <option value="Nampula">Nampula</option>
                 <option value="Niassa">Niassa</option>
                  <option value="Sofala">Sofala</option>
                   <option value="Tete">Tete</option>
                    <option value="Zambezia">Zambezia</option>
                   
  
          </select>
        </Box>

        <Box style={{ width: '193px', display: 'inline-block', marginTop:'16px', marginLeft:'2%' }}>
          <label className="label_claims">
            Tipo de Provedor<span style={{ color: '#ED3737' }}>*</span>
          </label>

           <input
            name="account"
            className="input_block"
             onChange={({ target }) => setType(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>


        <Box style={{ width: '193px', display: 'inline-block' , marginTop:'16px'}}>
          <label className="label_claims">
           Phone <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            name="account"
            className="input_block"
             onChange={({ target }) => setPhone(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '193px', display: 'inline-block', marginLeft:'2%', marginTop:'16px'}}>
            <label className="label_claims">Email</label>

            <input
            className="input_block"
            name="invoice_no"
            onChange={({ target }) => setEmail(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
          </Box>

           <Box style={{ width: '193px', display: 'inline-block' , marginTop:'16px' }}>
          <label className="label_claims">
            Account Number<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <input
            className="input_block"
            name="invoice_no"
        onChange={({ target }) => setAccountNumber(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '193px', display: 'inline-block', marginLeft:'2%', marginTop:'16px'}}>
            <label className="label_claims">Nib</label>

            <input
            className="input_block"
            name="invoice_no"
            onChange={({ target }) => setAccountNib(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
          </Box>

          <Box style={{ width: '193px', display: 'inline-block', marginTop:'16px' }}>
            <label className="label_claims">Banco</label>
            <input
            className="input_block"
            onChange={({ target }) => setBank(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
            
          </Box>


 <Box style={{ width: '193px', display: 'inline-block', marginTop:'16px', marginLeft:'2%'}}>
            <label className="label_claims">Titulo do Banco</label>

            <input
            className="input_block"
            name="invoice_no"
            onChange={({ target }) => setBankOwner(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
          </Box>

          

 



         
          </ModalBody>

          <ModalFooter style={{marginTop:'30px'}}>

          
            <button className="submitTrans_cancel" onClick={onClose}>
              Cancela
            </button>

            <button
              className="submitTrans_confirm"
              onClick={() => {
               submitProvider()
                onClose()
              }}
            >
              Confirme
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddProviders
