import React, { useEffect, useState, useContext } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Lorem,
  Center,
  Box
} from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import './Modal.css'
import api from '../core/api'
import { AppContext } from '../core/app-context'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

function MakePayment(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [issue_date, set_issue_date] = useState('')
  const [invoice_no, set_invoice_no] = useState('')
  const [payment_meth, set_payment_meth] = useState('')
  const [account, set_account] = useState('')
  const [receipt_no, set_receipt_no] = useState('')
  const [status, set_status] = useState('')
  const [comprovativo, set_comprovativo] = useState('')
  const [price, set_price] = useState('')

  const [paidInvoices, setPaidInvoices] = useState([])

  const personN = appState.user.first_names + ' ' + appState.user.last_name
  const [providerId, setProviderId] = useState()
  const [paidInvoiceValue, setPaidInvoiceValue] = useState()

  useEffect(async () => {
    if (props.providerId) {
      setProviderId(props.providerId)
      getPaidInvoices()
    }
  }, [props.providerId])

  function handleChange(event) {
    set_payment_meth(event.target.value)
  }

  function handleLiquid(event) {
    set_status(event.target.value)
  }

  const getPaidInvoices = async () => {
    try {
      const response = await api.post('/api.php', {
        action: 'get_paid_invoices',
        body: { provider_id: props.providerId }
      })

      setPaidInvoices(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const completeTable = () => {
    props.set_comprovativo(comprovativo)
    props.set_payment_meth(payment_meth)
    props.set_account(account)
    props.set_receipt_no(receipt_no)
    props.set_status(status)
    props.set_price(price)
    props.setPaidInvoiceId(paidInvoiceValue)
  }

  const handleChangeInvoiceNo = (e) => {
    if (e.target.value) {
      const paidInvoice = paidInvoices.find((item) => item.id == e.target.value)
      setPaidInvoiceValue(paidInvoice.id)
      if (paidInvoice) {
        set_issue_date(paidInvoice.issue_date)
        props.setPaidInvoiceId(paidInvoice.id)
      }
    } else {
      set_issue_date('')
      props.setPaidInvoiceId('')
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const generateInvoiceNo = async () => {
    try {
      await api.post('/api.php', {
        action: 'create_trans_status',
        body: {
          issue_date: new Date().toISOString().split('T')[0],
          invoice_no: invoice_no,
          changed_by: personN,
          provider_id: providerId
        }
      })

      getPaidInvoices()

      MySwal.fire({
        title: 'Sucesso',
        text: 'Lote gerado com sucesso',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    } catch (error) {}
  }

  const handleSubmitForm = () => {
    if (paidInvoices.length == 0) {
      MySwal.fire({
        title: 'Atenção',
        text: 'Nenhum lote foi gerado',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
      return
    }

    if (!paidInvoiceValue) {
      MySwal.fire({
        title: 'Atenção',
        text: 'Selecione um lote.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
      return
    }
    props.SubmitValues()
    props.refresh()
    onClose()
  }

  return (
    <>

    <button
        className="left_generate_lote_number"
        style={{ width: '160px' }}
        onClick={generateInvoiceNo}
      >
        Gerar N<sup>o</sup> do Lote
      </button>

      <br />

      <button className="left_deet_assign_procedures" style={{ width: '150px' }} onClick={onOpen}>
        Assign Procedures
      </button>

      

      

      <Modal className="ModalBody" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.5)', position: 'fixed' }} />
        <ModalContent
          style={{
            width: '33vw',
            height: '223px',
            backgroundColor: '#fff',
            position: 'absolute',
            marginLeft: ' 35%',
            marginRight: 'auto',
            marginTop: '5%',
            borderRadius: '6px'
          }}
        >
          <ModalHeader style={{ marginLeft: '40px', marginTop:'32px' }}>
            <p className="submitTrans_header">Faz Pagamento</p>
          </ModalHeader>

          <ModalBody className="ModalBody">

          <Box style={{ width: '193px', display: 'inline-block' }}>
              <label className="label_claims">
                N da Lote<span style={{ color: '#ED3737' }}>*</span>
              </label>

              <select
                className="input_block"
                name="payment_meth"
                onChange={handleChangeInvoiceNo}
                style={{ width: '100%', color: '#000' }}
                value={paidInvoiceValue}
              >
                <option value=""> </option>
                {paidInvoices.map((item) => (
                  <option value={item.id}>
                    {/* {item.invoice_no} */}
                    {('0000' + item.invoice_no).slice(-4)}
                  </option>
                ))}
              </select>

              {/* <input
                className="input_block"
                name="invoice_no"
                onChange={({ target }) => set_invoice_no(target.value)}
                onBlur={createTran}
                style={{ width: '100%' }}
                type="text"
              /> */}
            </Box>


            <Box style={{ width: '193px', display: 'inline-block' , marginLeft: '2%' }}>
              <label className="label_claims">Data De emissao</label>

              <input
                className="input_block"
                name="invoice_no"
                onChange={({ target }) => set_issue_date(target.value)}
                style={{ width: '100%' }}
                type="date"
                disabled
                value={issue_date}
              />
            </Box>

            

            
          </ModalBody>

          <ModalFooter style={{ marginTop: '30px' }}>
            <p className="trans_selected" style={{ float: 'left', marginRight: '120px' }}>
              *{props.transNo} selecionado{' '}
            </p>
            <button className="submitTrans_cancel" onClick={onClose}>
              Cancel
            </button>

            <button
              className="submitTrans_confirm"
              onClick={() => {
                handleSubmitForm()
              }}
            >
              Confirme
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MakePayment
