import React, { useState, useEffect, useContext } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { AppContext } from '../core/app-context'
import api from '../core/api'

function MUP_Claim({
  setProviderid,
  setAuthno,
  Authno,
  setRefName,
  setDate,
  setTime,
  setRefund,
  setAuthby
}) {
  const [appState, dispatch] = useContext(AppContext)

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_providers',
        body: {}
      })
      .then((result) => {
        console.log('providers', result.data.data)
        setProviders(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [])

  const [providers, setProviders] = useState([])
  const [filteredProviders, setFilteredProviders] = useState([])

  const [wordEntered, setWordEntered] = useState('')

  const [searchForm, setSearchForm] = useState({
    provider: ''
  })

  const [resetForm, setResetForm] = useState({
    provider: ''
  })

  const filterProviders = (filterForm) => {
    let newProviders = providers
    if (filterForm.provider.toString().length > 0) {
      newProviders = providers.filter(
        (m) =>
          filterForm.provider.toString().length > 0 &&
          m.name.toString().toLowerCase().includes(filterForm.provider.toLowerCase())
      )
    }

    // console.log("newMembers", newMembers);
    setFilteredProviders(newProviders)
  }

  const clearInput = () => {
    setWordEntered('')
  }

  const [searching, setSearching] = useState('no')

  let separator = '-'
  let newDate = new Date()
  let date_raw = newDate.getDate()
  let month_raw = newDate.getMonth() + 1
  let year = newDate.getFullYear()
  var date, month

  if (date_raw < 10) {
    date = '0' + date_raw.toString()
  } else {
    date = date_raw.toString()
  }
  if (month_raw < 10) {
    month = '0' + month_raw.toString()
  } else {
    month = month_raw.toString()
  }

  let newCurrentDate = year + separator + month + separator + date

  let timeSep = ':'
  var hours_raw = new Date().getHours() //Current Hours
  var min_raw = new Date().getMinutes() //Current Minutes
  var hours, min

  if (hours_raw < 10) {
    hours = '0' + hours_raw.toString()
  } else {
    hours = hours_raw.toString()
  }
  if (min_raw < 10) {
    min = '0' + min_raw.toString()
  } else {
    min = min_raw.toString()
  }

  let newCurrentHours = hours + timeSep + min

  useEffect(() => {
    setDate(newCurrentDate)
    setTime(newCurrentHours)
    setAuthby(appState.user.first_names + ' ' + appState.user.last_name)
  }, [])

  const [NewClaim, setNewClaim] = useState(true)

  const [counter, setCounter] = useState(1)

  const here = () => {
    setCounter(counter + 1)

    if (counter % 2 !== 0) {
      setNewClaim(false)
      setDate(newCurrentDate)
      setTime(newCurrentHours)
    } else {
      setNewClaim(true)
      setAuthby(appState.user.first_names + ' ' + appState.user.last_name)
    }
  }

  function handleChange(event) {
    setAuthby(event.target.value)
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings">
          {appState.user.position === 'editor' ? (
            <button className={NewClaim !== true ? 'btn_onList' : 'btn_notList'} onClick={here}>
              {NewClaim !== true ? 'Claim Antiga' : 'Claim Nova'}{' '}
            </button>
          ) : (
            ''
          )}
        </p>

        <Box style={{ width: '38%', display: 'inline-block' }}>
          <label className="label_claims">
            Provedor <span style={{ color: '#ED3737' }}>*</span>
          </label>
          {/*<select className="input_claim" onChange={handleChange} style={{width:'100%'}}>
    
            {providers.map(provider=> <option value ={provider.id} > {provider.name} ------ ({provider.address}) </option>)} 
                
            </select>*/}
          <input
            className="input_claim"
            onChange={(e) => {
              filterProviders(searchForm)

              setSearchForm({
                ...searchForm,
                provider: e.currentTarget.value
              })
            }}
            onFocus={(e) => {
              setSearching('yes')
            }}
            value={searchForm.provider}
            style={{ width: '90%', display: 'inline-block' }}
            type="text"
          />{' '}
          <div
            className={searching === 'yes' ? 'display_icon' : 'display_nothing'}
            style={{ alignItems: 'bottom', height: '28px', width: '20px' }}
          >
            <CloseIcon
              onClick={() => {
                setSearching('no')
                setSearchForm({ provider: '' })
              }}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
          <div
            className={searching === 'yes' ? 'display_result' : 'display_nothing'}
            style={{ position: 'absolute' }}
          >
            {filteredProviders.slice(0, 5).map((e) => {
              return (
                <h5
                  value={e.name}
                  onClick={() => {
                    setProviderid(e.id)
                    setSearchForm({ provider: e.name })
                    setSearching('no')
                  }}
                  className="dataItem"
                >
                  {e.name} -- {e.address}
                </h5>
              )
            })}
          </div>
        </Box>

        <Box style={{ width: '38%', display: 'inline-block', marginLeft: '10%' }}>
          <label className="label_claims">
            nome de referencia<span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            className="input_claim"
            onChange={({ target }) => setRefName(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>
      </Box>

      <div style={{ display: 'block' }}>
        <Box style={{ width: '20%', display: 'inline-block', marginTop: '2%', marginLeft: '24px' }}>
          <label className="label_claims">
            Data <span style={{ color: '#ED3737' }}>*</span>
          </label>
          {NewClaim === true ? (
            <input
              className="input_claim"
              value={newCurrentDate}
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <input
              className="input_claim"
              onChange={({ target }) => setDate(target.value)}
              style={{ width: '100%' }}
              type="date"
            />
          )}
        </Box>

        <Box style={{ width: '14%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
          <label className="label_claims">
            Hora (HH:MM) <span style={{ color: '#ED3737' }}>*</span>
          </label>
          {NewClaim === true ? (
            <input
              className="input_claim"
              value={newCurrentHours}
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <input
              className="input_claim"
              onChange={({ target }) => setTime(target.value)}
              style={{ width: '100%' }}
              type="text"
            />
          )}
        </Box>

        <Box style={{ width: '21%', display: 'inline-block', marginTop: '2%', marginLeft: '9.5%' }}>
          <label className="label_claims">Autorização emitida por</label>
          {NewClaim === true ? (
            <input
              className="input_claim"
              value={appState.user.first_names + ' ' + appState.user.last_name}
              style={{ width: '100%' }}
              type="text"
            />
          ) : (
            <select
              className="input_claim"
              onChange={handleChange}
              style={{ width: '100%', color: '#000' }}
            >
              <option> </option>
              <option value="Frederico Zibia">Frederico Zibia</option>
              <option value="Marcela Mondlane">Marcela Mondlane</option>
              <option value="Maria Loforte"> Maria Loforte</option>
              <option value="Jessica Sitoe">Jessica Sitoe</option>
              <option value="Ana Langa">Ana Langa </option>
              <option value="Dercio Munhame">Dercio Munhame</option>
              <option value="Aires Inacio">Aires Inacio </option>
              <option value="Edmundo Capitania">Edmundo Capitania </option>
              <option value="Ajira Mauricio">Ajira Mauricio </option>
              <option value="Madide Nhantumbo">Madide Nhantumbo</option>
              <option value="Mayra Luis">Mayra Luis </option>
              <option value="Rui Jeque">Rui Jeque</option>
            </select>
          )}
        </Box>

        <Box style={{ width: '14%', marginLeft: '2%', display:'inline-block' }}>
          <label className="label_claims" style={{ width: '150px' }}>
            Numéro de Autorização
          </label>
          <input
            className="input_claim"
            onChange={({ target }) => setAuthno(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>
      </div>
    </div>
  )
}

export default MUP_Claim
