import React, { useContext, useEffect, useState } from 'react'
import './Login.css'
import api from '../core/api'
import { useHistory, Redirect } from 'react-router-dom'
import BetterCareLogo from './BetterCareLogo.png'

import { Center, Box } from '@chakra-ui/react'
import { AppContext } from '../core/app-context'
import Spinner from '../Components/Spinner'

function Login() {
  let history = useHistory()

  const [appState, dispatch] = useContext(AppContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    if (email == '' || password == '') {
      alert('E-mail and Password are required.')
      return
    }

    setLoading(true)
    api
      .post('/api.php', {
        // action is the parameter that the api/gateway/actions.php files we usually use look for
        action: 'login',
        // body is the other parameter that is used with all the variables that will be passed to DataManager.class.php
        // functions.
        body: {
          email: email,
          password: password
        }
      })
      .then(async function (response) {
        console.log(response)
        if (response.data.status === 1) {
          // handle response with positive status

          dispatch({
            type: 'setUser',
            value: response.data.data
          })

          try {
            const localMembers = JSON.parse(localStorage.getItem('members') ?? '{}')

            // Check if members are in localStorage
            if (Array.isArray(localMembers)) {
              const result = await api.post('/api.php', {
                action: 'get_members',
                body: {}
              })
              localStorage.setItem('members', JSON.stringify(result.data.data))
            }
            history.push('/members')
          } catch (error) {
            console.log(error)
          }
          //alert(userInfo)
        } else {
          // handle response with negative status
          alert('Unable to Login')
          console.log(response)
        }

        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)

        // catch if errors while attempting to make request
        console.log(error)
      })
  }

  useEffect(() => {
    console.log(appState)
  }, [appState])

  return (
    <div className="background">
      <Center h="100%" w="100%">
        <div className="form_outline">
          <Center>
            <Box style={{ width: '156px', height: '56px' }}>
              <img
                src={BetterCareLogo}
                style={{ width: '100%', height: '100%', paddingTop: '24px' }}
              />{' '}
            </Box>
          </Center>
          <h1 className="Login_thing"> Faça login no Policy CRM</h1>
          <input
            type="text"
            placeholder="Email"
            className="input_username"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="input_password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />

          <button className="login_button" onClick={handleSubmit} type="submit" disabled={loading}>
            {loading ? <Spinner /> : 'Login'}
          </button>
        </div>
      </Center>
    </div>
  )
}

export default Login
