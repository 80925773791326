import logo from './logo.svg'
import './App.css'
import Login from './Login/Login'

import MembersPage from './MembersPage/MembersPage'
import ProvidersPage from './Providers/ProvidersPage'
import PlansPage from './PlansPage/PlansPage'
import MemberUserPage from './MemberUserPage/MemberUserPage'
import AddMemberPage from './AddMemberPage/AddMemberPage'
import TransactionPage from './TransactionPage/TransactionPage'
import TransactionDetailPage from './TransactionDeet/TransactionDetailPage'
import PlanDetailPage from './PlanDetailPage/PlanDetailPage'
import ProviderTransactionPage from './ProviderTransactionPage/ProviderTransactionPage'
import PrintProviderTrans from './PrintPages/PrintInvoice'
import { StateProvider } from './core/app-context'

import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom'

function App() {
  return (
    <StateProvider> 
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/members" component={MembersPage} />
          <Route exact path="/addmember" component={AddMemberPage} />
          <Route exact path="/memberUser" component={MemberUserPage} />
          <Route exact path="/transaction" component={TransactionPage} />
          <Route exact path="/transactionDetail" component={TransactionDetailPage} />
          <Route exact path="/planDetail" component={PlanDetailPage} />
          <Route exact path="/plan" component={PlansPage} />
          <Route exact path="/providers" component={ProvidersPage} />
          <Route exact path="/providerDetail" component={ProviderTransactionPage} />
        </Switch> 
      </Router>
    </StateProvider>
  )
}

export default App
