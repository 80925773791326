import React, { useState, useEffect } from 'react'
import './MUP.css'
import { Center, Box, IconButton } from '@chakra-ui/react'
import CloseIcon from '@material-ui/icons/Close'
import api from '../core/api'
import MUP_LineItem from '../MUP/MUP_LineItem'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import { formatCurrency } from '../utility/FormatCurrency'

function MUP_Transaction({
  id,
  Providerid,
  memberDetails,
  Planid,
  memb_id,
  main_member_id,
  addT,
  TotalTransValue,
  items,
  RemoveVals
}) {
  //Values that are set according to each transaction
  const [Procedurename, setProcedurename] = useState('')
  const [serviceid, setServiceid] = useState('')
  const [Price, setPrice] = useState('')
  const [AltPrice, setAltPrice] = useState(0)

  const [benefitLine, setBenefitLine] = useState([])
  const [disable, setDisable] = useState(false)

  const [TransValues, setTransValues] = useState([])
  const [uniqueBenefitType, setUniqueBenefitType] = useState([])
  const [uniqueBenefit, setUniqueBenefit] = useState([])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_benefit_line',
        body: {
          planid: Planid
        }
      })
      .then((result) => {
        console.log('Benefit Lines', result.data.data)
        setBenefitLine(result.data.data)

        const uniqueArray = []

        result.data.data.forEach((item) => {
          const index = uniqueArray.findIndex((el) => el.id == item.service_type.id)
          if (index < 0) {
            uniqueArray.push(item.service_type)
          }
        })

        setUniqueBenefitType(uniqueArray)
      })
      .catch((err) => console.warn(err))
  }, [])

  const [benefitAmount, setBenefitAmount] = useState('')
  const [Pamount, setPamount] = useState(0)
  const [refreshKey, setRefreshKey] = useState(1)

  const [benefitClass, setBenefitClass] = useState('')

  const benefitClassName = benefitClass.split('_')[2]

  const [classId, setClassId] = useState([])

  function handleChange(event) {
    setServiceid(event.target.value)
    setBenefitAmount(event.target.value)

    setBenefitClass(event.target.value)
  }

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  const dis = () => {
    setDisable(true)
  }

  const service_id = serviceid.split('_')[0]

  //Function save and submit button
  function SubmitButton() {
    if (
      (Price && Procedurename && bene_amount - TransactionTotal - Pamount >= 0) ||
      (Price === AltPrice && service_id !== 0)
    ) {
      return (
        <button
          style={{ float: 'right' }}
          className="save_item"
          disabled={disable}
          onClick={() => {
            addT({ Procedurename, service_id, Price, AltPrice })
            dis()
          }}
        >
          Salvar item
        </button>
      )
    } else {
      return (
        <button style={{ float: 'right' }} className="save_item_disabled" disabled>
          Salvar item
        </button>
      )
    }
  }

  //ADD PROCEDURE VALUE

  //GETS VALUES TO DETERMINE IF USER HAS ENOUGH MONEY LEFT
  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_member_history',
        body: {
          service_id: serviceid,
          member_id: main_member_id===0?(memb_id):(main_member_id)
        }
      })
      .then((result) => {
        console.log('HISTORIAS JOVEM', result.data.data)
        setTransValues(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [refreshKey])

  const [procedures, setProcedure] = useState([])
  const [filteredProcedures, setFilteredProcedures] = useState([])

  //Procedure function
  //Allows you to search for procedures for specific provider
  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_procedures',
        body: { provider_id: Providerid }
      })
      .then((result) => {
        console.log('procedures', result.data.data)
        setProcedure(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [Providerid])

  const [searchForm, setSearchForm] = useState({
    procedure: ''
  })

  const [resetForm, setResetForm] = useState({
    procedure: ''
  })

  //Filter function for procedures
  const filterProcedures = (filterForm) => {
    let newProcedures = procedures
    if (filterForm.procedure.toString().length > 0) {
      newProcedures = procedures.filter(
        (m) =>
          filterForm.procedure.toString().length > 0 &&
          m.procedure_name.toString().toLowerCase().includes(filterForm.procedure.toLowerCase())
      )
    }

    setFilteredProcedures(newProcedures)
  }

  //To determine if display of search must show
  const [searching, setSearching] = useState('no')

  //Sets price to allow for deductions
  function setPs(event) {
    setPamount(event.target.value)
    setPrice(event.target.value)
  }

  const TransactionTotal =
    TransValues !== null
      ? TransValues.reduce((prev, { price }) => {
          return prev + price
        }, 0)
      : 'hi'

  const AltTransTotal =
    TransValues !== null
      ? TransValues.reduce((prev, { alt_price }) => {
          return prev + alt_price
        }, 0)
      : 'hi'

  let bene_amount = benefitAmount.split('_')[1]

  if (isNaN(bene_amount)) {
    bene_amount = memberDetails.plan.total_amount - TotalTransValue
  } else {
    bene_amount = benefitAmount.split('_')[1]
  }

  let nf = new Intl.NumberFormat('en-US')

  const [generalPrice, setGeneralPrice] = useState('')
  const [altLocalPrice, setAltLocalPrice] = useState('')
  const [LocalPrice, setLocalPrice] = useState('')

  const [clicked, setClicked] = useState(false)

  //Using the radio, where must the price be assinged to
  const selectPricey = (e) => {
    const checked = e.target.value
    if (checked === 'alt_price') {
      //checked
      setAltPrice(generalPrice)
      setPrice('')
      setClicked(true)
      setAltLocalPrice(generalPrice)
    } else if (checked === 'og_price') {
      //unchecked
      setPrice(generalPrice)
      setAltPrice(0)
      setClicked(false)
      setLocalPrice(generalPrice)
    }
  }

  const [onlist, setOnList] = useState(false)

  const [counter, setCounter] = useState(1)

  //If procedure isnt on the list allow for overide
  const here = () => {
    setCounter(counter + 1)

    if (counter % 2 !== 0) {
      setOnList(false)
      setSearching('no')
    } else {
      setOnList(true)
      setSearching('yes')
    }
  }

  // const handleKeypress = (e) => {
  //   if (e.keyCode === 18) {
  //     setCounter(counter + 1)

  //     if (counter % 2 !== 0) {
  //       setOnList(false)
  //       setSearching('no')
  //     } else {
  //       setOnList(true)
  //       setSearching('yes')
  //     }
  //   }
  // }

  const [transes, setTranses] = useState([])

  const addTransaction = () => {
    let nextId = 2

    for (let i = 0; i < transes.length; i++) {
      if (transes[i].id > nextId) {
        nextId = transes[i].id
      }

      nextId++
    }
    setTranses([
      ...transes,
      {
        id: nextId,
        name: `Trans ${nextId}`
      }
    ])
  }

  function handleRemove(id) {
    RemoveVals(id)
  }

  let sumServices = Object.values(
    items.reduce((c, { service_id, price }) => {
      c[service_id] = c[service_id] || { service_id, price: 0 }
      c[service_id].price += +price
      return c
    }, {})
  )

  let groupPrice = 0

  if (sumServices.length > 0) {
    if (sumServices[0].service_id === service_id) {
      groupPrice = sumServices[0].price
    }
  }

  if (sumServices.length > 1) {
    if (sumServices[1].service_id === service_id) {
      groupPrice = sumServices[1].price
    }
  }

  if (sumServices.length > 2) {
    if (sumServices[2].service_id === service_id) {
      groupPrice = sumServices[2].price
    }
  }

  if (sumServices.length > 3) {
    if (sumServices[3].service_id === service_id) {
      groupPrice = sumServices[3].price
    }
  }

  if (sumServices.length > 4) {
    if (sumServices[4].service_id === service_id) {
      groupPrice = sumServices[4].price
    }
  }

  if (sumServices.length > 5) {
    if (sumServices[5].service_id === service_id) {
      groupPrice = sumServices[5].price
    }
  }

  if (sumServices.length > 6) {
    if (sumServices[6].service_id === service_id) {
      groupPrice = sumServices[6].price
    }
  }

  const [AllFetchedTrans, setAllTrans] = useState([])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_transaction_for_print',
        body: {
          member_id: memb_id
        }
      })
      .then((result) => {
        console.log('Transactions for The Mans', result.data.data)
        setAllTrans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  let thePrice = 0
  AllFetchedTrans.slice(0, 100).map((e) => {
    e.transactions.map((ey) => {
      thePrice += ey.price < 15 ? ey.alt_price : ey.price
    })
  })

  const handleChangeSelectBenefitType = (e) => {
    if (!e) {
      setUniqueBenefit([])
      return
    }

    const benefit = []
    benefitLine.forEach((el) => {
      if (el.service_type_id == e) benefit.push(el)
    })

    setUniqueBenefit(benefit)
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings" style={{ paddingTop: '10px' }}>
          Transação{' '}
          <button className={onlist !== true ? 'btn_onList' : 'btn_notList'} onClick={here}>
            {onlist !== true ? 'Nao na Tabela' : 'Na Tabela'}{' '}
          </button>
          <div style={{ float: 'right', marginLeft: '400px' }}>
            <IconButton
              onClick={addTransaction}
              className="save_item_btn"
              icon={<PlaylistAddIcon style={{ height: '18px' }} />}
            />
          </div>
        </p>

        <SubmitButton />

        <Box style={{ width: '35%', display: 'inline-block' }}>
          <label className="label_claims">Tipo de Beneficio</label>

          <select
            className="input_claim"
            onChange={(e) => handleChangeSelectBenefitType(e.target.value)}
            disabled={disable}
            style={{ width: '100%', color: '#000' }}
          >
            <option> </option>
            {uniqueBenefitType.map((type) => (
              <option value={type.id}>{type.name}</option>
            ))}
          </select>
          {/* <input
            className="input_claim"
            value={benefitClassName}
            style={{ width: '100%' }}
            type="text"
          /> */}
        </Box>

        <Box style={{ width: '35%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">
            Benefício <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <select
            className="input_claim"
            onClick={refresh}
            onChange={handleChange}
            disabled={disable}
            style={{ width: '100%', color: '#000' }}
          >
            <option> </option>
            {uniqueBenefit.map((benefit) => (
              <option value={benefit.id + '_' + benefit.amount + '_' + benefit.service_type.name}>
                {benefit.service_name}{' '}
              </option>
            ))}
          </select>
        </Box>

        <div>
          <Box style={{ width: '35%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">
              Procedimento <span style={{ color: '#ED3737' }}>*</span>
            </label>
            {onlist ? (
              <input
                className="input_claim"
                disabled={disable}
                onChange={(e) => {
                  filterProcedures(searchForm)

                  setSearchForm({
                    ...searchForm,
                    procedure: e.currentTarget.value
                  })
                }}
                onFocus={(e) => {
                  setSearching('yes')
                }}
                value={searchForm.procedure}
                //onKeyDown={handleKeypress}
                style={{ width: '90%', display: 'inline-block' }}
                type="text"
              />
            ) : (
              <input
                className="input_claim"
                disabled={disable}
                onChange={({ target }) => setProcedurename(target.value)}
                style={{ width: '90%', display: 'inline-block' }}
                //onKeyDown={handleKeypress}
                type="text" 
              />
            )}
            <div
              className={searching === 'yes' ? 'display_icon' : 'display_nothing'}
              style={{ alignItems: 'bottom', height: '28px', width: '20px' }}
            >
              <CloseIcon
                onClick={() => {
                  setSearching('no')
                  setSearchForm({ procedure: '' })
                }}
                style={{ width: '20px', height: '20px' }}
              />
            </div>
            <div className={searching === 'yes' ? 'display_result' : 'display_nothing'}>
              {filteredProcedures.slice(0, 25).map((e) => {
                return (
                  <h5
                    value={e.name}
                    onClick={() => {
                      setProcedurename(e.procedure_name)
                      setSearchForm({ procedure: e.procedure_name })
                      setSearching('no')
                      setPamount(e.price)
                      setGeneralPrice(e.price)
                      setPrice(e.price)
                    }}
                    className="dataItem"
                  >
                    {e.procedure_name}
                  </h5>
                )
              })}
            </div>
          </Box>

          <Box
            style={{ width: '15%', display: 'inline-block', marginLeft: '13%', marginTop: '2%' }}
          >
            <label className="label_claims">
              Preço(MT) <span style={{ color: '#ED3737' }}>*</span>
            </label>
            {/*<input className="input_claim"  onChange={({ target }) => setPrice(target.value)} placeholder='auto' style={{ width: '100%' }} type="text" />*/}

            <input className="input_claim" onChange={setPs} style={{ width: '100%' }} type="text" />

            {/*} <input
                            type="checkbox"
                            onClick={(e) => {
                                selectPricey(e);
                            }}
                        />*/}
          </Box>

          <Box style={{ width: '15%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
            <label className="label_claims">Preço Alternativo(MT)</label>

            <input
              className="input_claim"
              onChange={({ target }) => setAltPrice(target.value)}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
        </div>

        {transes.map((e) => {
          return (
            <MUP_LineItem
              addT={addT}
              id={e.id}
              service_id={service_id}
              setProcedurename={setProcedurename}
              setPrice={setPrice}
              setAltPrice={setAltPrice}
              Procedurename={Procedurename}
              Price={Price}
              AltPrice={AltPrice}
              handleRemove={handleRemove}
            />
          )
        })}

        <div style={{ display: 'block' }}>
          <Box
            className={onlist !== true ? 'display_nothing' : 'display_som'}
            style={{ width: '35%', display: 'inline-block', marginTop: '2%' }}
          >
            <label style={{ marginBottom: '5px' }} className="label_claims">
              {/* Preço efetivo<span style={{ color: '#ED3737' }}>*</span> */}
            </label>
            {/* <Box style={{ display: 'inline-block' }}>
							<label className="radio_value">
								<input
									name={`r.${id}`}
									disabled={disable}
									onClick={(e) => {
										selectPricey(e);
									}}
									type="radio"
									value="og_price"
									className={onlist !== true ? 'display_nothing' : 'display_som'}
								/>
								Preço original
							</label>
						</Box> */}
            {/* <Box style={{ display: 'inline-block', marginLeft: '24px' }}>
							<label className="radio_value">
								<input
									name={`r.${id}`}
									disabled={disable}
									onClick={(e) => {
										selectPricey(e);
									}}
									type="radio"
									value="alt_price"
									className={onlist !== true ? 'display_nothing' : 'display_som'}
								/>
								Preço alternativo
							</label>
						</Box> */}
          </Box>

          <Box
            style={{ width: '18%', display: 'inline-block', marginLeft: '13%', marginTop: '2%' }}
          >
            <label className="label_claims">Valor Total</label>
            <input
              className="input_claim"
              value={nf.format(groupPrice)}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
          <Box style={{ width: '18%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
            <label className="label_claims">Saldo</label>
            {formatCurrency}
            <input
              className="input_claim"
              // value={nf.format(bene_amount - TransactionTotal - groupPrice)}
              value={formatCurrency(bene_amount - TransactionTotal - groupPrice)}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
        </div>
      </Box>

      <br />
    </div>
  )
}

export default MUP_Transaction
