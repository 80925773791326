import React, { useState, useEffect } from 'react'
import './AMP.css'
import { Center, Box, Button, IconButton } from '@chakra-ui/react'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import AttachmentIcon from '@material-ui/icons/Attachment'
import DOENCA from './AMP_Doenca'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'

function AMP_Page4({
  previousStep,
  sickness,
  setCondition,
  setTreatment,
  setDot,
  setState,
  addCondition,
  saveCondition
}) {
  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="amp_page1_heading" style={{ paddingTop: '10px' }}>
          Dados da Cobertura Pretendida
        </p>
        <IconButton
          style={{ float: 'right' }}
          onClick={addCondition}
          className="add_btn_l"
          icon={<AddIcon />}
        />

        <p className="amp_sub_heading" style={{ width: '90%' }}>
          Se respondeu "sim" a alguma das questões acima mencionadas, por favor, complete o quadro
          abaixo na totalidade. Se necessitar de espaço adicional, por favor, utilize a Secção D
          e/ou uma folha á parte e anexe-a ao formulário. Por favor, anexe também os respectivos
          relatórios médicos, se os tiver.
        </p>

        {sickness.map((e) => {
          return (
            <DOENCA
              setSick={setCondition}
              setTreat={setTreatment}
              setDot={setDot}
              setS={setState}
              saveSickness={saveCondition}
            />
          )
        })}

        <div style={{ display: 'block', width: '86%', marginTop: '40px', textAlign: 'end' }}>
          <Button
            onClick={previousStep}
            leftIcon={<ArrowBackIosIcon style={{ height: '12px' }} />}
            className="prev_sect_btn"
          >
            Previous Section{' '}
          </Button>
        </div>
      </Box>
      <br />
    </div>
  )
}
export default AMP_Page4
