import React, { useEffect, useState, useContext } from 'react'
import './PlanDetailPage.css'
import Header from '../Top/Header'
import SubHeader from './SubHeader'
import { useHistory, Redirect } from 'react-router-dom'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Center, Box } from '@chakra-ui/react'

import PDP_Account from '../PDP/PDP_Account'
import PDP_Plan from '../PDP/PDP_Plan'
import PDP_CA from '../PDP/PDP_CA'

import api from '../core/api'
import { AppContext } from '../core/app-context'
import InsertNewServiceType from '../Modal/InsertNewServiceType'

function PlanDetailPage(props) {
  let history = useHistory()
  const [planDetails, setPlanDetails] = useState(null)

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_plan_details',
        body: {
          plan_id: props.location.state.plan_id
        }
      })
      .then((result) => {
        console.log('Plan DEETS', result.data.data)

        if (result.data.data !== null) {
          if (Array.isArray(result.data.data.service_types)) {
            setPlanDetails(result.data.data)
            return
          }

          const objectToArray = Object.entries(result.data.data.service_types)
          const newPlansArray = []

          objectToArray.forEach((item) => {
            newPlansArray.push(item[1])
          })

          setPlanDetails({ ...result.data.data, service_types: newPlansArray })
        }
      })
      .catch((err) => console.warn(err))
  }, [])

  const [BenefitName, setBenefitName] = useState('')
  const [benefitAmount, setBenefitAmount] = useState('')
  const [region, setRegion] = useState('')
  const [rede, setRede] = useState('')

  const defaultV = [
    {
      date: '2021-10-27',
      id: 8,
      name: 'Bettercare Family Max',
      rede: 'Geral',
      regiao: 'Mocambique, SA e India',
      service_types: {
        amount: 'Coberto',
        created_at: '2021-10-02 10:51:00',
        id: 1,
        plan_id: '9',
        service_name: 'Internamento Hospitalar',
        service_type_id: '5',
        service_type_name: 'Beneficios Hospitalares - Paciente'
      },
      total_amount: '5000000'
    }
  ]

  return (
    <div className="main-page">
      <div className="header_member">
        <Header />
      </div>
      <Box style={{ height: '6%', marginTop: '-8px' }}>
        <SubHeader />
      </Box>

      <div style={{ width: '100%', height: '87.5%', marginTop: '0.5%' }}>
        <Tabs variant="enclosed">
          <div
            style={{ marginTop: '10px', width: '30%', marginLeft: '32px', position: 'absolute' }}
          >
            <TabList></TabList>

            <TabPanels>
              <TabPanel>
                <PDP_CA planDetail={planDetails !== null ? planDetails : defaultV} />
              </TabPanel>

              <TabPanel>2</TabPanel>
            </TabPanels>
          </div>

          <div style={{ width: '33%', height: '27%', display: 'inline-block' }} />

          <Box
            style={{
              width: '65%',
              height: '95%',
              marginLeft: '10px',
              marginTop: '1%',
              border: '1px solid #fff',
              display: 'inline-block',
              overflowX: 'hidden',
              overflowY: 'scroll'
            }}
          >
            <Box style={{ width: '100%', height: '60vh', marginTop: '16px' }}>
              <TabPanels>
                <TabPanel style={{ width: '100%', height: '60vh' }}>
                  <Box
                    style={{
                      height: '8vh',
                      marginBottom: '20px',
                      width: '100%',
                      background: '#fff'
                    }}
                  >
                    <p className="headings" style={{ display: 'inline-block' }}>
                      Beneficios{' '}
                    </p>

                    {planDetails !== null &&
                      Array.isArray(planDetails.service_types) &&
                      planDetails.service_types.map((e) => {
                        return (
                          <PDP_Account
                            benefit_name={e[0].service_type_name}
                            items={e}
                            plan_amount={planDetails.total_amount}
                          />
                        )
                      })}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Box>
          </Box>
        </Tabs>
      </div>
    </div>
  )
}

export default PlanDetailPage
