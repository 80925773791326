import React, { useContext, useEffect, useState } from 'react'
import './MembersPage.css'

import { Center, Box } from '@chakra-ui/react'
import TopMembers from '../Top/TopMembers'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useHistory, Redirect } from 'react-router-dom'
import { AppContext } from '../core/app-context'
import api from '../core/api'

import Pagination from './Pagination'

function MembersPage() {
  useEffect(() => {
    document.title = 'Bettercare Claims App'
  }, [])

  const [appState, dispatch] = useContext(AppContext)

  const [members, setmembers] = useState([])
  const [filteredMembers, setFilteredMembers] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [membersPerPage, setMembersPerPage] = useState(7)
  const [refreshKey, setRefreshKey] = useState(1)

  //Get Current Posts
  const indexOfLastMember = currentPage * membersPerPage
  const indexOfFirstMember = indexOfLastMember - membersPerPage
  const currentMembers = members.slice(indexOfFirstMember, indexOfLastMember)

  //Change page

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const handleNextPage = () => {
    if (currentPage === members.length / membersPerPage) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleLastPage = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  let history = useHistory()

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  useEffect(async () => {
    const localMembers = JSON.parse(localStorage.getItem('members') ?? '{}')

    // Check if members are in localStorage
    if (Array.isArray(localMembers)) {
      setmembers(localMembers)
    } else {
      try {
        const result = await api.post('/api.php', {
          action: 'get_members',
          body: {}
        })
        localStorage.setItem('members', JSON.stringify(result.data.data))
        setmembers(result.data.data)
      } catch (error) {
        console.warn(error)
      }
    }
    // setmembers(localMembers)
    // api
    //   .post('/api.php', {
    //     action: 'get_members',
    //     body: {}
    //   })
    //   .then((result) => {
    //     console.log('members', result.data.data)
    //     setmembers(result.data.data)
    //   })
    //   .catch((err) => {
    //     console.warn(err)
    //   })
  }, [refreshKey])

  useEffect(() => {
    console.log(appState)
  }, [appState])

  useEffect(() => {
    console.log("members", members);
    setFilteredMembers(members)
  }, [members])

  const filterMembers = (filterForm) => {
    let newMembers = members
    if (
      filterForm.member.toString().length > 0 ||
      filterForm.policyNo.toString().length > 0 ||
      filterForm.plan.toString().length > 0
    ) {
      newMembers = members.filter(
        (m) =>
          (filterForm.member.toString().length > 0 &&
            `${m.first_names} ${m.last_name}`
              .toString()
              .toLowerCase()
              .includes(filterForm.member.toString().toLowerCase())) ||
          (filterForm.member.toString().length > 0 &&
            `${m.member_no}`.toString().includes(filterForm.member.toString())) ||
          (filterForm.policyNo.toString().length > 0 &&
            m.policy_no.toString().includes(filterForm.policyNo.toString())) ||
          (filterForm.plan.toString().length > 0 &&
            m.plan.name.toString().toLowerCase().includes(filterForm.plan.toString().toLowerCase()))
      )
    }

     console.log("newMembers", newMembers);
    setFilteredMembers(newMembers)
  }

  return (
    <div>
      <Box style={{ height: '200px' }}>
        <TopMembers refresh={refresh} onSearch={filterMembers} setCurrentPage={setCurrentPage} />
      </Box>

      <Box style={{ width: '100%' }}>
        <Center>
          <table className="member_table">
            <thead className="members_head">
              <th>Membro</th>
              <th>Número de apólice</th>
              <th>Plano</th>
              <th>Detalhes do contato</th>
              <th>Data final</th>
            </thead>

            {/* {currentMembers.map((e) => { */}
            {filteredMembers.slice(indexOfFirstMember, indexOfLastMember).map((e) => {
              return (
                <tr
                  key={e.id}
                  onClick={() =>e.status==="Cancelado"?(""):(
                    history.push({
                      pathname: '/memberUser',
                      // search: '?query=abc',
                      state: { member_id: e.id,
                      main_member_id: e.main_member_id }
                    }))
                  }
                 
                  className={e.status==="Cancelado" ? 'orange' : 'main_table_inside'}
                >
                  <td style={{ width: '29.79%' }} >
                    {e.first_names} {e.last_name} 
                    <span className="span_member">{e.member_no}</span>
                  </td>
                  <td style={{ width: '14.23%' }}>{e.policy_no}</td>
                  <td style={{ width: '14.23%' }}>{e.plan.name}</td>
                  <td style={{ width: '14.23%' }}>
                    {e.phone}
                    <span className="span_member">{e.email}</span>
                  </td>
                  <td style={{ width: '14.23%' }}>{e.end_date} </td>
                  <td style={{ width: '7.29%' }}>
                    <ArrowRightIcon />{' '}
                  </td>
                </tr>
              )
            })}
          </table>
        </Center>
      </Box><br/>
      <Pagination
        setMembersPerPage={setMembersPerPage}
        handleNextPage={handleNextPage}
        currentPage={currentPage}
        handleLastPage={handleLastPage}
        membersPerPage={membersPerPage}
        totalMembers={members.length}
        paginate={paginate}
      />
    </div>
  )
}

export default MembersPage
