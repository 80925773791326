import React, { useEffect, useState, useContext } from 'react'
import './MemberUserPage.css'
import Header from '../Top/Header'
import SubHeader from './SubHeader'
import { useHistory, Redirect } from 'react-router-dom'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Center, Box } from '@chakra-ui/react'
import TopMembers from '../Top/TopMembers'
import MUP_userDet from '../MUP/MUP_userDet'
import MUP_userDet_Read from '../MUP/MUP_userDet_Read'
import MUP_History from '../MUP/MUP_History'
import MUP_CA from '../MUP/MUP_CA'
import MUP_Claim from '../MUP/MUP_Claim'
import MUP_ClaimRefund from '../MUP/MUP_ClaimRefund'

import MUP_Account from '../MUP/MUP_Account'
import MUP_Transaction from '../MUP/MUP_Transaction'
import MUP_TransactionRefund from '../MUP/MUP_TransactionRefund'
import MUP_LeftDeet from '../MUP/MUP_LeftDeet'
import api from '../core/api'
import { AppContext } from '../core/app-context'

function MemberUserPage(props) {
  const [appState, dispatch] = useContext(AppContext)
  const [percentagemAmount, setPercentageAmount] = useState(100)
  const [memberDetails, setMemberDetails] = useState(null)
  const [trans, setTrans] = useState([])
  const [networkProvider, setNetworkProvider] = useState('true')

  const defaultvals = [
    {
      created_at: '',
      email: '',
      end_date: '',
      first_names: '',
      id: 2,
      id_number: '',
      last_name: '',
      member_no: '',
      phone: '',
      plan: { id: 1, name: '', total_amount: '', service_types: Array(4) },
      plan_id: '1',
      policy_no: '',
      start_date: '',
      waiting_period: ''
    }
  ]

  const getRanHex = (size) => {
    let result = []
    let hexRef = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H'
    ]

    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)])
    }
    return result.join('')
  }

  const addTransaction = () => {
    let nextId = 0

    for (let i = 0; i < trans.length; i++) {
      if (trans[i].id > nextId) {
        nextId = trans[i].id
      }

      nextId++
    }
    setTrans([
      ...trans,
      {
        id: nextId,
        name: `Trans ${nextId}`,

      }
    ])
  }

  const [Providerid, setProviderid] = useState('')

  const [Authno, setAuthno] = useState('')
  const [Authby, setAuthby] = useState('')
  const [Timey, setTime] = useState('')
  const [Datey, setDate] = useState('')
  const [RefName, setRefName] = useState('')

  const [history_service_id, setHistoryserviceid] = useState('')
  const [histroy_service_name, setHistoryservicename] = useState('')

  const [items, setItems] = useState([])

  const [number, setNumber] = useState(1)

  const [nClaim, setNClaim] = useState(true)



  const addT = ({ Procedurename, service_id, Price, AltPrice }) => {

    
    setNumber(number + 1)

    const newItem = {
      id: number,
      provider_id: Providerid,
      procedure_name: Procedurename,
      auth_no: Authno,
      member_id:  props.location.state.main_member_id===0?(props.location.state.member_id):(props.location.state.main_member_id),
      service_id: service_id,
      price: Price,
      ref_name: RefName,
      alt_price: AltPrice,
      date: Datey,
      time: Timey,
      auth_by: Authby
    }

    const newItems = [...items, newItem]

    setItems(newItems)
  }

  const [theID, setTheId] = useState('')

  function RemoveVals(id) {
    const newList = items.filter((item) => item.id !== id)

    setItems(newList)
  }
  const deletee = () => {
    var toRemove = 'test'
    var index = items.indexOf(toRemove)
    if (index > -1) {
      items.splice(index, 1)
    }
  }

  console.log('The shandies', items)

  const SubmitTrans = () => {
    const post_params = JSON.stringify(items)

    // alert(post_params)
    api
      .post('/api.php', {
        action: 'submit_transaction',

        body: {
          post_params
        }
      })
      .then(function (response) {
        if (response.data.status === 1) {
          alert(response.data.message)
          history.push('/members')
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const [Planid, setPlanid] = useState('')

  let history = useHistory()

  const [refreshKey, setRefreshKey] = useState(1)

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_member_details',
        body: {
          member_id: props.location.state.member_id,
          main_member_id: props.location.state.main_member_id
        }
      })
      .then((result) => {
        console.log('member details Jovem', result.data.data)
        setMemberDetails(result.data.data)
        setPlanid(result.data.data.plan_id)
        //setAuthno(getRanHex(6));
        //setAuthby(appState.user.first_names + ' ' + appState.user.last_name);
      })
      .catch((err) => console.warn(err))
  }, [refreshKey])

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  const [serv_name, setServname] = useState('')
  const [memberHistory, setMemberhistory] = useState([])

  const service_id = history_service_id.split('_')[0]
  const service_name = history_service_id.split('_')[1]

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_member_history',
        body: {
          member_id: props.location.state.main_member_id===0?(props.location.state.member_id):(props.location.state.main_member_id)
        }
      })
      .then((result) => {
        console.log('member history', result.data.data)
        setMemberhistory(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  const submit_history = () => {
    setServname(service_name)

    api
      .post('/api.php', {
        action: 'get_member_history',
        body: {
          service_id: service_id,
          member_id: props.location.state.main_member_id===0?(props.location.state.member_id):(props.location.state.main_member_id)
        }
      })
      .then((result) => {
        console.log('member history', result.data.data)
        setMemberhistory(result.data.data)
      })
      .catch((err) => console.warn(err))
  }

  const [AllFetchedTrans, setAllTrans] = useState([])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_all_trans_for_individual',
        body: {
          member_id: props.location.state.main_member_id===0?(props.location.state.member_id):(props.location.state.main_member_id)
        }
      })
      .then((result) => {
        console.log('Every Single Transaction Ntwana', result.data.data)
        setAllTrans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  const TotalTransValue =
    AllFetchedTrans !== null
      ? AllFetchedTrans.reduce((prev, { price }) => {
          return prev + price
        }, 0)
      : 'a'

  const [Editing, setEditing] = useState(false)

  const handleEditClick = (event, memberDetails) => {
    event.preventDefault()

    setEditing(true)

    const formValues = {
      id: memberDetails.id,
      first_names: memberDetails.first_names,
      last_name: memberDetails.last_name,
      d_o_b: memberDetails.d_o_b,
      policy_no: memberDetails.policy_no,
      member_no: memberDetails.member_no,
      id_number: memberDetails.id_number,
      email: memberDetails.email,
      phone: memberDetails.phone,
      plan_id: memberDetails.plan_id,
      start_date: memberDetails.start_date,
      end_date: memberDetails.end_date,
      waiting_period: memberDetails.waiting_period,
      created_at: memberDetails.created_at,
      status: memberDetails.status
    }

    setEditMemberData(formValues)
  }

  const [editMemberData, setEditMemberData] = useState({
    id: '',
    first_names: '',
    last_name: '',
    d_o_b: '',
    policy_no: '',
    member_no: '',
    id_number: '',
    email: '',
    phone: '',
    plan_id: '',
    start_date: '',
    end_date: '',
    waiting_period: '',
    created_at: '',
    status: ''
  })

  const handleEditMemberChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value

    const newStatData = { ...editMemberData }
    newStatData[fieldName] = fieldValue

    setEditMemberData(newStatData)
  }

  //UPDATE MEMBER INFORMATION ON HISTORY TAB
  const UpdateMember = () => {
    api
      .post('/api.php', {
        action: 'update_member',

        body: {
          member_id: props.location.state.member_id,
          first_names: editMemberData.first_names,
          last_name: editMemberData.last_name,
          policy_no: editMemberData.policy_no,
          member_no: editMemberData.member_no,
          id_number: editMemberData.id_number,
          email: editMemberData.email,
          phone: editMemberData.phone,
          start_date: editMemberData.start_date,
          end_date: editMemberData.end_date,
          status: editMemberData.status
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
          refresh()
          setEditing(false)
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const [refunding, setRefund] = useState('no')

  const [clicked, setClicked] = useState(false)

  const [claimPayTo, setClaimPayTo] = useState('provider')

  const [clickedAuth, setClickedAuth] = useState(true)

  const ClaimPays = (e) => {
    const checked = e.target.value
    if (checked === 'provider') {
      //checked
      setRefund('no')
      setClaimPayTo('provider')
    } else if (checked === 'member') {
      //unchecked
      setClaimPayTo('member')
      setRefund('yes')
    }
  }

  const selected_tab = (e) => {
    const picked = e.target.value
    if (picked === 'history') {
      setClickedAuth(false)
    } else if (picked === 'authorization') {
      setClickedAuth(true)
    }
  }

  return (
    <div className="main-page">
      <div className="header_member">
        <Header />
      </div>
      <Box className="SH" style={{ height: '6%', marginTop: '-8px' }}>
        <SubHeader />
      </Box>

      <div style={{ width: '100%', height: '87.5%', marginTop: '0.5%' }}>
        <Tabs variant="enclosed">
          <div
            style={{ marginTop: '10px', width: '30%', marginLeft: '32px', position: 'absolute' }}
          >
            <TabList>
              {/*<Tab
								_selected={{
									color: '#FF5A5D',
									bg: '#FFF1F3',
									border: '1px solid #FF5A5D',
									opacity: '1',
								}}
								className="tab_btn"
							>
								Conta
							</Tab> */}
              <Tab
                _selected={{
                  color: '#FF5A5D',
                  bg: '#FFF1F3',
                  border: '1px solid #FF5A5D',
                  opacity: '1'
                }}
                className="tab_btn"
                value="authorization"
                onClick={(e) => {
                  selected_tab(e)
                }}
              >
                Autorização
              </Tab>

              <Tab
                _selected={{
                  color: '#FF5A5D',
                  bg: '#FFF1F3',
                  border: '1px solid #FF5A5D',
                  opacity: '1'
                }}
                className="tab_btn"
                value="history"
                onClick={(e) => {
                  selected_tab(e)
                }}
              >
                História
              </Tab>
            </TabList>

            <TabPanels>
              {/*<TabPanel>
								
							</TabPanel>*/}

              <TabPanel>
                {memberDetails !== null ? (
                  <MUP_LeftDeet
                    addTransaction={addTransaction}
                    SubmitTrans={SubmitTrans}
                    memberDetails={memberDetails !== null ? memberDetails : defaultvals}
                    transNums={trans.length}
                    memb_id={props.location.state.member_id}
                    main_member_id={props.location.state.main_member_id}
                    TotalTransValue={TotalTransValue}
                    nClaim={nClaim}
                  />
                ) : (
                  <div />
                )}
              </TabPanel>

              <TabPanel>
                <MUP_CA
                  setHistoryserviceid={setHistoryserviceid}
                  setHistoryservicename={setHistoryservicename}
                  submit_history={submit_history}
                  Planid={Planid}
                  member_id={props.location.state.member_id}
                  memberDetails={memberDetails !== null ? memberDetails : defaultvals}
                />
              </TabPanel>
            </TabPanels>
          </div>

          <div
            className="overall_pagee"
            style={{ width: '33%', height: '27%', display: 'inline-block' }}
          />

          <Box
            style={{
              width: '65%',
              height: '95%',
              marginLeft: '10px',
              marginTop: '1%',

              display: 'inline-block',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
          >
            {appState.user.position === 'editor' ? (
              clickedAuth ? (
                <div style={{ height: '32px' }}>
                  <Box style={{ display: 'inline-block', width: '100px' }}>
                    <label className="label_claims">Claim Paga :</label>
                  </Box>
                  <Box style={{ display: 'inline-block', width: '160px' }}>
                    <label className="radio_value">
                      Provedor
                      <input
                        name="radio1"
                        style={{ color: '#000' }}
                        onClick={(e) => {
                          ClaimPays(e)
                        }}
                        type="radio"
                        checked={claimPayTo === 'provider'}
                        value="provider"
                      />
                    </label>
                  </Box>

                  <Box style={{ display: 'inline-block', width: '160px' }}>
                    <label className="radio_value">
                      Membro
                      <input
                        name="radio1"
                        onClick={(e) => {
                          ClaimPays(e)
                        }}
                        type="radio"
                        checked={claimPayTo === 'member'}
                        value="member"
                      />
                    </label>
                  </Box>
                </div>
              ) : (
                <div style={{ height: '32px' }} />
              )
            ) : (
              <div />
            )}

            <Box
              className="overall_pagee"
              style={{
                width: '100%',
                height: '180px',
                border: '1px solid #fff',
                backgroundColor: '#fff'
              }}
            >
              <TabPanels>
                {/*
								<TabPanel>
									<MUP_userDet {...memberDetails} />
								</TabPanel> */}

                <TabPanel>
                  {refunding === 'yes' ? (
                    <MUP_ClaimRefund
                      setProviderid={setProviderid}
                      setNetworkProvider={setNetworkProvider}
                      setAuthno={setAuthno}
                      setDate={setDate}
                      setTime={setTime}
                      setRefName={setRefName}
                      setRefund={setRefund}
                      setPercentageAmount={setPercentageAmount}
                    />
                  ) : (
                    <MUP_Claim
                      setProviderid={setProviderid}
                      setAuthno={setAuthno}
                      setDate={setDate}
                      setTime={setTime}
                      setRefName={setRefName}
                      setRefund={setRefund}
                      setAuthby={setAuthby}
                      setNClaim={setNClaim}
                    />
                  )}
                </TabPanel>

                <TabPanel>
                  {Editing === true ? (
                    <MUP_userDet
                      editMemberData={editMemberData}
                      handleEditMemberChange={handleEditMemberChange}
                      UpdateMember={UpdateMember}
                    />
                  ) : (
                    <MUP_userDet_Read
                      memberDetails={memberDetails !== null ? memberDetails : defaultvals}
                      handleEditClick={handleEditClick}
                      editMemberData={editMemberData}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </Box>

            <Box
              className="overall_pagee"
              style={{ width: '100%', height: '60vh', marginTop: '9px' }}
            >
              <TabPanels>
                {/*<TabPanel style={{ backgroundColor: '#fff', width: '100%', height: '60vh' }}>
									{memberDetails !== null ? (
										memberDetails.plan.service_types.map((e) => {
											return <MUP_Account benefit_name={e[0].service_type_name} items={e} TotalTransValue={TotalTransValue} plan_amount={memberDetails.plan.total_amount}  />;
										}) 
									) : (
										<div />
									)}
								</TabPanel>*/}

                <TabPanel style={{ width: '99.5%', height: '60vh', overflowY: 'scroll' }}>
                  {trans.map((e) => {
                    return refunding === 'no' ? (
                      <MUP_Transaction
                        key={e.id}
                        RemoveVals={RemoveVals}
                        id={e.id}
                        name={e.name}
                        Planid={Planid}
                        memberDetails={memberDetails}
                        trans={trans}
                        addT={addT}
                        Providerid={Providerid}
                        memb_id={props.location.state.member_id}
                        main_member_id={props.location.state.main_member_id}
                        TotalTransValue={TotalTransValue}
                        items={items}
                        setTheId={setTheId}
                      />
                    ) : (
                      <MUP_TransactionRefund
                        key={e.id}
                        id={e.id}
                        name={e.name}
                        Planid={Planid}
                        memberDetails={memberDetails}
                        trans={trans}
                        addT={addT}
                        Providerid={Providerid}
                        memb_id={props.location.state.member_id}
                        TotalTransValue={TotalTransValue}
                        networkProvider={networkProvider}
                        percentagemAmount={percentagemAmount}
                      />
                    )
                  })}
                  <br />
                  <br />
                  <br />
                </TabPanel>

                <TabPanel style={{ backgroundColor: '#fff', width: '99.5%', height: '60vh' }}>
                  <MUP_History
                    member_id={props.location.state.member_id}
                    history_service_id={history_service_id}
                    histroy_service_name={histroy_service_name}
                    memberHistory={memberHistory}
                    serv_name={serv_name}
                    memberDetails={memberDetails !== null ? memberDetails : defaultvals}
                  />
                </TabPanel>
              </TabPanels>
            </Box>
          </Box>
        </Tabs>
      </div>
    </div>
  )
}

export default MemberUserPage
