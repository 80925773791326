import React, { useState, useEffect , useContext } from 'react'
import './TopOfPage.css'
import Header from './Header'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory, Redirect } from 'react-router-dom'
import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import PrintProviderTrans from '../PrintPages/PrintProvidersTrans'
import AddIcon from '@material-ui/icons/Add'
import { AppContext } from '../core/app-context'

function TopClaims({ onSearch, setCurrentPage }) {

  const [appState, dispatch] = useContext(AppContext)

  

  const [searchForm, setSearchForm] = useState({
    member: '',
    prov: '',
    auth_no: ''
  })

  const [resetForm, setResetForm] = useState({
    member: '',
    prov: '',
    auth_no: ''
  })

  const [start_date, setStartDate] = useState("");

  const [end_date, setEndDate] = useState("");

  const handleSubmit = () => {}

  useEffect(() => {
    console.log('searchForm', searchForm)
  }, [searchForm])

  let history = useHistory()

  

  return (
    <div className="main-page">
      <div className="header">
        <Header active="transactions" />
      </div>

      <Center className="subheader" style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}}>
        <Box className="top_pagebox">
          <h1 style={{ display: 'inline-flex' }} className="subheader_heading">
            Transações
          </h1>

           {appState.user.position === 'editorz' ? ( <PrintProviderTrans start_date = {start_date} end_date = {end_date} />):(<p> </p>)}
 <div
            style={{ width: '100%', height: '1px', backgroundColor: '#EFEFEF', marginTop: '18px' }}
          />


          <div style={{ marginTop: '1.3%', display: 'inline-block', width: '21.5%' }}>
            <label className="labels">Membro</label>
            <input
              className="inputs_sidebar"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  member: e.currentTarget.value
                })
              }}
              style={{ width: '100%' }}
              type="text"
            />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%'
            }}
          >
            <label className="labels">Provedor</label>
            <input
              className="inputs_sidebar"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  prov: e.currentTarget.value
                })
              }}
              style={{ width: '100%' }}
              type="text"
            />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%'
            }}
          >
            <label className="labels">Numéro de Autorização</label>
            <input
              className="inputs_sidebar"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  auth_no: e.currentTarget.value
                })
              }}
              style={{ width: '100%' }}
              type="text"
            />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '1.7%' }}>
            <IconButton
              className="search_btn"
              style={{ width: '50px', height: '28px' }}
              onClick={() => {
                onSearch(searchForm)
                setCurrentPage(1)
              }}
              icon={<SearchIcon style={{ height: '18px' }} />}
            />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '10.7%' }}>
            <Button
              style={{ width: '48px', height: '28px', fontSize: '13px' }}
              className="trash_btn"
              v
              onClick={() => {
                onSearch(resetForm)
              }}
            >
              {' '}
              Reset
            </Button>
          </div>
        
        <div style={{display:'none'}}>
          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%',
              
            }}
            className="inputs_sidebar"
          >
          <p style={{display:'inline-block', fontSize:'10px'}}>Start</p>
            <input
            	onChange={({ target }) => setStartDate(target.value)}
              style={{ width: '70%', display:'inline-block', border:'none', marginLeft:'10px' }}
              type="date"
            />

          </div>

           <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
             marginLeft:'1%'
            }}
            className="inputs_sidebar"
          >
            <p style={{display:'inline-block', fontSize:'10px'}}>End</p>
            <input
            	onChange={({ target }) => setEndDate(target.value)}
              style={{ width: '65%', display:'inline-block', border:'none', marginLeft:'10px' }}
              type="date"
            />
          </div>
      
      </div>
        </Box>
      </Center>
    </div>
  )
}

export default TopClaims
