import React, { useEffect, useState, useContext } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Lorem,
  Center,
  Box
} from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import './Modal.css'
import api from '../core/api'
import { AppContext } from '../core/app-context'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

function UpdatePayment({factura, theP, clickedFactura,theDate, invNum}) {
  const [appState, dispatch] = useContext(AppContext)

  const [issue_date, set_issue_date] = useState('')
  const [invoice_no, set_invoice_no] = useState('')
  const [payment_meth, set_payment_meth] = useState('')
  const [account, set_account] = useState('')
  const [receipt_no, set_receipt_no] = useState('')
  const [status, set_status] = useState('')
  const [comprovativo, set_comprovativo] = useState('')
  const [price, set_price] = useState('')



  const personN = appState.user.first_names + ' ' + appState.user.last_name
  const [providerId, setProviderId] = useState()
  const [paidInvoiceValue, setPaidInvoiceValue] = useState()

 
  function handleChange(event) {
    set_payment_meth(event.target.value)
  }

  function handleLiquid(event) {
    set_status(event.target.value)
  }


 
  const SubmitUpdatedInvoice = () => {
   //alert(clickedFactura)
   
    api
      .post('/api.php', {
        action: 'update_paid_invoices',
        body: {
          payment_meth: payment_meth,
          account: account,
          receipt_no: receipt_no,
          comprovativo: comprovativo,
          status: status,
          changed_by: personN,
          price: theP,
          invoice_id: clickedFactura
          
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  

  const { isOpen, onOpen, onClose } = useDisclosure()

 

  return (
    <>
      <button className="left_deet_submit" style={{ width: '160px' }} onClick={onOpen}>
        Faça pagamento
      </button>

      <br />


      <Modal className="ModalBody" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.5)', position: 'fixed' }} />
        <ModalContent
          style={{
            width: '33vw',
            height: '52vh',
            backgroundColor: '#fff',
            position: 'absolute',
            marginLeft: ' 35%',
            marginRight: 'auto',
            marginTop: '5%',
            borderRadius: '6px'
          }}
        >
          <ModalHeader style={{ marginLeft: '40px' }}>
            <p className="submitTrans_header">Faz Pagamento</p>
          </ModalHeader>

          <ModalBody className="ModalBody">
            <Box style={{ width: '193px', display: 'inline-block' }}>
              <label className="label_claims">Data De emissao</label>

              <input
                className="input_block"
                
                style={{ width: '100%' }}
                type='text'
                value={theDate}
              />
            </Box>

            <Box style={{ width: '193px', display: 'inline-block', marginLeft: '2%' }}>
              <label className="label_claims">
                N da Lote<span style={{ color: '#ED3737' }}>*</span>
              </label>

              <input
                className="input_block"
                name="invoice_no"
                style={{ width: '100%' }}
                type='text'
                value={"000"+invNum}
              />
              

              {/* <input
                className="input_block"
                name="invoice_no"
                onChange={({ target }) => set_invoice_no(target.value)}
                onBlur={createTran}
                style={{ width: '100%' }}
                type="text"
              /> */}
            </Box>

            <Box style={{ width: '193px', display: 'inline-block', marginTop: '16px' }}>
              <label className="label_claims">
                Modalidade de Pagamento<span style={{ color: '#ED3737' }}>*</span>
              </label>

              <select
                className="input_block"
                name="payment_meth"
                onChange={handleChange}
                style={{ width: '100%', color: '#000' }}
              >
                <option value=""> </option>
                <option value="Cheque Deposito"> Cheque Deposito</option>
                <option value="Cheque Provedor">Cheque Provedor</option>
                <option value="Deposito Numerario">Deposito Numerario</option>
              </select>
            </Box>

            <Box
              style={{
                width: '193px',
                display: 'inline-block',
                marginLeft: '2%',
                marginTop: '16px'
              }}
            >
              <label className="label_claims">
                Account <span style={{ color: '#ED3737' }}>*</span>
              </label>
              <input
                name="account"
                className="input_block"
                onChange={({ target }) => set_account(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '193px', display: 'inline-block', marginTop: '16px' }}>
              <label className="label_claims">Recibo</label>

              <input
                className="input_block"
                name="invoice_no"
                onChange={({ target }) => set_receipt_no(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box
              style={{
                width: '193px',
                display: 'inline-block',
                marginLeft: '2%',
                marginTop: '16px'
              }}
            >
              <label className="label_claims">
                Comprovativo<span style={{ color: '#ED3737' }}>*</span>
              </label>

              <input
                className="input_block"
                name="invoice_no"
                onChange={({ target }) => set_comprovativo(target.value)}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>

            <Box style={{ width: '193px', display: 'inline-block', marginTop: '16px' }}>
              <label className="label_claims">Liquidação</label>

              <select
                className="input_block"
                name="status"
                onChange={handleLiquid}
                style={{ width: '100%', color: '#000' }}
              >
                <option value=""> </option>
                <option value="Nao Pago"> Não Pago</option>
                <option value="Processado">Processado </option>
                <option value="Em Pagamento">Em Pagamento</option>
                <option value="Pago">Pago </option>
              </select>
            </Box>

            <Box style={{ width: '193px', display: 'inline-block', marginLeft: '2%' }}>
              <label className="label_claims">
                Valor Total <span style={{ color: '#ED3737' }}>*</span>
              </label>
              <input
                className="input_block"
                //onBlur={completeTable}
               value={theP+ " MT"}
                style={{ width: '100%' }}
                type="text"
              />
            </Box>
          </ModalBody>

          <ModalFooter style={{ marginTop: '30px' }}>
            <p className="trans_selected" style={{ float: 'left', marginRight: '120px' }}>
             
            </p>
            <button className="submitTrans_cancel" onClick={onClose}>
              Cancel
            </button>

            <button
              className="submitTrans_confirm"
              onClick={() => {
                SubmitUpdatedInvoice()
                onClose()
              }}
            >
              Confirme
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdatePayment
