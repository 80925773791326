import React, { useEffect, useState, useContext } from 'react'
import './ProviderTransactionPage.css'
import Header from '../Top/Header'
import SubHeader from './SubHeader'
import { useHistory, Redirect } from 'react-router-dom'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Center, Box } from '@chakra-ui/react'
import PTP_Transactions from '../PTP/PTP_Transactions'
import PTP_Invoices from '../PTP/PTP_Invoices'
import PTP_LeftDeet from '../PTP/PTP_LeftDeet'
import PTP_LeftDeet_Factura from '../PTP/PTP_LeftDeet_Factura'
import api from '../core/api'
import { AppContext } from '../core/app-context'

function ProviderTransactionPage(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [theDate, setTheDate] = useState('')
  const [clickedFactura, setClickedFactura] = useState('')
  const [issue_date, set_issue_date] = useState('')
  const [invoice_no, set_invoice_no] = useState('')
  const [payment_meth, set_payment_meth] = useState('')
  const [account, set_account] = useState('')
  const [receipt_no, set_receipt_no] = useState('')
  const [status, set_status] = useState('')
  const [comprovativo, set_comprovativo] = useState('')
  const [price, set_price] = useState('')

  const personN = appState.user.first_names + ' ' + appState.user.last_name

  const [TranInfo, setTranInfo] = useState({})

  const [postTrans, setPostTrans] = useState([])

  const [transNo, setTransNo] = useState(0)
  const [paidInvoiceId, setPaidInvoiceId] = useState(0)

  const [providerId, setProviderId] = useState(null)
  const [address, setAddress] = useState('')
  const [trans, setTrans] = useState('')
  const [total_paid, setTotalPaid] = useState(0)
  const [name, setName] = useState('')
  const [invNum, setInvNum] = useState('')
  const [theP, setTheP] = useState('')
  const [theID, setTheID] = useState('')
  

  useEffect(async () => {
    if (props.location.state) {
      setProviderId(props.location.state.provider_id)
      setAddress(props.location.state.address)
      setTrans(props.location.state.trans)
      setTotalPaid(props.location.state.total_paid)
      setName(props.location.state.name)
    }
  }, [props.location.state])

  const SubmitValues = () => {
    const post_params = JSON.stringify(postTrans)

    api
      .post('/api.php', {
        action: 'update_trans_status',
        body: {
          payment_meth: payment_meth,
          account: account,
          receipt_no: receipt_no,
          comprovativo: comprovativo,
          status: status,
          changed_by: personN,
          price: price,
          // paid_invoice_id: TranInfo[0].id,
          paid_invoice_id: paidInvoiceId,
          // provider_id: props.location.state.provider_id,
          provider_id: providerId,
          post_params
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const [refreshKey, setRefreshKey] = useState(1)

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  return (
    <div className="main-page">
      <div className="header_member">
        <Header />
      </div>
      <Box className="SH" style={{ height: '6%', marginTop: '-8px' }}>
        <SubHeader provider_id={providerId} />
      </Box>

      <div style={{ width: '100%', height: '87.5%', marginTop: '0.5%' }}>
        <Tabs variant="enclosed">
          <div
            style={{ marginTop: '10px', width: '30%', marginLeft: '32px', position: 'absolute' }}
          >
            <TabList>
              <Tab
                _selected={{
                  color: '#FF5A5D',
                  bg: '#FFF1F3',
                  border: '1px solid #FF5A5D',
                  opacity: '1'
                }}
                className="tab_btn"
              >
                Transações
              </Tab>

              <Tab
                _selected={{
                  color: '#FF5A5D',
                  bg: '#FFF1F3',
                  border: '1px solid #FF5A5D',
                  opacity: '1'
                }}
                className="tab_btn"
                value="history"
                onClick={(e) => {
                  //selected_tab(e);
                  refresh();
                }}
              >
                Facturas
              </Tab>
            </TabList>

            <TabPanels>
              {/*<TabPanel>
								
							</TabPanel>*/}

              <TabPanel>
                <PTP_LeftDeet
                  address={address}
                  trans={trans}
                  total_paid={total_paid}
                  name={name}
                  set_invoice_no={set_invoice_no}
                  set_payment_meth={set_payment_meth}
                  set_account={set_account}
                  set_receipt_no={set_receipt_no}
                  set_status={set_status}
                  set_comprovativo={set_comprovativo}
                  set_price={set_price}
                  setTranInfo={setTranInfo}
                  setPaidInvoiceId={setPaidInvoiceId}
                  SubmitValues={SubmitValues}
                  transNo={transNo}
                  refresh={refresh}
                  providerId={providerId}
                  invNum={invNum}
                />
              </TabPanel>

              <TabPanel>
                <PTP_LeftDeet_Factura
                  address={address}
                  trans={trans}
                  total_paid={total_paid}
                  name={name}
                  clickedFactura={clickedFactura}
                  theDate={theDate}
                  theP={theP}
                  invNum={invNum}
                />
              </TabPanel>
            </TabPanels>
          </div>

          <div style={{ width: '33%', height: '27%', display: 'inline-block' }} />

          <Box
            style={{
              width: '65%',
              height: '95%',
              marginLeft: '10px',
              marginTop: '1%',
              border: '1px solid #fff',
              display: 'inline-block',
              overflowX: 'hidden',
              overflowY: 'scroll'
            }}
          >
            <Box
              style={{
                width: '100%',
                height: '80vh',
                border: '1px solid #fff',
                backgroundColor: '#fff'
              }}
            >
              <TabPanels style={{ backgroundColor: '#fff', width: '100%', height: '80vh' }}>
                <TabPanel>
                  <PTP_Transactions
                    provider_id={props.location.state.provider_id}
                    setPostTrans={setPostTrans}
                    setTransNo={setTransNo}
                    transNo={transNo}
                  />
                </TabPanel>

                <TabPanel>
                  <PTP_Invoices
                    provider_id={providerId}
                    setPostTrans={setPostTrans}
                    refresh={refreshKey}
                    setClickedFactura={setClickedFactura}
                    setTheP={setTheP}
                    setTheID={setTheID}
                    setTheDate={setTheDate}
                    setInvNum={setInvNum}
                  />
                </TabPanel>
              </TabPanels>
            </Box>
          </Box>
        </Tabs>
      </div>
    </div>
  )
}

export default ProviderTransactionPage
