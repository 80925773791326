import React, { useState } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'

function MUP_userDet({ editMemberData, handleEditMemberChange, UpdateMember }) {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <Box style={{ width: '25%', display: 'inline-block' }}>
          <label className="label">Primeiro nome</label>
          <input
            name="first_names"
            className="input_edit_member"
            value={editMemberData.first_names}
            onChange={handleEditMemberChange}
          />
        </Box>
        <Box style={{ width: '15%', display: 'inline-block', marginLeft: '-5%' }}>
          <label className="label">Apelido</label>
          <input
            name="last_name"
            className="input_edit_member"
            style={{ width: '120px' }}
            value={editMemberData.last_name}
            onChange={handleEditMemberChange}
          />
        </Box>

        <Box style={{ width: '22%', display: 'inline-block', marginLeft: '10px' }}>
          <label className="label">B.I No</label>
          <input
            name="id_number"
            value={editMemberData.id_number}
            onChange={handleEditMemberChange}
            className="input_edit_member"
          />
        </Box>

        <Box style={{ width: '20%', display: 'inline-block' }}>
          <label className="label">Numero da apolice</label>
          <input
            name="policy_no"
            value={editMemberData.policy_no}
            onChange={handleEditMemberChange}
            className="input_edit_member"
          />
        </Box>

        <Box style={{ width: '20%', display: 'inline-block' }}>
          <label className="label">Data de inicio</label>
          <input
            name="start_date"
            value={editMemberData.start_date}
            onChange={handleEditMemberChange}
            className="input_edit_member"
          />
        </Box>

        <div style={{ marginTop: '20px' }}>
          <Box style={{ width: '25%', display: 'inline-block' }}>
            <label className="label">Email</label>
            <input
              name="email"
              value={editMemberData.email}
              onChange={handleEditMemberChange}
              style={{ width: '280px' }}
              className="input_edit_member"
            />
          </Box>

          <Box style={{ width: '22%', display: 'inline-block' }}>
            <label className="label">Contacto</label>
            <input
              name="phone"
              value={editMemberData.phone}
              onChange={handleEditMemberChange}
              className="input_edit_member"
            />
          </Box>

          <Box style={{ width: '20%', display: 'inline-block' }}>
            <label className="label">Numero do Membro</label>
            <input
              name="member_no"
              value={editMemberData.member_no}
              onChange={handleEditMemberChange}
              className="input_edit_member"
            />
          </Box>

          <Box style={{ width: '20%', display: 'inline-block' }}>
            <label className="label">Data Fim</label>
            <input
              name="end_date"
              value={editMemberData.end_date}
              onChange={handleEditMemberChange}
              className="input_edit_member"
            />
          </Box>
          <Box style={{ width: '10%', display: 'inline-block' }}>
            <label className="label">Status</label>
            <input
              name="status"
              value={editMemberData.status}
              onChange={handleEditMemberChange}
              className="input_edit_member"
            />
            
          </Box>


          <Box style={{ marginTop: '24px' }}>
            <button className="save_item_btn" onClick={UpdateMember}>
              Salve
            </button>
          </Box>
        </div>
      </Box>
    </div>
  )
}

export default MUP_userDet
