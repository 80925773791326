import React, { useState, useEffect } from 'react'
import './PDP.css'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  Box
} from '@chakra-ui/react'
import api from '../core/api'
import toast, { Toaster } from 'react-hot-toast'
import NumberFormat from 'react-number-format'
import { formatCurrency } from '../utility/FormatCurrency'

function PDP_Account(props) {
  return (
    <div>
      <Box style={{ width: '100%', height: '7%', backgroundColor: '#fff', marginTop: '2px' }}>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton className="accord_btn">
                <Box flex="1" textAlign="left">
                  {props.benefit_name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <table className="account_table">
                {props.items.map((item) => {
                  return (
                    <tr className="account_table_inside">
                      {item.amount > 0 ? (
                        <td style={{ width: '50%' }}>{item.service_name}</td>
                      ) : (
                        <div />
                      )}

                      {item.amount > 0 ? (
                        <td style={{ width: '20%', marginLeft: '2%' }}>
                          {' '}
                          {formatCurrency(item.amount)}{' '}
                          {isNaN(item.amount) ? '' : item.amount < 1000 ? 'Dias por ano' : 'MT'}
                        </td>
                      ) : (
                        <div />
                      )}

                      {/* {item.amount > 0 ? (
                        <td style={{ width: '20%', marginLeft: '2%' }}>
                          {isNaN(item.amount - 0)
                            ? props.plan_amount - props.TotalTransValue
                            : item.amount - 0}{' '}
                          {item.amount < 1000 ? 'Dias' : 'MT'}
                          <span className="span_thing">Balance</span>
                        </td>
                      ) : (
                        <div />
                      )} */}
                    </tr>
                  )
                })}
              </table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </div>
  )
}

export default PDP_Account
