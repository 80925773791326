import React, { useContext, useEffect, useState } from 'react'
import './PlansPage.css'

import { Center, Box } from '@chakra-ui/react'
import TopPlans from '../Top/TopPlans'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useHistory, Redirect } from 'react-router-dom'
import { AppContext } from '../core/app-context'
import api from '../core/api'

import Pagination from './Pagination'
import { formatCurrency } from '../utility/FormatCurrency'

function PlansPage() {
  const [appState, dispatch] = useContext(AppContext)

  const [plans, setplans] = useState([])
  const [filteredPlans, setFilteredPlans] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [plansPerPage, setPlansPerPage] = useState(7)
  const [refreshKey, setRefreshKey] = useState(0)

  //Get Current Posts
  const indexOfLastPlan = currentPage * plansPerPage
  const indexOfFirstPlan = indexOfLastPlan - plansPerPage
  const currenPlans = plans.slice(indexOfFirstPlan, indexOfLastPlan)

  //Change page

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const handleNextPage = () => {
    if (currentPage === plans.length / plansPerPage) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleLastPage = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  let history = useHistory()

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_plans',
        body: {}
      })
      .then((result) => {
        console.log('The Plans hombre', result.data.data)
        setplans(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [refreshKey])

  useEffect(() => {
    console.log(appState)
  }, [appState])

  useEffect(() => {
    // console.log("members", members);
    setFilteredPlans(plans)
  }, [plans])

  const filterPlans = (filterForm) => {
    let newPlans = plans
    if (filterForm.name.toString().length > 0) {
      newPlans = plans.filter(
        (m) =>
          filterForm.name.toString().length > 0 &&
          m.name.toString().includes(filterForm.name.toString())
      )
    }

    // console.log("newMembers", newMembers);
    setFilteredPlans(newPlans)
  }

  return (
    <div>
      <Box style={{ height: '200px' }}>
        <TopPlans refresh={refresh} onSearch={filterPlans} />
      </Box>

      <Box style={{ width: '100%' }}>
        <Center>
          <table className="member_table">
            <thead>
              <th>Plano</th>
              <th>Limite anual</th>
              <th>Região de corbertura</th>
              <th>Rede De Provedores</th>
              <th>Data Criada </th>
            </thead>

            {/* {currentMembers.map((e) => { */}
            {filteredPlans.slice(indexOfFirstPlan, indexOfLastPlan).map((e) => {
              return (
                <tr
                  key={e.id}
                  className="main_table_inside"
                  onClick={() =>
                    history.push({
                      pathname: '/planDetail',
                      // search: '?query=abc',
                      state: {
                        plan_id: e.id
                      }
                    })
                  }
                >
                  <td style={{ width: '29.79%' }}>
                    {e.name}
                    <span className="span_member">{e.member_no}</span>
                  </td>
                  <td style={{ width: '14.23%' }}>{formatCurrency(e.total_amount)} MT</td>
                  <td style={{ width: '14.23%' }}>{e.regiao}</td>
                  <td style={{ width: '14.23%' }}>{e.rede}</td>
                  <td style={{ width: '14.23%' }}>{e.date}</td>
                </tr>
              )
            })}
          </table>
        </Center>
      </Box>
      <br/>
      <Pagination
        handleNextPage={handleNextPage}
        handleLastPage={handleLastPage}
        plansPerPage={plansPerPage}
        totalPlans={plans.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  )
}

export default PlansPage
