import React, { useEffect, useState } from 'react'
import './TopOfPage.css'
import Modalz from '../Modal/Modalz'
import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import Header from './Header'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddIcon from '@material-ui/icons/Add'
import { useHistory, Redirect } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'


function TopMembers({ refresh, onSearch, setCurrentPage }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  let history = useHistory()

  const [searchForm, setSearchForm] = useState({
    member: '',
    policyNo: '',
    plan: ''
  })

  const [resetForm, setResetForm] = useState({
    member: '',
    policyNo: '',
    plan: ''
  })
  const toMembers = () => {
    history.push('/addmember')
  }

  useEffect(() => {
    console.log('searchForm', searchForm)
  }, [searchForm])


const handleKeypress = e => {
    if (e.keyCode === 13) {
      onSearch(searchForm)
          setCurrentPage(1)
    }
  };

  return (
    <div className="main-page">
      <div className="header">
        <Header active="members" />
      </div>

      <Center className="subheader" style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}}>
        <Box className="top_pagebox">
          <h1 className="subheader_heading" style={{ display: 'inline-flex' }}>
            Lista De Membros
          </h1>

          <Button
            onClick={toMembers}
            leftIcon={<AddIcon style={{ height: '28px' }} />}
            className="new_member_btn"
            style={{ float: 'right', width: '10.5%' }}
          >
            Novo Membro
          </Button>

          <div
            style={{ width: '100%', height: '1px', backgroundColor: '#EFEFEF', marginTop: '18px' }}
          />

          <div style={{ marginTop: '1.3%', display: 'inline-block', width: '21.5%' }}>
            <label className="labels">Membro</label>
            <input
              className="inputs_sidebar"
              style={{ width: '100%' }}
              type="text"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  member: e.currentTarget.value
                })
              }}
             
             onKeyDown={handleKeypress}
            />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%'
            }}
          >
            <label className="labels">Numéro de apólice</label>
            <input
              className="inputs_sidebar"
              style={{ width: '100%' }}
              type="text"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  policyNo: e.currentTarget.value
                })
              }}
               onKeyDown={handleKeypress}
            />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '13.26%',
              marginLeft: '1.7%'
            }}
          >
            <label className="labels">Plano</label>
            <input
              className="inputs_sidebar"
              style={{ width: '100%' }}
              type="text"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  plan: e.currentTarget.value
                })
              }}
              onKeyDown={handleKeypress}
            />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '1.7%' }}>
            <IconButton
              className="search_btn"
              style={{ width: '50px', height: '28px' }}
              icon={<SearchIcon style={{ height: '18px' }} />}
              onClick={() => {
                onSearch(searchForm)
                setCurrentPage(1)
              }}
            />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '10%' }}>
            <Button
              style={{ width: '48px', height: '28px', fontSize: '13px' }}
              className="trash_btn"
              v
              onClick={() => {
                onSearch(resetForm)
              }}
            >
              {' '}
              Reset
            </Button>
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '15.26%',
              marginLeft: '1.7%'
            }}
          >
            <select className="inputs_sidebar" style={{ width: '100%' }}></select>
          </div>
        </Box>
      </Center>
    </div>
  )
}

export default TopMembers
