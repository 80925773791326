import React, { useState, useEffect } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'
import CloseIcon from '@material-ui/icons/Close'
import api from '../core/api'
import { formatCurrency } from '../utility/FormatCurrency'

function MUP_TransactionRefund({
  key,
  id,
  name,
  Planid,
  memberDetails,
  trans,
  addT,
  Providerid,
  memb_id,
  TotalTransValue,
  networkProvider,
  PercentageAmount,
  percentagemAmount
}) {
  //Values that are set according to each transaction
  const [Procedurename, setProcedurename] = useState('')
  const [serviceid, setServiceid] = useState('')
  const [Pricey, setPrice] = useState('')
  const [AltPrice, setAltPrice] = useState(0)

  const [benefitLine, setBenefitLine] = useState([])
  const [disable, setDisable] = useState(false)

  const [TransValues, setTransValues] = useState([])

  const [uniqueBenefitType, setUniqueBenefitType] = useState([])
  const [uniqueBenefit, setUniqueBenefit] = useState([])

  const [refundAmount, setRefundAmount] = useState(0)

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_benefit_line',
        body: {
          planid: Planid
        }
      })
      .then((result) => {
        console.log('Benefit Lines', result.data.data)
        setBenefitLine(result.data.data)

        const uniqueArray = []

        result.data.data.forEach((item) => {
          const index = uniqueArray.findIndex((el) => el.id == item.service_type.id)
          if (index < 0) {
            uniqueArray.push(item.service_type)
          }
        })

        setUniqueBenefitType(uniqueArray)
      })
      .catch((err) => console.warn(err))
  }, [])

  const [benefitAmount, setBenefitAmount] = useState('')
  const [Pamount, setPamount] = useState(0)
  const [refreshKey, setRefreshKey] = useState(1)

  const [benefitClass, setBenefitClass] = useState('')

  const benefitClassName = benefitClass.split('_')[2]

  const [classId, setClassId] = useState([])

  function handleChange(event) {
    setServiceid(event.target.value)
    setBenefitAmount(event.target.value)

    setBenefitClass(event.target.value)
  }

  const handleChangeSelectBenefitType = (e) => {
    if (!e) {
      setUniqueBenefit([])
      return
    }

    const benefit = []
    console.log(e)
    benefitLine.forEach((el) => {
      if (el.service_type_id == e) benefit.push(el)
    })

    setUniqueBenefit(benefit)
  }

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  const dis = () => {
    setDisable(true)
  }

  const service_id = serviceid.split('_')[0]

  function SubmitButton() {
    let Price =Pricey * (percentagemAmount / 100)
    if (
      (Price && Procedurename && bene_amount - TransactionTotal - Pamount >= 0) ||
      Price === AltPrice
    ) {
      return (
        <button
          style={{ float: 'right' }}
          className="save_item_btn"
          disabled={disable}
          onClick={() => {
            addT({ Procedurename, service_id, Price, AltPrice })
            dis()
          }}
        >
          Salvar item
        </button>
      )
    } else {
      return (
        <button style={{ float: 'right' }} className="save_item_btn" disabled>
          Salvar item
        </button>
      )
    }
  }

  const servico = serviceid.split('_')[0]

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_member_history',
        body: {
          service_id: servico,
          member_id: memb_id
        }
      })
      .then((result) => {
        console.log('HISTORIAS JOVEM', result.data.data)
        setTransValues(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [refreshKey])

  function setPs(event) {
    setPamount(event.target.value)
    setPrice(event.target.value)
  }

  useEffect(() => {
    // if (!isNaN(Pamount)) {
    //   if (networkProvider == 'true') {
    //     setRefundAmount(Pamount)
    //   } else {
    //     setRefundAmount(Pamount * 0.7)
    //   }
    // }
    if (!isNaN(percentagemAmount)) {
      if (networkProvider == 'true') {
        setRefundAmount(Pamount)
      } else {
        setRefundAmount(Pamount * (percentagemAmount / 100))
      }
    }
    console.log(networkProvider, percentagemAmount)
  }, [percentagemAmount, networkProvider, Pamount])

  const TransactionTotal =
    TransValues !== null
      ? TransValues.reduce((prev, { price }) => {
          return prev + price
        }, 0)
      : 'hi'

  let bene_amount = benefitAmount.split('_')[1]

  if (isNaN(bene_amount)) {
    bene_amount = memberDetails.plan.total_amount - TotalTransValue
  } else {
    bene_amount = benefitAmount.split('_')[1]
  }

  let nf = new Intl.NumberFormat('en-US')

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings" style={{ paddingTop: '10px' }}>
          Transação
        </p>

        <SubmitButton />

        <Box style={{ width: '35%', display: 'inline-block' }}>
          <label className="label_claims">Tipo de Beneficio</label>

          <select
            className="input_claim"
            style={{ width: '100%' }}
            onChange={(e) => handleChangeSelectBenefitType(e.target.value)}
          >
            <option> </option>
            {uniqueBenefitType.map((type) => (
              <option value={type.id}>{type.name}</option>
            ))}
          </select>
        </Box>

        <Box style={{ width: '35%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">
            Benefício <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <select
            className="input_claim"
            onClick={refresh}
            onChange={handleChange}
            disabled={disable}
            style={{ width: '100%', color: '#000' }}
          >
            <option> </option>
            {uniqueBenefit.map((benefit) => (
              <option value={benefit.id + '_' + benefit.amount + '_' + benefit.service_type.name}>
                {benefit.service_name}
              </option>
            ))}
          </select>
        </Box>

        <Box style={{ width: '35%', display: 'inline-block', marginTop: '2%' }}>
          <label className="label_claims">
            Procedimento <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            className="input_claim"
            disabled={disable}
            onChange={({ target }) => setProcedurename(target.value)}
            style={{ width: '90%', display: 'inline-block' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '15%', display: 'inline-block', marginLeft: '13%', marginTop: '2%' }}>
          <label className="label_claims">
            Preço(MT) <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input className="input_claim" onChange={setPs} style={{ width: '100%' }} type="text" />
        </Box>

        <Box style={{ width: '15%', display: 'inline-block', marginLeft: '2%', marginTop: '2%' }}>
          <label className="label_claims">Preço Alternativo(MT)</label>
          <input
            className="input_claim"
            onChange={({ target }) => setAltPrice(target.value)}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '32%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">Balance</label>
            <input
              className="input_claim"
              value={nf.format(bene_amount - TransactionTotal - Pamount)}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box
            style={{ width: '25%', display: 'inline-block', marginTop: '2%', marginLeft: '16%' }}
          >
            <label className="label_claims">Valor do Reembolso</label>
            <input
              className="input_claim"
              value={formatCurrency(refundAmount)}
              style={{ width: '100%' }}
              type="text"
              disabled
            />
          </Box>
        </div>
      </Box>

      <br />
    </div>
  )
}

export default MUP_TransactionRefund
