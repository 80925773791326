import { Box, Center } from '@chakra-ui/react'
import './PrintPNew.css'
import { AppContext } from '../core/app-context'
import { useContext, useState, React, useEffect, Component, PropTypes } from 'react'
import BetterCareLogo from './bettercareOG.png'
import api from '../core/api'

function PrintTrans({ member_id, memberDetails }) {
  const print = () => {
    window.print()
  }

  const [AllFetchedTrans, setAllTrans] = useState([])

 

   useEffect(() => {
     document.title=memberDetails.member_no
  }, [memberDetails])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_transaction_for_print',
        body: {
          member_id: member_id
        }
      })
      .then((result) => {
        console.log('Transactions for Print', result.data.data)
        setAllTrans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])


  const TotalTransValue =
    AllFetchedTrans !== null
      ? AllFetchedTrans.reduce((prev, { price }) => {
          return prev + price
        }, 0)
      : 'a'

  const ClaimTotal =
    AllFetchedTrans !== null
      ? AllFetchedTrans.reduce((prev, { price }) => {
          return price >= 100 ? prev + price : prev + price - (price <= 50 ? price : prev)
        }, 0)
      : AllFetchedTrans.reduce((prev, { price }) => {
          return prev + price
        }, 0)

  const ClaimAltTotal =
    AllFetchedTrans !== null
      ? AllFetchedTrans.reduce((prev, { alt_price }) => {
          return prev + alt_price
        }, 0)
      : AllFetchedTrans.reduce((prev, { price }) => {
          return prev + price
        }, 0)





            let thePrice =0
            let REFUND=0
            let PAY_PROVIDER = 0
            let D_D =0
            let PACIENTE_iNTERNADO = 0
            let HOSPITALARES = 0
            let CONDICOES_CRONICAS=0
            let OUTROS_BENEFICIOS=0
 AllFetchedTrans.slice(0, 100).map((e) => {  
   
   e.transactions.map((ey)=> {
   
   
  
        thePrice+=ey.price<15?(ey.alt_price):(ey.price)
                  PAY_PROVIDER+= ey.auth_no==='Reembolso'?(0.00):(ey.price<15?(ey.alt_price):(ey.price))
                  REFUND+=ey.auth_no!=='Reembolso'?(0.00):(ey.price<15?(ey.alt_price):(ey.price))

                  PACIENTE_iNTERNADO+= ey.service.service_type_id==='1'||ey.service.service_type_id==='6'||ey.service.service_type_id==='13'||ey.service.service_type_id==='15'?(ey.price<15?(ey.alt_price):(ey.price)):(0);
                 D_D +=ey.service.service_type_id==='2'||ey.service.service_type_id==='5'||ey.service.service_type_id==='12'||ey.service.service_type_id==='14'||ey.service.service_type_id==='11'?(ey.price<15?(ey.alt_price):(ey.price)):(0)
                 HOSPITALARES +=ey.service.service_type_id==='8'||ey.service.service_type_id==='9'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00)
                 CONDICOES_CRONICAS+=ey.service.service_type_id==='3'||ey.service.service_type_id==='7'||ey.service.service_type_id==='16'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00)
                  OUTROS_BENEFICIOS+=ey.service.service_type_id==='4'||ey.service.service_type_id==='17'||ey.service.service_type_id==='10'?(ey.price<15?(ey.alt_price):(ey.price)):(0)


   })})
    var today = new Date(),

    datey = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();

  return (
    <div>
      <button className="button_del" onClick={print}>imprimir resumo </button>
      <div style={{ width: '100%', display:'none' }} className="divname">
        <div className="InnerDiv" class="k-widget k-grid" data-role="grid">
          <Box
            className="logo_p"
            style={{
              width: '22.68%',
              height: '20px',
              display: 'inline-block'
            }}
          >
            <img src={BetterCareLogo} width="135px" height="36px" />
          </Box>

          <Box style={{ float: 'right', display: 'inline-block' }}>
            <p className="summary_claim">Summary Claims Statement</p>
          </Box>

          <div
            style={{
              display: 'block',
              borderBottom: '1px solid #000',
              height: '124px',
              width: '100%'
            }}
          >
            <Box
              style={{
                width: '130px',
                height: '50px',
                verticalAlign: 'Top',
                marginTop: '27px',
                display: 'inline-block'
              }}
            >
              <p className="house_name"> {memberDetails.title} {memberDetails.last_name}</p>

              <div style={{ width: '94px' }}>
                <p className="house_address">
                  {memberDetails.address}
                </p>
              </div>
            </Box>

            <Box
              style={{
                display: 'inline-block',
                height: '76px',
                width: '240px',
                verticalAlign: 'Top',
                position: 'absolute',
                marginTop: '30px',
                right: '0',
                background: '#F4F4F4'
              }}
            >
              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Date
                </p>
                <p className="member_details_p"> {datey}</p>
              </div>

              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Member No
                </p>
                <p className="member_details_p"> {memberDetails.member_no} </p>
              </div>

              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Policy No
                </p>
                <p className="member_details_p"> {memberDetails.policy_no} </p>
              </div>

              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Periodo de Apolice
                </p>
                <p style={{fontSize:'10px'}} className="member_details_p">{memberDetails.start_date} |-| {memberDetails.end_date} </p>
              </div>
            </Box>
          </div>
          <div style={{ display: 'block' }}>
            <Box style={{ display: 'inline-block', width: '240px', marginLeft: '16px' }}>
              <p style={{ lineHeight: '0px' }} className="house_address">
                Name
              </p>
              <p style={{ lineHeight: '10px' }} className="member_details_p">
                {memberDetails.first_names} {memberDetails.last_name}
              </p>
            </Box>

            <Box style={{ display: 'inline-block', width: '100px' }}>
              <p style={{ lineHeight: '0px' }} className="house_address">
                Id No
              </p>
              <p style={{ lineHeight: '10px' }} className="member_details_p">
                {memberDetails.id_number}
              </p>
            </Box>

            <Box style={{ display: 'inline-block', width: '100px' }}>
              <p style={{ lineHeight: '0px' }} className="house_address">
                Email:
              </p>
              <p style={{ lineHeight: '10px' }} className="member_details_p">
            {memberDetails.email}
              </p>
            </Box>
          </div>

          <table className="general_table">
            <thead>
              <th colspan="3">Claim Details</th>
              <th colspan="2">Payment Amount</th>
              <th colspan="5">Benefits Paid From</th>
            </thead>

            <tr>
              <td style={{ width: '7%', border: '1px solid #000' }}>Procedure Date</td>
              <td style={{ width: '15%', border: '1px solid #000' }}>Procedure</td>
              <td style={{ width: '5%', border: '1px solid #000' }}>Claim Amount</td>

              <td style={{ width: '9.14%', border: '1px solid #000' }}>Paid Provider</td>
              <td style={{ width: '9.14%', border: '1px solid #000' }}>Paid Member</td>

              <td style={{ width: '9.32%', border: '1px solid #000' }}>Dia-a-Dia</td>
              <td style={{ width: '9.32%', border: '1px solid #000' }}>Paciente Internados</td>
              <td style={{ width: '9.32%', border: '1px solid #000' }}>Hospitalares</td>
              <td style={{ width: '9.32%', border: '1px solid #000' }}>Condicoes Cronicas</td>
              <td style={{ width: '9.32%', border: '1px solid #000' }}>Outros Beneficios</td>
            </tr>
          </table>

          {AllFetchedTrans.slice(0, 100).map((e) => {
            let tPrice =0
            
            let reembolso=0
            let pay_p = 0
            let day_to_day =0
            let paciente_internado = 0
            let hospitalres = 0
            let condicoes_cronicas=0
            let outro_beneficios=0
            return (
              <table className="value_table">
                <thead>
                  <th colspan="10">{e.provider.name}</th>
                  {/* <th>{ e.transactions.map((pp)=> { return(pp.price)})}</th> */}
                </thead>

                {e.transactions.map((ey)=> { 
                  
                  tPrice+=ey.price<15?(ey.alt_price):(ey.price)
                  reembolso+= ey.auth_no==='Reembolso'?(0):(ey.price<15?(ey.alt_price):(ey.price))
                  pay_p+=ey.auth_no!=='Reembolso'?(0):(ey.price<15?(ey.alt_price):(ey.price))

                  paciente_internado+= ey.service.service_type_id==='1'||ey.service.service_type_id==='6'||ey.service.service_type_id==='13'||ey.service.service_type_id==='15'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00);
                 day_to_day +=ey.service.service_type_id==='2'||ey.service.service_type_id==='5'||ey.service.service_type_id==='12'||ey.service.service_type_id==='14'||ey.service.service_type_id==='11'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00)
                 hospitalres+=ey.service.service_type_id==='8'||ey.service.service_type_id==='9'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00)
                 condicoes_cronicas+=ey.service.service_type_id==='3'||ey.service.service_type_id==='7'||ey.service.service_type_id==='16'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00)
                  outro_beneficios+=ey.service.service_type_id==='4'||ey.service.service_type_id==='17'||ey.service.service_type_id==='10'?(ey.price<15?(ey.alt_price):(ey.price)):(0.00)

                  return(<tr>

                  <td style={{ width: '7%', textAlign:'left', fontSize:'7px' }}>{ey.date}</td>
                    <td style={{ width: '15%', textAlign:'left' }}>{ey.procedure_name}</td>
                  <td style={{ width: '5%' }}>{ey.price<15?(ey.alt_price):(ey.price)}</td>
                  
                  <td style={{ width: '9.32%' }}>{ey.auth_no==='Reembolso'?(0.00):(ey.price<15?(ey.alt_price):(ey.price))}</td>
                  <td style={{ width: '9.14%' }}>{ey.auth_no!=='Reembolso'?(0.00):(ey.price<15?(ey.alt_price):(ey.price))} </td>

                  <td style={{ width: '9.32%' }}>{ey.service.service_type_id==='2'||ey.service.service_type_id==='5'||ey.service.service_type_id==='12'||ey.service.service_type_id==='14'||ey.service.service_type_id==='11'?(ey.price<15?(ey.alt_price):(ey.price)):(0)} </td>
                  <td style={{ width: '9.32%' }}>{ey.service.service_type_id==='1'||ey.service.service_type_id==='6'||ey.service.service_type_id==='13'||ey.service.service_type_id==='15'?(ey.price<15?(ey.alt_price):(ey.price)):(0)}</td>
                  <td style={{ width: '9.32%' }}>{ey.service.service_type_id==='8'||ey.service.service_type_id==='9'?(ey.price<15?(ey.alt_price):(ey.price)):(0)}</td>
                  <td style={{ width: '9.32%' }}>{ey.service.service_type_id==='3'||ey.service.service_type_id==='7'||ey.service.service_type_id==='16'?(ey.price<15?(ey.alt_price):(ey.price)):(0)}</td>
                  <td style={{ width: '9.32%' }}>{ey.service.service_type_id==='4'||ey.service.service_type_id==='17'||ey.service.service_type_id==='10'?(ey.price<15?(ey.alt_price):(ey.price)):(0)} </td>
                </tr>
                )})}

                <tr className="table_total_block">
                  <td style={{ width: '7%' }}>Totals</td>
                  <td style={{ width: '15%' }}></td>
                   <td style={{ width: '5%' }}>{tPrice}</td>

                  <td style={{ width: '9.32%' }}>{reembolso}</td>
                  <td style={{ width: '9.14%' }}>{pay_p} </td>

                  <td style={{ width: '9.32%' }}>{day_to_day}</td>
                  <td style={{ width: '9.32%' }}>{paciente_internado}</td>
                  <td style={{ width: '9.32%' }}>{hospitalres} </td>
                  <td style={{ width: '9.32%' }}>{condicoes_cronicas}</td>
                  <td style={{ width: '9.32%' }}>{outro_beneficios} </td>
                </tr>
              </table>
            )
          })}

             <table className="value_table">
                <thead>
                  <th colspan="10">STATEMENT TOTAL</th>
                  {/* <th>{ e.transactions.map((pp)=> { return(pp.price)})}</th> */}
                </thead>

                <tr className="table_total_block">
                  <td style={{ width: '7%' }}>Totals</td>
                  <td style={{ width: '15%' }}></td>
                   <td style={{ width: '5%' }}>{thePrice}</td>


                  <td style={{ width: '9.32%' }}>{PAY_PROVIDER}</td>
                  <td style={{ width: '9.14%' }}>{REFUND}</td>

                  <td style={{ width: '9.32%' }}>{D_D}</td>
                  <td style={{ width: '9.32%' }}>{PACIENTE_iNTERNADO}</td>
                  <td style={{ width: '9.32%' }}>{HOSPITALARES}</td>
                  <td style={{ width: '9.32%' }}>{CONDICOES_CRONICAS}</td>
                  <td style={{ width: '9.32%' }}>{OUTROS_BENEFICIOS}  </td>
                </tr>

                </table>
        </div>
       
      </div>

     
    </div>
  )
}
export default PrintTrans
