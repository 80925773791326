import React, { useState, useEffect } from 'react'
import './AMP.css'
import { Center, Box, Button } from '@chakra-ui/react'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import AttachmentIcon from '@material-ui/icons/Attachment'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

function AMP_Page2({
  nextStep,
  previousStep,
  Planid,
  setPlanId,
  policyNo,
  setPolicyNo,
  memberNo,
  setMemberNo,
  startdate,
  setStartdate,
  enddate,
  setEnddate,
  attachLink,
  setAttachLink,
  waitperiod,
  setWaitperiod
}) {
  const [plans, SetPlans] = useState([])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_plans',
        body: {}
      })
      .then((result) => {
        console.log('Plans', result.data.data)
        SetPlans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  function handleChange(event) {
    setPlanId(event.target.value)
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="amp_page1_heading" style={{ paddingTop: '10px' }}>
          Dados da Cobertura Pretendida
        </p>

        <Box style={{ width: '46%', display: 'inline-block' }}>
          <label className="amp_label">
            Nome do plano:<span style={{ color: '#ED3737' }}>*</span>
          </label>
          <select
            className="input_claim"
            value={Planid}
            onChange={handleChange}
            style={{ width: '100%', color: '#000' }}
          >
            {plans.map((plan) => (
              <option value={plan.id}>
                {' '}
                {plan.name} ({plan.total_amount} MT)
              </option>
            ))}
          </select>
        </Box>

        <Box style={{ width: '21%', display: 'inline-block', marginLeft: '9%' }}>
          <label className="label_claims">
            Periodo de Carencia:<span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            value={waitperiod}
            onChange={({ target }) => setWaitperiod(target.value)}
            className="input_claim"
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">Prémio Acordado:</label>
            <input className="input_claim" style={{ width: '100%' }} type="text" />
          </Box>

          <Box style={{ width: '21%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">
              Numero da Apolice<span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={policyNo}
              onChange={({ target }) => setPolicyNo(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '21%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">
              Numero do Membro:<span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={memberNo}
              onChange={({ target }) => setMemberNo(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
        </div>

        <div style={{ display: 'block' }}>
          <Box style={{ width: '30%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">Assinado em:</label>
            <input className="input_claim" style={{ width: '100%' }} type="text" />
          </Box>

          <Box style={{ width: '21%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">
              Data de Inicio:<span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={startdate}
              onChange={({ target }) => setStartdate(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="date"
            />
          </Box>

          <Box style={{ width: '21%', display: 'inline-block', marginTop: '2%', marginLeft: '2%' }}>
            <label className="label_claims">
              Data Fim<span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              value={enddate}
              onChange={({ target }) => setEnddate(target.value)}
              className="input_claim"
              style={{ width: '100%' }}
              type="date"
            />
          </Box>
        </div>

        <p
          className="amp_page1_heading"
          style={{ paddingTop: '5%', fontWeight: '500', lineHeight: '4px' }}
        >
          Attachments
        </p>
        <div style={{ width: '86%', borderBottom: '1.5px solid #E2E0E5' }} />
        <div style={{ display: 'block' }}>
          <Box style={{ width: '38%', display: 'inline-block', marginTop: '2%' }}>
            <label className="label_claims">Attachment Link</label>
            <input
              value={attachLink}
              onChange={({ target }) => setAttachLink(target.value)}
              className="input_claim"
              style={{ width: '85%', display: 'inline-block' }}
              type="text"
            />
            <span style={{ display: 'inline-block' }}>
              <AttachmentIcon style={{ height: '20px' }} />{' '}
            </span>
          </Box>
        </div>

        <div style={{ display: 'block', width: '86%', marginTop: '40px', textAlign: 'end' }}>
          <Button
            onClick={previousStep}
            leftIcon={<ArrowBackIosIcon style={{ height: '12px' }} />}
            className="prev_sect_btn"
          >
            Previous Section
          </Button>

          <Button
            onClick={nextStep}
            rightIcon={<ArrowForwardIosIcon style={{ height: '12px' }} />}
            className="next_sect_btn"
            style={{ marginLeft: '24px' }}
          >
            Next Section{' '}
          </Button>
        </div>
      </Box>
      <br />
    </div>
  )
}

export default AMP_Page2
