import React, { useState } from 'react'
import './PlanDetailPage.css'
import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useHistory, Redirect } from 'react-router-dom'

function SubHeader() {
  let history = useHistory()

  return (
    <div className="main-page">
      <Box style={{ width: '100%', height: '7%', backgroundColor: '#fff', marginTop: '2px' }}>
        <Box>
          <Button
            onClick={() => history.push('/plan')}
            className="back_to_m"
            leftIcon={<KeyboardBackspaceIcon style={{ height: '18px' }} />}
          >
            Voltar para planos{' '}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default SubHeader
