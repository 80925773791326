import React, { useState, useEffect, useContext } from 'react'
import './TDP.css'
import { Center, Box, Button } from '@chakra-ui/react'
import { useHistory, Redirect } from 'react-router-dom'
import SubmitTrans from '../Modal/SubmitTrans'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import toast, { Toaster } from 'react-hot-toast'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import DeleteTransaction from '../Modal/DeleteTransaction'
import { formatCurrency } from '../utility/FormatCurrency'

function TDP_LeftDeet(props) {
  const [appState, dispatch] = useContext(AppContext)

  let history = useHistory()

  const Delete = () => {
    api
      .post('/api.php', {
        action: 'delete_transaction',

        body: {
          provider_id: props.provider_id,
          auth_no: props.auth_no
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
          history.push('/members')
        } else {
          alert('Unable to Delete')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const notify = () => toast('O Claim foi Atualizado')

  return (
    <div style={{ overflowY: 'auto', height: '75vh' }}>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p>Detalhes </p>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Nome Completo</label>

          {props.transactionDetails.slice(0, 1).map((tDeet) => (
            <p className="values_left">
              {' '}
              {tDeet.member.first_names} {tDeet.member.last_name}{' '}
            </p>
          ))}
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Plano- Numero da apolice</label>
          {props.transactionDetails.slice(0, 1).map((tDeet) => (
            <p className="values_left">
              {tDeet.plan.name} - {tDeet.member.policy_no}{' '}
            </p>
          ))}
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            numero do membro
          </label>
          {props.transactionDetails.slice(0, 1).map((tDeet) => (
            <p className="values_left">{tDeet.member.member_no}</p>
          ))}
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            End Date
          </label>
          {props.transactionDetails.slice(0, 1).map((tDeet) => (
            <p className="values_left">{tDeet.member.end_date}</p>
          ))}
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Limite Anual</label>
          {props.transactionDetails.slice(0, 1).map((tDeet) => (
            <p className="values_left">{formatCurrency(tDeet.plan.total_amount)} MT</p>
          ))}
        </Box>

        {/*<Box style={{width:'95%'}}>

<label className='left_deet_label'>Saldo</label>
           {props.transactionDetails.slice(0,1).map((tDeet) =>  <p className='values_left'>{tDeet.plan.total_amount-props.TotalTransValue} MT</p>)}
</Box>*/}

        {appState.user.position === 'editor' ? (
          <button
            onClick={() => {
              props.SubmitStatus()
              notify()
            }}
            className="left_deet_submit"
          >
            Submit
          </button>
        ) : (
          <p> </p>
        )}

        {appState.user.position === 'editor' ? (
          <DeleteTransaction
            Delete={Delete}
            auth_no={props.auth_no}
            full_name={
              props.transactionDetails.first_names + ' ' + props.transactionDetails.last_name
            }
          />
        ) : (
          <div />
        )}
      </Box>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 2000,
          style: {
            background: '#000',
            color: '#fff'
          }
          // Default options for specific types
        }}
      />
    </div>
  )
}

export default TDP_LeftDeet
