import React, { useState, useEffect } from 'react'
import './PDP.css'

import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import api from '../core/api'
import { useHistory, Redirect } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { formatCurrency } from '../utility/FormatCurrency'

function PDP_CA(props) {
  let history = useHistory()

  const click = () => {
    alert(JSON.stringify(props.planDetail))
  }

  let nf = new Intl.NumberFormat('en-US')

  const amount = 50000

  return (
    <div>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p></p>

        <div style={{ marginTop: '3%', width: '90%' }}>
          <label className="labels">Name</label>

          <p onClick={click} className="values_left">
            {props.planDetail.name}
          </p>
        </div>

        <div style={{ marginTop: '6%', width: '90%' }}>
          <label className="labels">Limite Annual</label>

          <p className="values_left">
            {/* {nf.format(props.planDetail.total_amount)} */}
            {isNaN(props.planDetail.total_amount)
              ? ''
              : formatCurrency(props.planDetail.total_amount)}
          </p>
        </div>

        <div style={{ marginTop: '6%', width: '90%' }}>
          <label className="labels">Rede de Provedors</label>

          <p className="values_left">{props.planDetail.rede}</p>
        </div>

        <div style={{ marginTop: '6%', width: '90%' }}>
          <label className="labels">Regiao de Cobertura</label>

          <p className="values_left">{props.planDetail.regiao}</p>
        </div>
      </Box>
    </div>
  )
}

export default PDP_CA
