import React, { useState, useEffect } from 'react'
import './PTP.css'
import { Center, Box, Button } from '@chakra-ui/react'

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import MakePayment from '../Modal/MakePayment'

function PTP_LeftDeet({
  address,
  trans,
  total_paid,
  name,
  SubmitStatus,
  set_issue_date,
  set_invoice_no,
  set_payment_meth,
  set_account,
  set_receipt_no,
  set_status,
  set_comprovativo,
  set_price,
  setTranInfo,
  SubmitValues,
  transNo,
  refresh,
  providerId,
  setPaidInvoiceId
}) {
  return (
    <div style={{ overflowY: 'auto', height: '75vh' }}>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p>Detalhes </p>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Provedor</label>
          <p className="values_left_ptp">{name}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Localização</label>
          <p className="values_left_ptp">{address}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            Total de Claims
          </label>
          <p className="values_left_ptp">{trans}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            Total de claims não pagas
          </label>
          <p className="values_left_ptp"> {trans - total_paid}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Total claims Pagas</label>
          <p className="values_left_ptp"> {total_paid}</p>
        </Box>
      </Box>
      <MakePayment
        SubmitStatus={SubmitStatus}
       
        SubmitValues={SubmitValues}
        transNo={transNo}
        refresh={refresh}
        providerId={providerId}
        setPaidInvoiceId={setPaidInvoiceId}
      />
    </div>
  )
}

export default PTP_LeftDeet
