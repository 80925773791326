import React, { useState, useEffect } from 'react'
import './AMP.css'
import { Center, Box, Button } from '@chakra-ui/react'

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import CloseIcon from '@material-ui/icons/Close'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import HistoryIcon from '@material-ui/icons/History'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CheckIcon from '@material-ui/icons/Check'
import { useHistory, Redirect } from 'react-router-dom'

function AMP_Left({ page, submitMember, items }) {
  let history = useHistory()

  const SubmitConditions = () => {
    const post_params = JSON.stringify(items)

    api
      .post('/api.php', {
        action: 'insert_health_condition',

        body: {
          post_params
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
          history.push('/members')
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div style={{ overflowY: 'auto', height: '75vh' }}>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p className="amp_left_heading"> Member Profile </p>

        <p className={page === 1 ? 'amp_left_pages amp_check_active' : 'amp_left_pages amp_check'}>
          <span style={{ marginRight: '16px' }}>
            <AccountBoxIcon style={{ height: '18px', color: '#4284F3' }} />{' '}
          </span>{' '}
          Dados Pessoais do Segurado{' '}
          <span
            style={{
              position: 'absolute',
              right: '0',
              marginRight: '40px',
              border: '1px solid #E2E0E5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              width: '16px',
              height: '16px',
              textAlign: 'center'
            }}
          >
            <CheckCircleOutlineIcon style={{ height: '18px', color: '#79D120' }} />
          </span>
        </p>

        <p className={page === 2 ? 'amp_left_pages amp_check_active' : 'amp_left_pages amp_check'}>
          <span style={{ marginRight: '16px' }}>
            <HomeWorkIcon style={{ height: '18px', color: '#4284F3' }} />
          </span>
          Dados da Cobertura Pretendida
          <span
            style={{
              position: 'absolute',
              right: '0',
              marginRight: '40px',
              border: '1px solid #E2E0E5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              width: '16px',
              height: '16px',
              textAlign: 'center'
            }}
          >
            <CheckCircleOutlineIcon style={{ height: '18px', color: '#79D120' }} />
          </span>
        </p>

        <p className={page === 3 ? 'amp_left_pages amp_check_active' : 'amp_left_pages amp_check'}>
          <span style={{ marginRight: '16px' }}>
            <HistoryIcon style={{ height: '18px', color: '#4284F3' }} />
          </span>{' '}
          Historial Medico e Questoes de Saude
          <span
            style={{
              position: 'absolute',
              right: '0',
              marginRight: '40px',
              border: '1px solid #E2E0E5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              width: '16px',
              height: '16px',
              textAlign: 'center'
            }}
          >
            <CheckCircleOutlineIcon style={{ height: '18px', color: '#79D120' }} />
          </span>
        </p>

        <p className={page === 4 ? 'amp_left_pages amp_check_active' : 'amp_left_pages amp_check'}>
          <span style={{ marginRight: '16px' }}>
            <HistoryIcon style={{ height: '18px', color: '#4284F3' }} />
          </span>
          Additional Information
          <span
            style={{
              position: 'absolute',
              right: '0',
              marginRight: '40px',
              border: '1px solid #E2E0E5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              width: '16px',
              height: '16px',
              textAlign: 'center'
            }}
          >
            <CheckCircleOutlineIcon style={{ height: '18px', color: '#79D120' }} />
          </span>
        </p>

        <Button
          onClick={() => {
            submitMember()
            SubmitConditions()
          }}
          className="amp_left_button"
        >
          Save Changes{' '}
        </Button>
      </Box>
    </div>
  )
}

export default AMP_Left
