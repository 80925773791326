import React, { useState, useEffect, useContext } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'
import api from '../core/api'
import { useHistory, Redirect } from 'react-router-dom'
import { AppContext } from '../core/app-context'
import DeleteMember from '../Modal/DeleteMember'

function MUP_CA({
  setHistoryserviceid,
  setHistoryservicename,
  submit_history,
  Planid,
  member_id,
  memberDetails
}) {
  const [benefitLine, setBenefitLine] = useState([])
  const [uniqueBenefitType, setUniqueBenefitType] = useState([])
  const [uniqueBenefit, setUniqueBenefit] = useState([])

  const [appState, dispatch] = useContext(AppContext)

  let history = useHistory()

  const Delete = () => {
    api
      .post('/api.php', {
        action: 'delete_member',

        body: {
          member_id: member_id
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
          history.push('/members')
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const clickar = () => {
    alert(JSON.stringify(Planid))
  }

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_benefit_line',
        body: {
          planid: Planid
        }
      })
      .then((result) => {
        console.log('Benefit Lines', result.data.data)
        setBenefitLine(result.data.data)

        const uniqueArray = []

        result.data.data.forEach((item) => {
          const index = uniqueArray.findIndex((el) => el.id == item.service_type.id)
          if (index < 0) {
            uniqueArray.push(item.service_type)
          }
        })

        setUniqueBenefitType(uniqueArray)
      })
      .catch((err) => console.warn(err))
  }, [Planid])

  useEffect(() => {
    benefitLine
      .slice(0, 1)
      .map((benefit) => setHistoryserviceid(benefit.id + '_' + benefit.service_name))
  }, [benefitLine])

  function handleChange(event) {
    setHistoryserviceid(event.target.value)
    setHistoryservicename(event.target.value)
  }

  const handleChangeSelectBenefitType = (e) => {
    if (!e) {
      setUniqueBenefit([])
      return
    }

    const benefit = []
    benefitLine.forEach((el) => {
      if (el.service_type_id == e) benefit.push(el)
    })

    setUniqueBenefit(benefit)
  }

  return (
    <div>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p>Benefícios </p>

        <div style={{ marginTop: '3%', width: '90%' }}>
          <label className="labels">Tipo de Benefícios</label>

          <select
            className="input_thingy"
            onChange={(e) => handleChangeSelectBenefitType(e.target.value)}
            style={{ width: '100%', color: '#000' }}
          >
            <option> </option>
            {uniqueBenefitType.map((type) => (
              <option value={type.id}>{type.name}</option>
            ))}
          </select>

          {/* <input type="text" className="input_thingy" style={{ width: '100%' }} /> */}
        </div>

        <div style={{ marginTop: '6%', width: '90%' }}>
          <label className="labels">Benefícios</label>

          <select
            className="input_thingy"
            onChange={handleChange}
            style={{ width: '100%', color: '#000' }}
          >
            <option></option>
            {uniqueBenefit.map((benefit) => (
              <option value={benefit.id + '_' + benefit.service_name}>
                {benefit.service_name}
              </option>
            ))}
          </select>
        </div>

        <button onClick={submit_history} style={{ marginTop: '50px' }} className="select_btn">
          Selecione
        </button>

        {appState.user.position === 'editor' ? (
          <DeleteMember
            Delete={Delete}
            full_name={memberDetails.first_names + ' ' + memberDetails.last_name}
          />
        ) : (
          <div />
        )}
      </Box>
    </div>
  )
}

export default MUP_CA
