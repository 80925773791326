import React, { useState, useEffect } from 'react'
import './AMP.css'
import { Center, Box, Button } from '@chakra-ui/react'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import AttachmentIcon from '@material-ui/icons/Attachment'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

function AMP_Page3({ nextStep, previousStep }) {
  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="amp_page1_heading" style={{ paddingTop: '10px' }}>
          Historial Medico e Questoes de Saude
        </p>

        <Box>
          <p className="amp_sub_heading" style={{ width: '54%', display: 'inline-block' }}>
            Historial Medico e Questoes de Saude
          </p>

          <p className="amp_sub_heading" style={{ display: 'inline-block', marginLeft: '10%' }}>
            Sim
          </p>
          <p className="amp_sub_heading" style={{ display: 'inline-block', marginLeft: '14%' }}>
            Nao
          </p>
        </Box>

        <Box style={{ width: '100%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            1. Fuma ou fumou nos últimos 24 meses?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r1" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r1" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            2. Alguma doença ou problema do coração (ataque cardíaco, por exemplo, febre reumática,
            sopro no coração, doença arterial coronária, dor no peito, falta de ar ou palpitações)?{' '}
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r2" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r2" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            3. A pressão arterial elevada ou doença dos vasos sanguíneos (ex. colesterol elevado,
            AVC ou distúrbio circulatório)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r3" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r3" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            4. Algum problema respiratório ou pulmonar (ex. asma, bronquite, tosse persistente
            tuberculose)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r4" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r4" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            5. Alguma doença ou problema do sistema digestivo, vesícula biliar, fígado ou pâncreas
            (ex. úlcera gástrica ou duodenal, pancreatite, indigestão recorrente, hérnia de hiato,
            hepatite B ou diarreia persistente)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r5" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r5" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            6. Alguma doença ou problema nos rins, vesicula/bexiga ou órgãos reprodutivos (ex.
            albumina na urina, pedras nos rins, problemas com órgãos femininos ou doença venérea)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r6" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r6" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            7. Alguma doença ou problema relacionada com sistema nervoso ou de cariz mental (ex.
            epilepsia, enxaqueca, desmaios, perda de consciência, paralisia, ansiedade ou
            depressão)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r7" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r7" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            8. Algum problema de audição, visão ou garganta (ex. descarga, deficiência de visão,
            usar óculos / lentes de contacto, rinite, amigdalite recorrente, glândulas inchadas,
            feridas na boca persistentes, catarata ou qualquer doença hereditárias, comprometimento
            funcional do nariz, sinusite crónica, alérgica)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r8" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r8" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            9. Alguma doença ou problema de músculos, ossos, articulações, membros ou coluna (ex.
            reumatismo, artrite, gota, osteoporose, hérnia discal ou outro problema na coluna)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r9" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r9" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            10. Tiróide, diabetes ou outros problemas nas glândulas ou no sangue?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r10" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r10" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            11. Algum nódulo, tumores (benigno ou maligno), tipos de cancros (incluindo Hodgkin e
            Leucemia) Cancro da pele ou doenças de pele?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r11" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r11" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            12. Alguma doença tropical (e.g. Bilharziose, Malária, Cólera)?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r12" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r12" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            13. Foi testado para, recebeu ou espera receber, algum aconselhamento médico,
            orientação, tratamento ou exame de sangue relacionado com o HIV / SIDA ou de uma doença
            relacionada com a SIDA ou qualquer doença sexualmente transmissível, por exemplo
            Hepatite B, gonorreia e sífilis?
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r13" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r13" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            14. (Se é do sexo feminino) Você ou algum dos seus dependentes encontra-se grávida? Se
            sim, indique a data prevista para o parto, na seção E1 abaixo.
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r14" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r14" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <Box style={{ width: '100%', paddingTop: '0%' }}>
          <p className="p_writing" style={{ width: '54%', display: 'inline-block' }}>
            15. Tem alguma outra doença que não foi mencionada acima? Se sim, mencione no quadro
            posterior.
          </p>

          <Box
            style={{
              display: 'inline-block',
              paddingTop: '4px',
              marginLeft: '10%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r15" onClick={(e) => {}} type="radio" value="yes" />
          </Box>

          <Box
            style={{
              display: 'inline-block',
              marginLeft: '52px',
              paddingTop: '4px',
              marginLeft: '14%',
              width: '20px',
              height: '20px'
            }}
          >
            <input name="r15" onClick={(e) => {}} type="radio" value="no" />
          </Box>
        </Box>

        <div style={{ display: 'block', width: '86%', marginTop: '40px', textAlign: 'end' }}>
          <Button
            onClick={previousStep}
            leftIcon={<ArrowBackIosIcon style={{ height: '12px' }} />}
            className="prev_sect_btn"
          >
            Previous Section{' '}
          </Button>

          <Button
            onClick={nextStep}
            rightIcon={<ArrowForwardIosIcon style={{ height: '12px' }} />}
            className="next_sect_btn"
            style={{ marginLeft: '24px' }}
          >
            Next Section{' '}
          </Button>
        </div>
      </Box>
      <br />
    </div>
  )
}

export default AMP_Page3
