import React, { useState } from 'react'
import './TransactionDetailPage.css'
import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useHistory, Redirect } from 'react-router-dom'

function SubHeader(props) {
  let history = useHistory()

  return (
    <div className="main-page">
      <Box style={{ width: '100%', height: '7%', backgroundColor: '#fff', marginTop: '2px' }}>
        <Box>
          <Button
             onClick={() =>
                      history.push({
                        pathname: '/transaction',
                        // search: '?query=abc',
                        // state: {
                        //   provider_id: props.provider_id
                        // }
                      })
                    }
            className="back_to_m"
            leftIcon={<KeyboardBackspaceIcon style={{ height: '18px' }} />}
          >
            Voltar para transações{' '} {props.name}
          </Button>

          {/* <Button  onClick={() =>
                    history.push({
                      pathname: '/providerDetail',
                      // search: '?query=abc',
                      state: {
                        provider_id: props.provider_id,
                        name:"DANG"
                      }
                    })
                  }> Hallo </Button> */}
        </Box>
      </Box>
    </div>
  )
}

export default SubHeader
