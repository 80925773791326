import React, { useState, useEffect } from 'react'
import './MUP.css'
import { Center, Box, Button } from '@chakra-ui/react'
import SubmitTrans from '../Modal/SubmitTrans'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import { formatCurrency } from '../utility/FormatCurrency'

function MUP_LeftDeet(props) {
  const click = () => {
    alert(JSON.stringify(props.memberDetails))
  }

  const [AllFetchedTrans, setAllTrans] = useState([])
  
  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_transaction_for_print',
        body: {
          member_id: props.main_member_id===0?(props.memb_id):(props.main_member_id)
        }
      })
      .then((result) => {
        console.log('Transactions for The Mans', result.data.data)
        setAllTrans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  let thePrice = 0
  AllFetchedTrans.slice(0, 100).map((e) => {
    e.transactions.map((ey) => {
      thePrice += ey.price < 15 ? ey.alt_price : ey.price
    })
  })

  return (
    <div style={{ overflowY: 'auto', height: '75vh' }}>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p>Detalhes </p>

        <Box style={{ width: '95%' }}>
          <label onClick={click} className="left_deet_label">
            Nome completo
          </label>
          <p className="values_left">
            {props.memberDetails.first_names} {props.memberDetails.last_name}{' '}
          </p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Plano- Número da polície</label>
          <p className="values_left">
            {props.memberDetails.plan.name} - {props.memberDetails.policy_no}
          </p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            Numero do Membro
          </label>
          <p className="values_left">{props.memberDetails.member_no}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            End Date
          </label>
          <p className="values_left">{props.memberDetails.end_date}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Limite anual</label>
          <p className="values_left">{formatCurrency(props.memberDetails.plan.total_amount)} MT</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Saldo</label>
          <p className="values_left">
            {formatCurrency(props.memberDetails.plan.total_amount - thePrice)} MT
          </p>
        </Box>

        <Button
          onClick={props.addTransaction}
          leftIcon={<PlaylistAddIcon style={{ height: '18px' }} />}
          style={{ marginTop: '16px' }}
          className="add_transaction_btn"
        >
          Adicionar Procedimento
        </Button>
      </Box>

      <SubmitTrans
        transNums={props.transNums}
        memberDetails={props.memberDetails}
        submitTrans={props.SubmitTrans}
      />
    </div>
  )
}

export default MUP_LeftDeet
