import React, { useState, useEffect, useContext } from 'react'
import './TDP.css'
import { Center, Box } from '@chakra-ui/react'
import { AppContext } from '../core/app-context'
import api from '../core/api'
import CloseIcon from '@material-ui/icons/Close'

function TDP_Claim({transactionDetails,  setNewProvId, editing}) {
  const [appState, dispatch] = useContext(AppContext)

  const [newProviderName, setNewProviderName] = useState("")


useEffect(() => {
  transactionDetails.slice(0, 1).map((trans) => (
  setNewProviderName(trans.provider.name)

  ))
}, [transactionDetails])


  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_providers',
        body: {} 
      })
      .then((result) => {
        console.log('providers', result.data.data)
        setProviders(result.data.data)
        
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [])

  


  const [providers, setProviders] = useState([])
  const [filteredProviders, setFilteredProviders] = useState([])

  const [wordEntered, setWordEntered] = useState('')

  const [searchForm, setSearchForm] = useState({
    provider: ''
  })

  
  const [searching, setSearching] = useState('no')

  const filterProviders = (filterForm) => {
    let newProviders = providers
    if (filterForm.provider.toString().length > 0) {
      newProviders = providers.filter(
        (m) =>
          filterForm.provider.toString().length > 0 &&
          m.name.toString().toLowerCase().includes(filterForm.provider.toLowerCase())
      )
    }

    // console.log("newMembers", newMembers);
    setFilteredProviders(newProviders)
  }
 

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings" style={{ paddingTop: '10px' }}>
          Atendimento
        </p>

        <Box style={{ width: '35%', display: 'inline-block' }}>
          <label className="label_claims">
            Provedor <span style={{ color: '#ED3737' }}>*</span>
          </label>

    
            {editing !== true?(<input
              className="input_completed"
              value={newProviderName}
              style={{ width: '100%' }}
              type="text"
            />):
        

(<input
            className="input_claim"
            onChange={(e) => {
              filterProviders(searchForm)

              setSearchForm({
                ...searchForm,
                provider: e.currentTarget.value
              })
            }}
            onFocus={(e) => {
              setSearching('yes')
            }}
            value={searchForm.provider}
            style={{ width: '90%', display: 'inline-block' }}
            type="text"
          />)}
          <div
            className={searching === 'yes' ? 'display_icon' : 'display_nothing'}
            style={{ alignItems: 'bottom', height: '28px', width: '20px', }}
          >
            <CloseIcon
              onClick={() => {
                setSearching('no')
                setSearchForm({ provider: '' })
              }}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
          <div
            className={searching === 'yes' ? 'display_result' : 'display_nothing'}
            style={{ position: 'absolute' }}
          >
            {filteredProviders.slice(0, 5).map((e) => {
              return (
                <h5
                  value={e.name}
                  onClick={() => {
                    setNewProvId(e.id)
                    setSearchForm({ provider: e.name })
                    setSearching('no')
                    setNewProviderName(e.name)
                  }}
                  className="dataItem"
                >
                  {e.name} -- {e.address}
                </h5>
              )
            })}
          </div>

          
        </Box>

        <Box style={{ width: '18%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">Autorização emitida por</label>
          {transactionDetails.slice(0, 1).map((trans) => (
            <input
              className="input_completed"
              value={trans.auth_by}
              style={{ width: '100%' }}
              type="text" 
            />
          ))}
        </Box>

        <Box style={{ width: '15%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims">Numéro de Autorização</label>
          {transactionDetails.slice(0, 1).map((trans) => (
            <input
              className="input_completed"
              value={trans.auth_no}
              placeholder=" "
              readOnly
              style={{ width: '100%' }}
              type="text"
            />
          ))}
        </Box>
      </Box>
    </div>
  )
}

export default TDP_Claim
