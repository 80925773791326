import React, { useState } from 'react'
import './AddMemberPage.css'
import Header from '../Top/Header'
import SubHeader from './SubHeader'
import AMP_LEFT from '../AMP/AMP_Left'
import AMP_PAGE1 from '../AMP/AMP_Page1'
import AMP_PAGE2 from '../AMP/AMP_Page2'
import AMP_PAGE3 from '../AMP/AMP_Page3'
import AMP_PAGE4 from '../AMP/AMP_Page4'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useHistory, Redirect } from 'react-router-dom'
import api from '../core/api'

function AddMemberPage() {
  let history = useHistory()

  const [page, setPage] = useState(1)

  const nextStep = () => {
    setPage(page + 1)
  }

  const previousStep = () => {
    setPage(page - 1)
  }

  //PAGE 1
  let [titlez, setTitle] = useState('Mr')
  let [fNames, setfNames] = useState('')
  let [gender, setGender] = useState('')
  let [idNo, setIdNo] = useState('')
  let [lName, setlName] = useState('')
  let [D_o_b, setDob] = useState('')
  let [C_O_Wx, setCOW] = useState('')
  let [homeNum, setHomeNum] = useState('')
  let [phone, setPhone] = useState('')
  let [address, setAddress] = useState('')
  let [email, setEmail] = useState('')

  const [fetchedMembInfo, setMembInfo] = useState({})

  const createMember = () => {
    api
      .post('/api.php', {
        action: 'create_member',
        body: {
          first_names: fNames,
          id_number: idNo
        }
      })
      .then((result) => {
        console.log('MEMBER SUFF', result.data.data)
        setMembInfo(result.data.data)
      })
      .catch((err) => console.warn(err))
  }

  //PAGE 2
  let [Planid, setPlanId] = useState('1')
  let [policyNo, setPolicyNo] = useState('')
  let [memberNo, setMemberNo] = useState('')
  let [startdate, setStartdate] = useState('')
  let [enddate, setEnddate] = useState('')
  let [attachLink, setAttachLink] = useState('')
  let [waitperiod, setWaitperiod] = useState('')

  //PAGE 4

  const [sick, setCondition] = useState('')
  const [treat, setTreatment] = useState('')
  const [d_o_t, setDot] = useState('')
  const [s_state, setState] = useState('')

  const [items, setItems] = useState([])

  const [sickness, setSickness] = useState([{ id: '1' }])

  const addCondition = () => {
    let nextId = 0

    for (let i = 0; i < sickness.length; i++) {
      if (sickness[i].id > nextId) {
        nextId = sickness[i].id
      }

      nextId++
    }
    setSickness([
      ...sickness,
      {
        id: nextId
      }
    ])
  }

  const saveCondition = () => {
    let memb_id = ''

    if (fetchedMembInfo !== undefined) {
      memb_id = fetchedMembInfo[0].id
    }

    const newItem = {
      member_id: memb_id,
      sickness: sick,
      treatment_type: treat,
      date_of_treatment: d_o_t,
      sick_state: s_state
    }

    const newItems = [...items, newItem]

    setItems(newItems)
  }

  let pageDisplay = null
  switch (page) {
    case 1:
      pageDisplay = (
        <AMP_PAGE1
          nextStep={nextStep}
          title={titlez}
          setTitle={setTitle}
          gender={gender}
          setGender={setGender}
          idNo={idNo}
          setIdNo={setIdNo}
          fNames={fNames}
          setfNames={setfNames}
          lName={lName}
          setlName={setlName}
          D_o_b={D_o_b}
          setDob={setDob}
          C_O_W={C_O_Wx}
          setCOW={setCOW}
          homeNum={homeNum}
          setHomeNum={setHomeNum}
          phone={phone}
          setPhone={setPhone}
          address={address}
          setAddress={setAddress}
          email={email}
          setEmail={setEmail}
          createMember={createMember}
        />
      )

      break

    case 2:
      pageDisplay = (
        <AMP_PAGE2
          nextStep={nextStep}
          previousStep={previousStep}
          Planid={Planid}
          setPlanId={setPlanId}
          policyNo={policyNo}
          setPolicyNo={setPolicyNo}
          memberNo={memberNo}
          setMemberNo={setMemberNo}
          startdate={startdate}
          setStartdate={setStartdate}
          enddate={enddate}
          setEnddate={setEnddate}
          attachLink={attachLink}
          setAttachLink={setAttachLink}
          waitperiod={waitperiod}
          setWaitperiod={setWaitperiod}
        />
      )
      break

    case 3:
      pageDisplay = <AMP_PAGE3 nextStep={nextStep} previousStep={previousStep} />
      break
    case 4:
      pageDisplay = (
        <AMP_PAGE4
          previousStep={previousStep}
          setCondition={setCondition}
          setTreatment={setTreatment}
          sickness={sickness}
          setDot={setDot}
          setState={setState}
          addCondition={addCondition}
          saveCondition={saveCondition}
        />
      )
      break

    default:
      break
  }

  const submitMember = () => {
    let memb_id = ''

    if (fetchedMembInfo !== undefined) {
      memb_id = fetchedMembInfo[0].id
    }

    api
      .post('/api.php', {
        action: 'insert_member_info',
        body: {
          title: titlez,
          first_names: fNames,
          last_name: lName,
          d_o_b: D_o_b,
          policy_no: policyNo,
          member_no: memberNo,
          id_number: idNo,
          email: email,
          gender: gender,
          phone: phone,
          alt_phone: homeNum,
          address: address,
          plan_id: Planid,
          start_date: startdate,
          end_date: enddate,
          waiting_period: waitperiod,
          attach_link: attachLink,
          member_id: memb_id
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className="main-page">
      <div className="header_member">
        <Header />
      </div>
      <Box style={{ height: '6%', marginTop: '-8px' }}>
        <SubHeader />
      </Box>

      <div style={{ width: '100%', height: '87.5%', marginTop: '0.5%' }}>
        <div style={{ marginTop: '10px', width: '30%', marginLeft: '32px', position: 'absolute' }}>
          <AMP_LEFT page={page} submitMember={submitMember} items={items} />
        </div>

        <div style={{ width: '33%', height: '27%', display: 'inline-block' }} />

        <Box
          style={{
            width: '65%',
            height: '95%',
            marginLeft: '10px',
            marginTop: '1%',

            display: 'inline-block',
            overflowX: 'hidden',
            overflowY: 'auto'
          }}
        >
          {pageDisplay}
        </Box>
      </div>
    </div>
  )
}

export default AddMemberPage
