import React, { useContext, useEffect, useState } from 'react'
import './PTP.css'

import { Center, Box } from '@chakra-ui/react'
import TopProviders from '../Top/TopProviders'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useHistory, Redirect } from 'react-router-dom'

import { AppContext } from '../core/app-context'
import api from '../core/api'

import Pagination from './Pagination'
import UpdatePayment from '../Modal/UpdatePayment'
import Print from '../PrintPages/PrintInvoice'

function PTP_Invoices(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [providersTransactions, setprovidersTrans] = useState([])
  const [filteredProviders, setFilteredProviders] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [providersPerPage, setProvidersPerPage] = useState(6)
  const [refreshKey, setRefreshKey] = useState(1)

  let history = useHistory()

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_paid_invoices',
        body: { provider_id: props.provider_id }
      })
      .then((result) => {
        console.log('THE FACTURAS', result.data.data)
        setprovidersTrans(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [props.refresh, props.provider_id])

  useEffect(() => {
    // console.log("members", members);
    setFilteredProviders(providersTransactions)
  }, [providersTransactions])

  const [SelectedFactura, setSelectedFactura] = useState('')

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box style={{ width: '100%' }}>
        <Center>
          <table className="main_table_ptp">
            <thead className="ptp_head">
              <th> </th>
              <th style={{ width: '20%' }}>Issue Date</th>
              <th style={{ width: '20%' }}>Lote No</th>
              <th style={{ width: '20%' }}>Valor</th>
              <th style={{ width: '20%' }}> No of Transações</th>
              <th style={{ width: '20%' }}> </th>
            </thead>

            {providersTransactions.map((e) => {
              let thePrice = 0
              let date = e.issue_date
              let lot_no = e.invoice_no
              e.transactions.map((ey) => {
                thePrice += ey.price < 15 ? ey.alt_price : ey.price
              })

              return (
                <tr className="main_table_inside_ptp">
                  <td>
                    {e.status !== 'Pago' ? (
                      <input
                        style={{ width: '12px', height: '12px' }}
                        type="checkbox"
                        value={e.id}
                        onClick={(e) => {
                          props.setClickedFactura(e.target.value)
                          setSelectedFactura(e.target.value)
                          props.setTheP(thePrice)
                          props.setTheDate(date)
                          props.setInvNum(lot_no)
                        }}
                      />
                    ) : (
                      <p> </p>
                    )}
                  </td>

                  <td style={{ width: '50px' }}>{e.issue_date}</td>
                  <td>000{e.invoice_no}</td>

                  <td>{thePrice} MT</td>
                  <td>
                    {e.transactions.length}
                    <span className="span_member"></span>
                  </td>

                  <td>
                    <Print
                      invoice_no={e.invoice_no}
                      SelectedFactura={SelectedFactura}
                      issue_date={e.issue_date}
                      price={e.price}
                      receipt_no={e.receipt_no}
                      Transactions={e.transactions != null ? e.transactions : ''}
                      paid_invoice_id={SelectedFactura}
                      // paid_invoice_id={e.id}
                    />
                  </td>
                  {/* <td  onClick={() =>
                      // history.push({
                       
                         pathname: '/transactionDetail',
                        // search: '?query=abc',
                        state: {
                          provider_id: e.provider_id,
                          auth_no: e.auth_no,
                          member_id: e.member.id,
                          date: e.date,
                          time: e.time,
                          coming_from: 'provider'
                        }
                      })
                    } */}
                  {/* <td><UpdatePayment totalAmount={thePrice}/> </td> */}
                  <td>
                    <p className={e.status === 'paid' ? 'paid_status' : ''}>
                      {e.status === 'paid' ? 'Pago' : ''}
                    </p>
                  </td>
                </tr>
              )
            })}
          </table>
        </Center>
      </Box>
    </div>
  )
}

export default PTP_Invoices
