import React, { useContext, useEffect, useState } from 'react'
import './ProvidersPage.css'

import { Center, Box } from '@chakra-ui/react'
import TopProviders from '../Top/TopProviders'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useHistory, Redirect } from 'react-router-dom'
import { AppContext } from '../core/app-context'
import api from '../core/api'

import Pagination from './Pagination'
import Spinner from '../Components/Spinner'

function ProvidersPage() {
  const [appState, dispatch] = useContext(AppContext)

  const [providers, setproviders] = useState([])
  const [filteredProviders, setFilteredProviders] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [providersPerPage, setProvidersPerPage] = useState(6)
  const [refreshKey, setRefreshKey] = useState(1)
  const [loading, setLoading] = useState(false)

  //Get Current Posts
  const indexOfLastProvider = currentPage * providersPerPage
  const indexOfFirstProvider = indexOfLastProvider - providersPerPage
  const currentProviders = providers.slice(indexOfFirstProvider, indexOfLastProvider)

  //Change page

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const handleNextPage = () => {
    if (currentPage === providers.length / providersPerPage) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleLastPage = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  let history = useHistory()

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  useEffect(() => {
    setLoading(true)
    api
      .post('/api.php', {
        action: 'get_providers_list',
        body: {}
      })
      .then((result) => {
        console.log('Providers', result.data.data)
        setproviders(result.data.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.warn(err)
      })
  }, [])

  useEffect(() => {
    // console.log("members", members);
    setFilteredProviders(providers)
  }, [providers])

  const filterProviders = (filterForm) => {
    let newProviders = providers
    if (filterForm.prov.toString().length > 0 || filterForm.date.toString().length > 0) {
      newProviders = providers.filter(
        (m) =>
          (filterForm.prov.toString().length > 0 &&
            `${m.name}`.toString().toLowerCase().includes(filterForm.prov.toString())) ||
          (filterForm.date.toString().length > 0 &&
            m.date.toString().toLowerCase().includes(filterForm.date.toString().toLowerCase()))
      )
    }

    // console.log("newMembers", newMembers);
    setFilteredProviders(newProviders)
  }

  return (
    <div>
      <Box style={{ height: '200px' }}>
        <TopProviders
          refresh={refresh}
          onSearch={filterProviders}
          setCurrentPage={setCurrentPage}
        />
      </Box>

      {loading && (
        <Box
          style={{
            marginLeft: '40px',
            marginBottom: '10px'
          }}
        >
          <Spinner />
        </Box>
      )}

      <Box style={{ width: '100%' }}>
        <Center>
          <table className="main_table_provider">
            <thead className="providers_head">
              <th style={{ width: '40%' }}>Provedor</th>
              <th style={{ width: '15%' }}>Total de Claims</th>
              <th style={{ width: '15%' }}>Total de claims não pagas</th>
              <th style={{ width: '15%' }}>Total claims Pagas</th>
              <th style={{ width: '10%' }}> </th>
            </thead>

            {/*{transactions}*/}
            {filteredProviders.slice(indexOfFirstProvider, indexOfLastProvider).map((e) => {
              let total_paid = 0

              e.transactions.map((ey) => {
                if (ey.status === 'paid') {
                  total_paid += 1
                }
              })

              return (
                <tr
                  className="main_table_inside_provider"
                  onClick={() =>
                    history.push({
                      pathname: '/providerDetail',
                      // search: '?query=abc',
                      state: {
                        provider_id: e.id,
                        address: e.address,
                        trans: e.transactions.length,
                        total_paid: total_paid,
                        name: e.name
                      }
                    })
                  }
                >
                  <td>
                    {e.name}
                    <span className="span_member">{e.address}</span>
                  </td>
                  <td>{e.transactions.length}</td>

                  <td>{e.transactions.length - total_paid}</td>
                  <td>{total_paid}</td>
                  <td> </td>
                </tr>
              )
            })}
          </table>
        </Center>
      </Box>
      <br/>
      <Pagination
        setProvidersPerPage={setProvidersPerPage}
        handleNextPage={handleNextPage}
        currentPage={currentPage}
        handleLastPage={handleLastPage}
        providersPerPage={providersPerPage}
        totalProviders={providers.length}
        paginate={paginate}
      />
    </div>
  )
}

export default ProvidersPage
