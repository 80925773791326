import React, { useEffect, useState, useContext } from 'react'
import './TransactionDetailPage.css'
import Header from '../Top/Header'
import SubHeader from './SubHeader'
import { useHistory, Redirect } from 'react-router-dom'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Center, Box } from '@chakra-ui/react'
import TDP_Claim from '../TDP/TDP_Claim'
import TDP_Transaction from '../TDP/TDP_Transaction'
import TDP_Status from '../TDP/TDP_Status'
import TDP_Status_Read from '../TDP/TDP_Status_Read'
import TDP_LeftDeet from '../TDP/TDP_LeftDeet'
import api from '../core/api'
import PrintTrans from '../PrintPages/PrintTransaction'
import { AppContext } from '../core/app-context'

function TransactionDetailPage(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [transactionDetails, setTransactionDetails] = useState(null)
  const [memberTransactions, setMemberTransactions] = useState(null)
  const [trans, setTrans] = useState([])

  const [pageOn, setPageOn] = useState('')

  const defaultV = [
    {
      auth_by: '',
      auth_no: ' ',
      created_at: ' ',
      date: ' ',
      id: 2,
      member: {
        id: 1,
        first_names: '',
        last_name: ' ',
        policy_no: ' ',
        member_no: ' '
      },
      member_id: 1,
      plan: { id: 1, name: ' ', total_amount: ' ' },
      price: ' ',
      procedure_name: ' ',
      provider: { id: 3, name: ' ', email: '', phone: '', created_at: ' ' },
      provider_id: 3,
      service: {
        id: 16,
        plan_id: '1',
        service_type_id: ' ',
        service_name: ' ',
        amount: ' '
      },
      service_type: {
        name: ''
      },
      service_id: 16,
      time: ' ',
      timestamp: ' '
    }
  ]

  const theVal = [
    {
      account: '',
      changed_by: ' ',
      changed_date: '',
      comprovativo: ' ',
      id: 1,
      invoice_no: ' ',
      issue_date: ' ',
      payment_meth: ' ',
      receipt_no: ' ',
      status: ' ',
      transaction_id: ' '
    }
  ]

  const [refreshKey, setRefreshKey] = useState(1)

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }



  //16/04/22 
  const [newProvId, setNewProvId] = useState("")

  const [editing, setEditing] = useState(false)

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_transaction_details',
        body: {
          //provider_id: props.location.state.provider_id,
          member_id: props.location.state.member_id,
          date: props.location.state.date,
          time: props.location.state.time,
          auth_no: props.location.state.auth_no
        }
      })
      .then((result) => {
        console.log('transaction details', result.data.data)
        setTransactionDetails(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [refreshKey, props.location.state.member_id])

  let separator = '-'
  let newDate = new Date()
  let date_raw = newDate.getDate()
  let month_raw = newDate.getMonth() + 1
  let year = newDate.getFullYear()
  var date, month

  if (date_raw < 10) {
    date = '0' + date_raw.toString()
  } else {
    date = date_raw.toString()
  }
  if (month_raw < 10) {
    month = '0' + month_raw.toString()
  } else {
    month = month_raw.toString()
  }

  let newCurrentDate = year + separator + month + separator + date

  const ClaimTotal =
    transactionDetails !== null
      ? transactionDetails.reduce((prev, { price }) => {
          return price >= 5 ? prev + price : prev + price - (price <= 5 ? price : prev)
        }, 0)
      : defaultV.reduce((prev, { price }) => {
          return prev + price
        }, 0)

  const ClaimAltTotal =
    transactionDetails !== null
      ? transactionDetails.reduce((prev, { alt_price }) => {
          return prev + alt_price
        }, 0)
      : defaultV.reduce((prev, { price }) => {
          return prev + price
        }, 0)

  const [transactionStatus, setTransactionStatus] = useState([])

  let transaction_id = 0

  if (transactionDetails !== null) {
    for (var iy = 0; iy < transactionDetails.length; iy++) {
      transaction_id = transactionDetails[iy].id
    }
  }

  const personN = appState.user.first_names + ' ' + appState.user.last_name

  const [Tid, setTid] = useState(null)

  const handleEditClick = (event, transactionStatus) => {
    event.preventDefault()
    setTid(transactionStatus.id)

    const formValues = {
      account: transactionStatus.account,
      changed_by: transactionStatus.changed_by,
      comprovativo: transactionStatus.comprovativo,
      invoice_no: transactionStatus.invoice_no,
      issue_date: newCurrentDate,
      payment_meth: transactionStatus.payment_meth,
      receipt_no: transactionStatus.receipt_no,
      status: transactionStatus.status
    }

    setEditStatusData(formValues)
  }

  const [addStatusData, setAddStatusData] = useState({
    account: '',
    changed_by: '',
    comprovativo: '',
    id: '',
    invoice_no: '',
    issue_date: '',
    payment_meth: '',
    receipt_no: '',
    status: ''
  })

  const handleAddStatusChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value

    const newStatData = { ...addStatusData }
    newStatData[fieldName] = fieldValue

    setAddStatusData(newStatData)
  }

  const handleAddStatusSubmit = (event, transactionStatus) => {
    event.preventDefault()
    setTid(transactionStatus.id)

    const formValues = {
      account: transactionStatus.account,
      changed_by: transactionStatus.changed_by,
      comprovativo: transactionStatus.comprovativo,
      invoice_no: transactionStatus.invoice_no,
      issue_date: newCurrentDate,
      payment_meth: transactionStatus.payment_meth,
      receipt_no: transactionStatus.receipt_no,
      status: transactionStatus.status
    }

    setAddStatusData(formValues)
  }

  const [editStatusData, setEditStatusData] = useState({
    account: '',
    changed_by: '',
    comprovativo: '',
    id: '',
    invoice_no: '',
    issue_date: '',
    payment_meth: '',
    receipt_no: '',
    status: ''
  })

  const handleEditStatusChange = (event) => {
    event.preventDefault()
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value

    const newStatData = { ...editStatusData }
    newStatData[fieldName] = fieldValue

    setEditStatusData(newStatData)
  }

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_transaction_status',
        body: {
          transaction_id: transaction_id
        }
      })
      .then((result) => {
        console.log('transaction status', result.data.data)
        setTransactionStatus(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [transaction_id])

  const [AllTrans, setAllTrans] = useState([])

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_all_trans_for_individual',
        body: {
          member_id: props.location.state.member_id
        }
      })
      .then((result) => {
        console.log('Every Single Transaction TransDetailPage', result.data.data)
        setAllTrans(result.data.data)
      })
      .catch((err) => console.warn(err))
  }, [])

  const TotalTransValue =
    AllTrans !== null
      ? AllTrans.reduce((prev, { price }) => {
          return prev + price
        }, 0)
      : 'a'

  let history = useHistory()

  const SubmitStatus = () => {
    api
      .post('/api.php', {
        action: 'submit_transaction_status',

        body: {
          transaction_id: transaction_id,
          issue_date: addStatusData.issue_date || editStatusData.issue_date,
          invoice_no: addStatusData.invoice_no || editStatusData.invoice_no,
          payment_meth: addStatusData.payment_meth || editStatusData.payment_meth,
          account: addStatusData.account || editStatusData.account,
          receipt_no: addStatusData.receipt_no || editStatusData.receipt_no,
          comprovativo: addStatusData.comprovativo || editStatusData.comprovativo,
          status: addStatusData.status || editStatusData.status,
          changed_by: personN,
          auth_no: props.location.state.auth_no
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }



  return (
    <div className="main-page">
      <div className="header_member">
        <Header />
      </div>
      <Box style={{ height: '6%', marginTop: '-8px', width: '100%' }}>
        <div style={{ display: 'inline-block', width: '90vw' }}>
          <SubHeader
            provider_id={props.location.state.provider_id}
            pageOn={props.location.state.coming_from}
          />
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '10vw',
            backgroundColor: '#fff',
            height: '82%',
            verticalAlign: 'middle',
            paddingTop: '14px'
          }}
        >
          <PrintTrans
            transactionDetails={transactionDetails !== null ? transactionDetails : defaultV}
            TotalTransValue={TotalTransValue}
          />
        </div>
      </Box>

      <div style={{ width: '100%', height: '87.5%', marginTop: '0.5%' }}>
        <Tabs variant="enclosed">
          <div
            style={{ marginTop: '10px', width: '30%', marginLeft: '32px', position: 'absolute' }}
          >
            <TabList>
              <Tab
                _selected={{
                  color: '#FF5A5D',
                  bg: '#FFF1F3',
                  border: '1px solid #FF5A5D',
                  opacity: '1'
                }}
                className="tab_btn"
              >
                Status 
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <TDP_LeftDeet
                  TotalTransValue={TotalTransValue}
                  transactionDetails={transactionDetails !== null ? transactionDetails : defaultV}
                  SubmitStatus={SubmitStatus}
                  provider_id={props.location.state.provider_id}
                  auth_no={props.location.state.auth_no}
                />
              </TabPanel>
            </TabPanels>
          </div>

          <div style={{ width: '33%', height: '27%', display: 'inline-block' }} />

          <Box
            style={{
              width: '65%',
              height: '95%',
              marginLeft: '10px',
              marginTop: '1%',
              border: '1px solid #fff',
              display: 'inline-block',
              overflowX: 'hidden',
              overflowY: 'scroll',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
              
            }}
          >
            <Box
              style={{
                width: '100%',
                height: '150px',
                border: '1px solid #fff',
                backgroundColor: '#fff'
                
              }}
            >
              <TabPanels style={{ backgroundColor: '#fff', width: '100%', height: '25vh' }}>
                <TabPanel>
                  {Tid === transactionStatus.id ? (
                    <TDP_Status
                      editStatusData={editStatusData}
                      addStatusData={addStatusData}
                      handleEditStatusChange={handleEditStatusChange}
                      handleAddStatusChange={handleAddStatusChange}
                      ClaimTotal={ClaimTotal}
                      ClaimAltTotal={ClaimAltTotal}
                    />
                  ) : (
                    <TDP_Status_Read
                      transactionStatus={transactionStatus !== null ? transactionStatus : theVal}
                      ClaimTotal={ClaimTotal}
                      ClaimAltTotal={ClaimAltTotal}
                      handleEditClick={handleEditClick}
                      handleAddStatusSubmit={handleAddStatusSubmit}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </Box>

            <Box style={{ width: '100%', height: '16vh', marginTop: '7%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px' }}>
              <TabPanels>
                <TabPanel style={{ backgroundColor: '#fff', width: '100%', height: '16vh' }}>
                  <TDP_Claim
                    transactionDetails={transactionDetails !== null ? transactionDetails : defaultV}
                    setNewProvId={setNewProvId}
                    editing={editing}
                  />
                </TabPanel>
              </TabPanels>
            </Box>

            <Box style={{ width: '100%', height: '32vh', marginTop: '9px' ,boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'}}>
              <TabPanels>
                <TabPanel style={{ backgroundColor: '#fff', width: '100%', height: '32vh' }}>
                  {transactionDetails !== null ? (
                    transactionDetails.map((e) => {
                      return (
                        <TDP_Transaction
                          id={e.id}
                          benefitline={e.service.service_name}
                          benefitlineID={e.service.id}
                          benefitClass={e.service_type.name}
                          procedureName={e.procedure_name}
                          price={e.price}
                          date={e.date}
                          time={e.time}
                          refName={e.ref_name}
                          altPrice={e.alt_price}
                          transactionDetails={
                            transactionDetails !== null ? transactionDetails : defaultV
                          }
                          refresh={refresh}
                          newProvId={newProvId}
                          setNewProvId={setNewProvId}
                          editing={editing}
                          setEditing={setEditing}
                        />
                      )
                    })
                  ) : (
                    <div />
                  )}
                </TabPanel>
              </TabPanels>
            </Box>
          </Box>
        </Tabs>
      </div>
    </div>
  )
}

export default TransactionDetailPage
