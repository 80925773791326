import { React, useState } from 'react'
import './MembersPage.css'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { Center, Box } from '@chakra-ui/react'

const Pagination = ({
  membersPerPage,
  totalMembers,
  paginate,
  handleNextPage,
  handleLastPage,
  currentPage,
  setMembersPerPage
}) => {
  const pageNumbers = []

  const [isActive, setActive] = useState(false)
  const [currentP, setCurrentP] = useState(1)

  const toggleClass = () => {
    setActive(!isActive)
  }

  for (let i = 1; i <= Math.ceil(totalMembers / membersPerPage); i++) {
    pageNumbers.push(i)
  }

  function handleChange(event) {
    setMembersPerPage(event.target.value)
  }

  return (
    <div className="right_end">
      <div className="pagination">
        <Box
          className="block_arrow"
          style={{
            float: 'left',
            color: '747587',
            width: '200px',
            fontSize: '10px',
            lineHeight: '1px',
            display: 'inline-block'
          }}
        >
          <p className="block_arrow" style={{ display: 'inline-block', marginRight: '17px' }}>
            Membros por página
          </p>

          <select className="select_p_page" onChange={handleChange}>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </Box>

        <p
          className="block_arrow"
          style={{
            color: '747587',
            width: '155px',
            fontSize: '10px',
            lineHeight: '0px',
            display: 'inline-block',
            marginRight: '37px'
          }}
        >
          {currentPage} of {Math.ceil(totalMembers / membersPerPage)}
        </p>
        <ArrowBackIos
          className="block_arrow"
          onClick={handleLastPage}
          style={{
            color: '747587',
            width: '15px',
            height: '20px',
            display: 'inline-block',
            marginRight: '60px'
          }}
        />

        <ArrowForwardIos
          className="block_arrow"
          onClick={handleNextPage}
          style={{ color: '747587', width: '15px', height: '20px', display: 'inline-block' }}
        />
      </div>
    </div>
  )
}

export default Pagination
