import React, { useState, useEffect, useContext } from 'react'
import './TDP.css'
import { Center, Box } from '@chakra-ui/react'
import { AppContext } from '../core/app-context'
import api from '../core/api'

function TDP_Status_Read({
  transactionStatus,
  handleEditClick,
  ClaimTotal,
  handleAddStatusSubmit,
  editStatusData,
  ClaimAltTotal
}) {
  const [appState, dispatch] = useContext(AppContext)

  let T_p = ClaimTotal + ClaimAltTotal

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings">
          {' '}
          Estado
          {appState.user.position === 'editor' ? (
            transactionStatus.length !== 0 ? (
              <button
                style={{ marginLeft: '100px' }}
                className="save_item_btn"
                onClick={(event) => handleEditClick(event, transactionStatus)}
              >
                Editar
              </button>
            ) : (
              <button
                style={{ marginLeft: '100px' }}
                className="save_item_btn"
                onClick={(event) => handleAddStatusSubmit(event, transactionStatus)}
              >
                Editar
              </button>
            )
          ) : (
            <p></p>
          )}
        </p>

        <Box style={{ width: '17%', display: 'inline-block' }}>
          <label className="label_claims">
            Data de emissão <span style={{ color: '#ED3737' }}>*</span>
          </label>

          <input
            Value={transactionStatus.issue_date}
            readOnly
            className="input_completed"
            style={{ width: '100%' }}
            type="date"
          />
        </Box>

        <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims">
            N da Fatura<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <input
            className="input_completed"
            defaultValue={transactionStatus.invoice_no}
            readOnly
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '17%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">
            Modalidade de Pagamento<span style={{ color: '#ED3737' }}>*</span>
          </label>
          <select
            className="input_completed"
            value={transactionStatus.payment_meth}
            style={{ width: '100%', color: '#000' }}
          >
            <option>{transactionStatus.payment_meth}</option>
          </select>
        </Box>

        <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims">
            Account <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            defaultValue={transactionStatus.account}
            className="input_completed"
            readOnly
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <div style={{ display: 'block', marginTop: '24px' }}>
          <Box style={{ width: '17%', display: 'inline-block' }}>
            <label className="label_claims">
              Recibo No <span style={{ color: '#ED3737' }}>*</span>
            </label>

            <input
              className="input_completed"
              defaultValue={transactionStatus.receipt_no}
              readOnly
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
            <label className="label_claims">
              Comprovativo <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              className="input_completed"
              defaultValue={transactionStatus.comprovativo}
              readOnly
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '17%', display: 'inline-block', marginLeft: '13%' }}>
            <label className="label_claims">Liquidação</label>

            <select
              className="input_completed"
              value={transactionStatus.status}
              style={{ width: '100%', color: '#000' }}
            >
              <option value={transactionStatus.status}>{transactionStatus.status}</option>
            </select>
          </Box>

          <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
            <label className="label_claims">
              Valor <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              className="input_completed"
              value={parseFloat(T_p).toFixed(2)}
              placeholder=" "
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
        </div>
      </Box>
    </div>
  )
}

export default TDP_Status_Read
