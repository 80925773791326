import { React, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Lorem,
  Center,
  Box
} from '@chakra-ui/react'
import AddIcon from '@material-ui/icons/Add'
import './Modal.css'

function DeleteTransaction(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <button
        className="reset_btn"
        style={{ marginTop: '50px', display: 'block', width:'120px' }}
        onClick={onOpen}
      >
        Apaga transação
      </button>

      <Modal className="ModalBody" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.5)', position: 'fixed' }} />
        <ModalContent
          style={{
            width: '30vw',
            height: '25vh',
            backgroundColor: '#fff',
            position: 'absolute',
            marginLeft: ' 35%',
            marginRight: 'auto',
            marginTop: '5%',
            borderRadius: '6px'
          }}
        >
          <ModalHeader style={{ marginLeft: '40px' }}>
            <p className="submitTrans_header">Apaga transação</p>
          </ModalHeader>

          <ModalBody className="ModalBody">
            <p className="submitTrans_body">
              Tem certeza que deseja deletar a transação com Numéro de Autorização{' '}
              <strong>{props.auth_no}</strong>
            </p>
          </ModalBody>

          <ModalFooter style={{ marginTop: '20px' }}>
            <button className="submitTrans_cancel" onClick={onClose}>
              Cancela
            </button>

            <button
              className="submitTrans_confirm"
              onClick={() => {
                props.Delete()
                onClose()
              }}
            >
              Sim
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteTransaction
