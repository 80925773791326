import React, { useEffect, useState } from 'react'
import '../Css/searchPTP.css'
import Modalz from '../Modal/Modalz'
import { Center, Box, IconButton, Button } from '@chakra-ui/react'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddIcon from '@material-ui/icons/Add'
import { useHistory, Redirect } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'

function SearchPTP_Trans({ refresh, onSearch, setCurrentPage, setSearchDate }) {
  const [dateSearch, setDateSearch] = useState(new Date())
  const [searchForm, setSearchForm] = useState({
    auth_no: ''
  })

  const [resetForm, setResetForm] = useState({
    auth_no: ''
  })

  useEffect(() => {
    console.log('searchForm', searchForm)
  }, [searchForm])

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      onSearch(searchForm)
      setCurrentPage(1)
    }
  }

  return (
    <div>
      <Center className="subheader" style={{ height: '80px', borderBottom: '1px solid #94979a' }}>
        <Box className="top_pagebox">
          <div style={{ marginTop: '1.3%', display: 'inline-block', width: '21.5%' }}>
            <label className="labels">Autho No</label>
            <input
              className="inputs_sidebar"
              style={{ width: '100%' }}
              type="text"
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  auth_no: e.currentTarget.value
                })
              }}
              onKeyDown={handleKeypress}
            />
          </div>

          <div
            style={{
              marginTop: '1.3%',
              display: 'inline-block',
              width: '21.5%',
              marginLeft: '15px'
            }}
          >
            <label className="labels">Data</label>
            <input
              className="inputs_sidebar"
              style={{ width: '100%' }}
              type="date"
              value={dateSearch}
              max={new Date().toISOString().slice(0, 10)}
              onChange={(e) => {
                setSearchDate(e.target.value)
                setDateSearch(e.target.value)
              }}
              onKeyDown={handleKeypress}
            />
          </div>

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '1.7%' }}>
            <IconButton
              className="search_btn"
              style={{ width: '50px', height: '28px' }}
              icon={<SearchIcon style={{ height: '18px' }} />}
              onClick={() => {
                onSearch(searchForm)
                setCurrentPage(1)
              }}
            />
          </div>

         

          <div style={{ marginTop: '1.3%', display: 'inline-block', marginLeft: '10%' }}>
            <Button
              style={{ width: '48px', height: '28px', fontSize: '13px' }}
              className="trash_btn"
              v
              onClick={() => {
                onSearch(resetForm)
              }}
            >
              {' '}
              Reset
            </Button>
          </div>
        </Box>
      </Center>
    </div>
  )
}

export default SearchPTP_Trans
