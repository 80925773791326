import React, { useState, useContext } from 'react'
import './MUP.css'
import { Center, Box } from '@chakra-ui/react'
import { AppContext } from '../core/app-context'

function MUP_userDet_Read({ memberDetails, handleEditClick, editMemberData }) {
  const [appState, dispatch] = useContext(AppContext)

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Box
        style={{
          width: '95%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <Box style={{ width: '35%', display: 'inline-block' }}>
          <label className="label">Nome Completo</label>
          <p className="values">
            {memberDetails.first_names} {memberDetails.last_name}
          </p>
        </Box>

        <Box style={{ width: '22%', display: 'inline-block' }}>
          <label className="label">B.I No</label>
          <p className="values">{memberDetails.id_number}</p>
        </Box>

        <Box style={{ width: '20%', display: 'inline-block' }}>
          <label className="label">Numero da apolice</label>
          <p className="values" style={{ width: '250px' }}>
            {memberDetails.policy_no}
          </p>
        </Box>

        <Box style={{ width: '20%', display: 'inline-block' }}>
          <label className="label">Data de inicio</label>
          <p className="values">{memberDetails.start_date}</p>
        </Box>

        <div style={{ marginTop: '20px' }}>
          <Box style={{ width: '25%', display: 'inline-block' }}>
            <label className="label">Email</label>
            <p className="values">{memberDetails.email}</p>
          </Box>

          <Box style={{ width: '22%', display: 'inline-block' }}>
            <label className="label">Contacto</label>
            <p className="values">{memberDetails.phone} </p>
          </Box>

          <Box style={{ width: '20%', display: 'inline-block' }}>
            <label className="label">Numero do Membro</label>
            <p className="values">{memberDetails.member_no} </p>
          </Box>

          <Box style={{ width: '20%', display: 'inline-block' }}>
            <label className="label">Data Fim</label>
            <p className="values">{memberDetails.end_date}</p>
          </Box>

          <Box style={{ width: '10%', display: 'inline-block' }}>
            <label className="label">Status</label>
            <p className="values">{memberDetails.status}</p>
          </Box>

          <Box style={{ marginTop: '16px' }}>
            {appState.user.position === 'editor' ? (
              <button
                className="save_item_btn"
                onClick={(event) => handleEditClick(event, memberDetails)}
              >
                Editar
              </button>
            ) : (
              <p></p>
            )}
          </Box>
        </div>
      </Box>
    </div>
  )
}

export default MUP_userDet_Read
