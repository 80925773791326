import React, { useState, useEffect } from 'react'
import './PTP.css'
import { Center, Box, Button } from '@chakra-ui/react'

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import api from '../core/api'
import { AppContext } from '../core/app-context'
import UpdatePayment from '../Modal/UpdatePayment'

function PTP_LeftDeet_Factura({
  address,
  trans,
  total_paid,
  name,
  clickedFactura,
  theDate,
  theP,
  invNum
}) {

  const AA = () => {

    alert(JSON.stringify(factura))
  }


  const [factura, setFactura] = useState([])

 const getInvoice = () => {
    api
      .post('/api.php', {
        action: 'get_factura',
        body: {invoice_id: clickedFactura}
      })
      .then((result) => {
        console.log('THE FACTURA ID', result.data.data)
        setFactura(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }



  return (
    <div style={{ overflowY: 'auto', height: '75vh' }}>
      <Box style={{ width: '100%', marginTop: '2px' }}>
        <p>Detalhes </p>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Provedor</label>
          <p className="values_left_ptp">{name}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label">Localização</label>
          <p className="values_left_ptp">{address}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            Total de Claims
          </label>
          <p className="values_left_ptp">{trans}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label className="left_deet_label" style={{}}>
            Total de claims não pagas
          </label>
          <p className="values_left_ptp"> {trans - total_paid}</p>
        </Box>

        <Box style={{ width: '95%' }}>
          <label  onClick={getInvoice} className="left_deet_label">Total claims Pagas</label>
          <p className="values_left_ptp"> {total_paid}</p>
        </Box>
      </Box>

      <UpdatePayment
      invNum={invNum}
      clickedFactura={clickedFactura}
      theDate={theDate}
      factura={factura}
      theP={theP}
       />
     
    </div>
  )
}

export default PTP_LeftDeet_Factura
