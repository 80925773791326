import { React, useState } from 'react'
import './PlansPage.css'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

const Pagination = ({ plansPerPage, totalPlans, paginate, handleNextPage, handleLastPage, currentPage }) => {
  const pageNumbers = []

  const [isActive, setActive] = useState(false)
  const [currentP, setCurrentP] = useState(1)

  const toggleClass = () => {
    setActive(!isActive)
  }

  for (let i = 1; i <= Math.ceil(totalPlans / plansPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
 <div className="right_end">
      <div className="pagination">
        

        <p
          className="block_arrow"
          style={{
            color: '747587',
            width: '155px',
            fontSize: '10px',
            lineHeight: '0px',
            display: 'inline-block',
            marginRight: '37px'
          }}
        >
          {currentPage} of {Math.ceil(totalPlans / plansPerPage)}
        </p>
        <ArrowBackIos
          className="block_arrow"
          onClick={handleLastPage}
          style={{
            color: '747587',
            width: '15px',
            height: '20px',
            display: 'inline-block',
            marginRight: '60px'
          }}
        />

        <ArrowForwardIos
          className="block_arrow"
          onClick={handleNextPage}
          style={{ color: '747587', width: '15px', height: '20px', display: 'inline-block' }}
        />
      </div>
    </div>
  )
}

export default Pagination
