import { Box, Center } from '@chakra-ui/react'
import './PrintPNew.css'
import { AppContext } from '../core/app-context'
import { useContext, useState, React, useEffect, Component, PropTypes } from 'react'
import BetterCareLogo from './bettercareOG.png'
import api from '../core/api'

function PrintTransaction({ transactionDetails, TotalTransValue }) {
  const print = () => {
    window.print()
  }
  const [appState, dispatch] = useContext(AppContext)

  let thePrice = 0

  {
    transactionDetails.slice(0, 100).map((e) => {
      thePrice += e.price < 5 ? e.alt_price : e.price
    })
  }

  return (
    <div>
      <button className="button_del" onClick={print}>
        Imprimir transação{' '}
      </button>
      <div style={{ width: '100%', display: 'none' }} className="divname">
        <div className="InnerDiv" class="k-widget k-grid" data-role="grid" />

        <Box
          className="logo_p"
          style={{
            width: '22.68%',
            height: '20px',
            display: 'inline-block'
          }}
        >
          <img src={BetterCareLogo} width="172px" height="46px" />
        </Box>

        <Box style={{ float: 'right', display: 'inline-block' }}>
          <p className="folha">Folha de RegiSTO DE AUTORIZACOES</p>
        </Box>

        <div
          style={{
            display: 'block',

            height: '100px',
            width: '100%'
          }}
        >
          <Box
            style={{
              border: '1px solid #000',
              width: '51.6%',
              height: '100%',
              verticalAlign: 'Top',
              marginTop: '29px',
              display: 'inline-block'
            }}
          >
            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Nome:
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails
                  .slice(0, 1)
                  .map((trans) => trans.member.first_names + ' ' + trans.member.last_name)}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Mem No:
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => trans.member.member_no)}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Pacote:
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => trans.plan.name)}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Numero da Apolice:
              </p>
              <p className="member_details_p">
                {transactionDetails.slice(0, 1).map((trans) => trans.member.policy_no)}{' '}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Telefone
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails
                  .slice(0, 1)
                  .map((trans) => trans.member.phone + '||' + trans.member.alt_phone)}
              </p>
            </div>
          </Box>

          <Box
            style={{
              display: 'inline-block',
              height: '100%',
              width: '40%',
              verticalAlign: 'Top',
              marginLeft: '24px',
              marginTop: '29px',

              background: '#F4F4F4'
            }}
          >
            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Nome do Provedor:
              </p>
              <p className="member_details_p" style={{ fontSize: '8px' }}>
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => 
                 {
                  return (
                    <p>
                      {trans.provider.name}
                    </p>
                  )
                })}
                
      
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Data:
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => trans.date)}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Hora:
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => trans.time)}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                No de Autorizacao
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => trans.auth_no)}
              </p>
            </div>

            <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
              <p style={{ width: '100px', lineHeight: '20px' }} className="member_details_p">
                {' '}
                Nome do Agente:
              </p>
              <p className="member_details_p">
                {' '}
                {transactionDetails.slice(0, 1).map((trans) => trans.ref_name)}
              </p>
            </div>
          </Box>
        </div>

        <Box style={{ width: '95%', height: 'auto', minHeight:'75%', marginTop: '50px' }}>
          <div style={{ width: '100%' }}>
            <Box
              style={{
                display: 'inline-block',
                width: '73%',
                height: '20px',
                backgroundColor: '#CD1D33',
                marginBottom: '11px'
              }}
            >
              <p className="sin"> Sinistros / Claim</p>
            </Box>

            <Box
              style={{ display: 'inline-block', width: '25%', height: '20px', marginLeft: '8px' }}
            >
              <p className="cla">
                Claim No:{' '}
                <span className="folha"> {transactionDetails.slice(0, 1).map((e) => e.id)}</span>{' '}
              </p>
            </Box>
          </div>

          <table className="sinistros" style={{ width: '100%' }}>
            <tr>
              <th style={{ width: '10%' }}>Qtd</th>
              <th style={{ width: '68%' }}>Descricao de Sinistro</th>
              <th style={{ width: '21.44%' }}>Valor</th>
            </tr>
            {transactionDetails.slice(0, 100).map((e) => {
              return (
                <tr>
                  <td>1</td>
                  <td>{e.procedure_name}</td>
                  <td>{e.price < 5 ? e.alt_price : e.price}</td>
                </tr>
              )
            })}
            <td style={{ border: '1px solid #fff' }}></td>
            <td
              style={{
                border: '1px solid #fff',
                textAlign: 'right',
                fontSize: '13px',
                fontWeight: 'bold'
              }}
            >
              {' '}
              Total
            </td>
            <td
              style={{
                border: '1px solid #fff',
                fontSize: '13px',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              {parseFloat(thePrice).toFixed(2)}{' '}
            </td>
          </table>
        </Box>

        <Box style={{ width: '95%', height: 'auto', minHeight:'310px', marginTop: '16px' }}>
          <Box
            style={{
              display: 'inline-block',
              width: '100%',
              height: '20px',
              backgroundColor: '#CD1D33',
              marginBottom: '11px'
            }}
          >
            <p className="sin" style={{ textAlign: 'center' }}>
              {' '}
              Sinistros / Claim
            </p>
          </Box>
          <table className="sinistros" style={{ width: '100%' }}>
            <tr>
              <th style={{ width: '10%' }}>Qtd</th>
              <th style={{ width: '39.45%' }}>Referencia de Sinistro</th>
              <th style={{ width: '12.79%' }}>Limite Geral</th>
              <th style={{ width: '12.79%' }}>Saldo Anterior</th>

              <th style={{ width: '11.71%' }}>Valor</th>
              <th style={{ width: '12.79%' }}>Saldo Actual</th>
            </tr>
            {transactionDetails.slice(0, 100).map((e) => {
              let pricey = e.price < 5 ? e.alt_price : e.price

              let T_p = TotalTransValue < 5 ? pricey : TotalTransValue

              return (
                <tr>
                  <td>1</td>
                  <td>{e.service_type.name}</td>
                  <td>{e.plan.total_amount}</td>
                  <td>{(e.plan.total_amount - T_p + pricey).toFixed(2)}</td>
                  <td>{e.price < 5 ? e.alt_price : e.price}</td>
                  <td>{e.plan.total_amount - T_p}</td>
                </tr>
              )
            })}
          </table>
        </Box>

        <div style={{ marginTop: '48px' }}>
          <Box style={{ width: '180px', height: '50px', display: 'inline-block' }}>
            <div style={{ width: '100%', borderBottom: '1px solid #CD1D33' }}>
              <p className="request_by" style={{ fontSize: '14px' }}>
                {' '}
                {transactionDetails.slice(0, 1).map((e) => e.auth_by)}{' '}
              </p>
            </div>

            <p className="request_by">Requisitado por </p>

            <p className="red_text"> Linha do Cliente</p>
          </Box>

          <Box
            style={{ width: '180px', height: '50px', display: 'inline-block', marginLeft: '70px' }}
          >
            <div style={{ width: '100%', borderBottom: '1px solid #CD1D33' }} />

            <p className="request_by">Verificado por </p>

            <p className="red_text"> Gestor/a de Sinistro</p>
          </Box>

          <Box
            style={{ width: '180px', height: '50px', display: 'inline-block', marginLeft: '70px' }}
          >
            <div style={{ width: '100%', borderBottom: '1px solid #CD1D33' }} />

            <p className="request_by">Aprovado por </p>

            <p className="red_text"> Director de Operacoes</p>
          </Box>
        </div>
      </div>
    </div>
  )
}

export default PrintTransaction
