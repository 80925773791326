import React, { useState, useEffect, useContext } from 'react'
import './TDP.css'
import { Center, Box } from '@chakra-ui/react'
import { AppContext } from '../core/app-context'
import api from '../core/api'

function TDP_Status({
  ClaimTotal,
  handleAddStatusChange,
  editStatusData,
  addStatusData,
  handleEditStatusChange
}) {
  const [appState, dispatch] = useContext(AppContext)

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <Box
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#fff',
          marginTop: '20px',
          marginLeft: '24px'
        }}
      >
        <p className="headings"> Estado</p>

        <Box style={{ width: '17%', display: 'inline-block' }}>
          <label className="label_claims">
            Data de emissão <span style={{ color: '#ED3737' }}>*</span>
          </label>

          <input
            name="issue_date"
            className="input_submit"
            value={editStatusData.issue_date || addStatusData.issue_date}
            onChange={handleEditStatusChange || handleAddStatusChange}
            style={{ width: '100%' }}
            readOnly
            type="date"
          />
        </Box>

        <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims">
            N da Fatura<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <input
            className="input_submit"
            name="invoice_no"
            value={editStatusData.invoice_no || addStatusData.invoice_no}
            onChange={handleEditStatusChange || handleAddStatusChange}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <Box style={{ width: '17%', display: 'inline-block', marginLeft: '13%' }}>
          <label className="label_claims">
            Modalidade de Pagamento<span style={{ color: '#ED3737' }}>*</span>
          </label>

          <select
            className="input_claim"
            name="payment_meth"
            className="input_submit"
            value={editStatusData.payment_meth || addStatusData.payment_meth}
            onChange={handleEditStatusChange || handleAddStatusChange}
            style={{ width: '100%', color: '#000' }}
          >
            <option value=""> </option>
            <option value="Cheque Deposito"> Cheque Deposito</option>
            <option value="Cheque Provedor">Cheque Provedor</option>
            <option value="Deposito Numerario">Deposito Numerario</option>
          </select>
        </Box>

        <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
          <label className="label_claims">
            Account <span style={{ color: '#ED3737' }}>*</span>
          </label>
          <input
            name="account"
            className="input_submit"
            value={editStatusData.account || addStatusData.account}
            onChange={handleEditStatusChange || handleAddStatusChange}
            style={{ width: '100%' }}
            type="text"
          />
        </Box>

        <div style={{ display: 'block', marginTop: '24px' }}>
          <Box style={{ width: '17%', display: 'inline-block' }}>
            <label className="label_claims">
              Recibo No <span style={{ color: '#ED3737' }}>*</span>
            </label>

            <input
              className="input_submit"
              name="receipt_no"
              value={editStatusData.receipt_no || addStatusData.receipt_no}
              onChange={handleEditStatusChange || handleAddStatusChange}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
            <label className="label_claims">
              Comprovativo <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              name="comprovativo"
              className="input_submit"
              value={editStatusData.comprovativo || addStatusData.comprovativo}
              onChange={handleEditStatusChange || handleAddStatusChange}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>

          <Box style={{ width: '17%', display: 'inline-block', marginLeft: '13%' }}>
            <label className="label_claims">Liquidação</label>

            <select
              className="input_submit"
              name="status"
              value={editStatusData.status || addStatusData.status}
              onChange={handleEditStatusChange || handleAddStatusChange}
              style={{ width: '100%', color: '#000' }}
            >
              <option value=""> </option>
              <option value="Nao Pago"> Não Pago</option>
              <option value="Processado">Processado </option>
              <option value="Em Pagamento">Em Pagamento</option>
              <option value="Pago">Pago </option>
            </select>
          </Box>

          <Box style={{ width: '16%', display: 'inline-block', marginLeft: '2%' }}>
            <label className="label_claims">
              Valor Total <span style={{ color: '#ED3737' }}>*</span>
            </label>
            <input
              className="input_submit"
              value={ClaimTotal.toFixed(2)}
              style={{ width: '100%' }}
              type="text"
            />
          </Box>
        </div>
      </Box>
    </div>
  )
}

export default TDP_Status
