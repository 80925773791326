import { Box, Center , Button} from '@chakra-ui/react'
import './PrintPNew.css'
import { AppContext } from '../core/app-context'
import { useContext, useState, React, useEffect, Component, PropTypes } from 'react'
import BetterCareLogo from './bettercareOG.png'
import { ExportReactCSV } from '../TransactionPage/ExportReactCSV'
import api from '../core/api'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

function PrintProvidersTrans({start_date, end_date}) {




 const [Trans, setT] = useState([])
 const [Print, setPrint] = useState(false)
 

     


const getDate = ()=> {
   api
      .post('/api.php', {
        action: 'get_all_prov_transactions',
        body: {
            start_date: start_date,
            end_date: end_date
        }
      })
      .then((result) => {
        console.log('Providers Transactions', result.data.data)
        setT(result.data.data)
        setPrint(true);
      })
      .catch((err) => console.warn(err))
}

 let thePrice =0
  Trans.map((e) => {  
   
   e.transactions.map((ey)=> {
   
        thePrice+=ey.price<15?(ey.alt_price):(ey.price)              

   })})


const print = () => {
  
 window.print()

  }
const headers = [
        { label: 'Name', key: 'name' },
        { label: 'Description', key: 'description' },
        { label: 'SuggestedRoles', key: 'suggestedRoles' }
    ];


const csvData = [{
  name: 'Data science training',
  description: 'Data Science certification training',

  suggestedRoles: [{
      id: 16,
      category: 'DEVELOPER',
      name: 'Data Engineer'
    }
  ]
}, {
  name: 'AWS',
  description: 'AWS certification training',

  suggestedRoles: [{
      id: 16,
      category: 'DEVELOPER',
      name: 'Cloud Engineer'
    },
    {
      id: 17,
      category: 'DEVELOPER',
      name: 'Network Engineer'
    }
  ]
}];

const headerz = [
        { label: 'id', key: 'id' },
        { label: ' provider_id', key: ' provider_id' },
        { label: 'procedure_name', key: 'procedure_name' },
        { label: 'auth_no', key: 'auth_no' },
        { label: 'member_id', key: 'member_id' },
        { label: 'service_id', key: 'service_id' },
        { label: 'price', key: 'price' },
        { label: 'ref_name', key: 'ref_name' },
         { label: 'alt_price', key: 'alt_price' },
         { label: 'timestamp', key: 'timestamp' },
         { label: 'created_at', key: 'created_at' },
        { label: 'date', key: 'date' },
        { label: 'time', key: 'time' },
        { label: 'auth_by', key: 'auth_by' },
        { label: 'member', key: 'member' },
        { label: 'plan', key: 'plan' },
        { label: 'provider', key: 'provider' },

    ];

    let data = []


Trans.map((e) => {e.transactions.forEach(item=> {
  data.push({
    id:item.id,
    provider_id: item.provider_id,
    procedure_name: item.procedure_name,
    auth_no: item.auth_no,
    member_id: item.member_id,
    service_id: item.service_id,
    price: item.price,
    ref_name: item.ref_name,
    alt_price: item.alt_price,
    timestamp: item.timestamp,
    created_at: item.created_at,
    date: item.date,
    time: item.time,
    auth_by: item.auth_by,
    member: "item.member.first_names",
    plan: "item.plan.name",
    provider: "item.provider.name"
    });

    
});} )





    
    // csvData.forEach(item => {
    //     data.push({
    //         name: item.name,
    //         description: item.description,
    //         suggestedRoles: item.suggestedRoles[0].name
    //     });
    //     for (let i = 1; i < item.suggestedRoles.length; i++) {
    //         const role = item.suggestedRoles[i];
    //         data.push({
    //             name: '',
    //             description: '',
    //             suggestedRoles: role.name
    //         });
    //     }
    // });


   return (
    <div>


    <button onClick={getDate}> Hello</button>
    
      <Button onClick={print} leftIcon={<ArrowDownwardIcon style={{ height: '28px' }} />}
            className="new_member_btn"
            style={{ float: 'right', width: '10.5%', marginTop:'-30px' }}
           >Download</Button>
      <div style={{ width: '100%', display:'none' }} className="divname">
        <div className="InnerDiv" class="k-widget k-grid" data-role="grid">
          <Box
            className="logo_p"
            style={{
              width: '22.68%',
              height: '20px',
              display: 'inline-block'
            }}
          >
            <img src={BetterCareLogo} width="135px" height="36px" />
          </Box>

          <Box style={{ float: 'right', display: 'inline-block' }}>
            <p className="summary_claim">Summary Claims Statement</p>
          </Box>

          <div
            style={{
              display: 'block',
              borderBottom: '1px solid #000',
              height: '124px',
              width: '100%'
            }}
          >
            <Box
              style={{
                width: '130px',
                height: '50px',
                verticalAlign: 'Top',
                marginTop: '27px',
                display: 'inline-block'
              }}
            >
              <p className="house_name"></p>

              <div style={{ width: '94px' }}>
                <p className="house_address">
                
                </p>
              </div>
            </Box>

            <Box
              style={{
                display: 'inline-block',
                height: '76px',
                width: '240px',
                verticalAlign: 'Top',
                position: 'absolute',
                marginTop: '30px',
                right: '0',
                background: '#F4F4F4'
              }}
            >
              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Date
                </p>
                <p className="member_details_p"></p>
              </div>

              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Member No
                </p>
                <p className="member_details_p">  </p>
              </div>

              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                  Policy No
                </p>
                <p className="member_details_p">  </p>
              </div>

              <div style={{ display: 'block', height: '16px', marginLeft: '8px' }}>
                <p style={{ width: '100px' }} className="member_details_p">
                  {' '}
                 
                </p>
                <p style={{fontSize:'10px'}} className="member_details_p"></p>
              </div>
            </Box>
          </div>
          <div style={{ display: 'block' }}>
            <Box style={{ display: 'inline-block', width: '240px', marginLeft: '16px' }}>
              <p style={{ lineHeight: '0px' }} className="house_address">
               
              </p>
              <p style={{ lineHeight: '10px' }} className="member_details_p">
               
              </p>
            </Box>

            <Box style={{ display: 'inline-block', width: '100px' }}>
              <p style={{ lineHeight: '0px' }} className="house_address">
              
              </p>
              <p style={{ lineHeight: '10px' }} className="member_details_p">
               
              </p>
            </Box>

            <Box style={{ display: 'inline-block', width: '100px' }}>
              <p style={{ lineHeight: '0px' }} className="house_address">
               
              </p>
              <p style={{ lineHeight: '10px' }} className="member_details_p">
           
              </p>
            </Box>
          </div>

          <table className="general_table">
            <thead>
              <th colspan="2">Member Details</th>
              <th colspan="4">Procedure Details</th>
              <th colspan="2">Amount</th>
            </thead>

          </table>

          {Trans.map((e) => {
            let tPrice =0
            
            let reembolso=0
            let pay_p = 0
            let day_to_day =0
            let paciente_internado = 0
            let hospitalres = 0
            let condicoes_cronicas=0
            let outro_beneficios=0
            return (
              <table className="value_table">
                <thead>
                  <th colspan="9">{e.provider.name}</th>
                  {/* <th>{ e.transactions.map((pp)=> { return(pp.price)})}</th> */}
                </thead>

                {e.transactions.map((ey)=> { 
                  
                  tPrice+=ey.price<15?(ey.alt_price):(ey.price)
                
                  return(<tr className={ey.status.status ==="Paid"  ? 'paid' : 'not_paid'}>

                  <td colspan="1" style={{ width: '12%', textAlign:'left', fontSize:'10px' }}>{ey.date}</td>
                  <td colspan="1" style={{ width: '15%', textAlign:'left', fontSize:'10px'  }}>{ey.member.member_no}</td>
                 <td colspan="1" style={{ width: '10%', textAlign:'left' , fontSize:'10px'  }}>{ey.auth_no}</td>
                    <td colspan="2" style={{ width: '35%', textAlign:'left' , fontSize:'10px'  }}>{ey.procedure_name}</td>
                  <td colspan="2" style={{ width: '15%' , fontSize:'10px'  }}>{ey.price<15?(ey.alt_price):(ey.price).toLocaleString('en')}</td>
                   <td colspan="1" style={{ width: '5%', textAlign:'left', fontSize:'10px'  }}>{ey.status.status}</td>
                </tr>
                )})}

                <tr className="table_total_block">
                  <td colspan="6" style={{ width: '7%' ,  textAlign:'left', fontSize:'10px' }}>Totals</td>
                
                   <td colspan="2" style={{ width: '20%' , fontSize:'10px' }}>{tPrice.toLocaleString('en')} MT</td>

      
                </tr>
              </table>
            )
          })}

             <table className="value_table">
                <thead>
                  <th colspan="9">STATEMENT TOTAL</th>
                  {/* <th>{ e.transactions.map((pp)=> { return(pp.price)})}</th> */}
                </thead>

                <tr className="table_total_block">
                  <td colspan="7"  style={{ width: '7%' ,  textAlign:'left'  , fontSize:'10px'}}>Totals</td>
                    <td  colspan="2" style={{ width: '50%'  , fontSize:'10px' }}>{thePrice.toLocaleString('en')} MT</td>


                </tr>

                </table>
        </div>
       
      </div>

     
    </div>
  )
}




export default PrintProvidersTrans